import React, { useState } from 'react';

const FaqAccordion = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    // <div className="w-full max-w-2xl mx-auto mt-8">
    <>
      <h1 className="md:text-xl text-2xl font-bold text-[#252423] dark:text-[#EAEAEA]  mt-[60px]">FAQ</h1>
      {accordionData.map((item, index) => (
        <div key={index} className="border-b border-[#E0DFDE] dark:border-[#575350] mt-[12px]">
          <button
            className="w-full flex justify-between items-center focus:outline-none"
            onClick={() => handleToggle(index)}
          >
            <p className="text-medium font-semibold text-[#645F5B] dark:text-white mt-[20px] mb-[12px]">{item.title}</p>
            <span className="text-medium font-semibold text-[#645F5B] dark:text-white">
              {activeIndex === index ? '-' : '+'}
            </span>
          </button>
          {activeIndex === index && (
            <p className="text-base font-regular text-[#645F5B] dark:text-white mt-[12px] mb-[12px]">
              {item.content}
            </p>
          )}
        </div>
      ))}
    {/* </div> */}
      </>
  );
};

const accordionData = [
  { title: 'Example title text.', content: 'Example description text.' },
  { title: 'Example title text.', content: 'Example content text.' },
  { title: 'Example title text.', content: 'Example content text.' },
  { title: 'Example title text.', content: 'Example description text.' },
  { title: 'Example title text.', content: 'Example content text.' },
  { title: 'Example title text.', content: 'Example content text.' },
  { title: 'Example title text.', content: 'Example description text.' },
  { title: 'Example title text.', content: 'Example content text.' },
  { title: 'Example title text.', content: 'Example content text.' },
  // Add more items as needed
];

export default FaqAccordion;
