// import { GoogleLogin } from "react-google-login";
import { useGoogleOneTapLogin, GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { google_logo } from "../../images";
import { useEffect } from "react";
import { useNavigate  } from 'react-router-dom';
import { GoogleOAuth, getInfo,getUserIpTimeAddress } from "../../http/userApi";
import { USER_ACCOUNT_ROUTE, USER_SETNAME_ROUTE, USER_ACCOUNTTEST_ROUTE } from "../../utils/consts";
import { useMedia } from "../../utils/use-media";
import { isMobile } from "../../utils";
import jwtDecode, { InvalidTokenError } from 'jwt-decode';
import { toast } from "react-toastify";
import { showToast } from '../../utils/BannerPopup';

 
// const clientId ='347848844331-0kdfrjs52ectb0n202ragevled8si8np.apps.googleusercontent.com';
const clientId = '728207504628-o8baeuht3hom6u2ov3lklp49lidssk4h.apps.googleusercontent.com';
// const clientId = "175140570001-gu5nmfhcttepqtrlbuqc8u28d7pjcf2t.apps.googleusercontent.com";

function GoogleAuth() {

  const navigate = useNavigate();

  useEffect(() => {
    
  });

  const credentialResponse = async (res) => {    
    // console.log(res); 
  
    if(res && res.credential) {  
      const decoded = jwtDecode(res.credential);  
      const responseIp= await getUserIpTimeAddress();
      const {ipAddress, address, currentTime } = responseIp;// destructuring of object 
      const data = await GoogleOAuth(
        decoded.email,
        decoded.sub,
        ipAddress, address, currentTime
      ); 
      console.log('data',data)
      if ( data.status ==2 ){
        showToast(
          data.message,
          { type: "error" }
        );
        return;       
      }
       const userData= await getInfo();
       
       if (data.id) (data.username && userData.backup_email ) ? window.location.href = USER_ACCOUNT_ROUTE : window.location.href =USER_SETNAME_ROUTE;
       
    }
  };
  let mobile = useMedia(isMobile);
  return (
    <div id="googleSignInButton" className="border mx-auto rounded-[4px] bg-white
    hover:bg-[#eaeaea4d] dark:hover:bg-[#eaeaea4d] focus:bg-[#eaeaea] dark:focus:bg-[#eaeaea] active:bg-[#eaeaea] dark:active:bg-[#eaeaea] duration-300 ease-in-out cursor-pointer customSplashPage__logBtn">
      <img
        height={useMedia(isMobile) ? 20 : 30}
        width={useMedia(isMobile) ? 20 : 30}
        src={google_logo}
        alt="Google Logo"
      />
     <GoogleOAuthProvider 
     clientId={clientId}
     logo_alignment='left'
     >     
      <GoogleLogin
          onSuccess={credentialResponse}
          onError={() => {
            console.log('Login Failed');
          }}
          theme='outline'
          type='icon'
          size='large'
          shape='rectangular'          
          height= { (mobile) ? '40' : '60'}
          width= {(mobile) ? '40' : '60'}
        />
     </GoogleOAuthProvider>
    </div>
  );
}

export default GoogleAuth;
