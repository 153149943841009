import { useEffect, useRef, useState } from "react";
import { delete_svg, borderIcon } from "../../images/index";
import { getLayerInfo, setTextData, saveComponents,deleteComponent } from "../../http/userApi";
import { useContext } from "react";
import { Context } from "../../index";
import { showToast } from "../../utils/BannerPopup";

const useDebounce = (func, delay) => {
  const timeoutRef = useRef(null);

  const debouncedFunc = (...args) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      func(...args);
    }, delay);
  };
  return debouncedFunc;
};

const TextComponent = ({ selectedUsername, onlyView, metadata }) => {
  const [text, setText] = useState(metadata?.meta_data?.[0]?.meta_data?.layer_text || "");
  const [textBorder, setTextBorder] = useState(metadata?.meta_data?.[0]?.meta_data?.layer_border ?? false);
  const { user } = useContext(Context);
  const textareaRef = useRef(null);

  const debouncedSetTextData = useDebounce(async (value, border) => {
    try {
      // const response = await setTextData(value, border); 

      await onTextSave(value, border);

    } catch (error) {
      console.error("Error uploading Text:", error);
    }
  }, 1900);

  const handleTextChange = async (e) => {
    if (e.target.value.length > 1000) {
      showToast("Maximum characters 1,000.");
    } else {
      setText(e.target.value);
      debouncedSetTextData(e.target.value, textBorder);
    }
  };


  const toggleTextBorder = async () => {
    const newBorderStatus = !textBorder;
    setTextBorder(newBorderStatus);
    await debouncedSetTextData(text, newBorderStatus);
  };


  // const toggleTextBorder = async () => {
  //   const newBorderStatus = !textBorder;
  //   setTextBorder(newBorderStatus);
  
  //   // Debounce the text data update (if you have a debounced function)
  //   debouncedSetTextData(text, newBorderStatus);
  
    
  //   let payload;
  //   if (metadata?.meta_data?.[0]?.id) {
  //     payload = {
  //       user_id: user.user.id,
  //       meta_id: metadata?.meta_id,
  //       item_id: metadata?.meta_data?.[0]?.id,
  //       data: [
  //         {
  //           meta_data: {
  //             title: "text",
  //             layer_text: text, 
  //             layer_border: newBorderStatus 
  //           }
  //         }
  //       ]
  //     };
  //   } else {
  //     payload = {
  //       user_id: user.user.id,
  //       meta_id: metadata?.meta_id,
  //       meta_name: "textComponent",
  //       item_index: metadata?.item_index,
  //       data: [
  //         {
  //           title: "text",
  //           layer_text: text, 
  //           layer_border: newBorderStatus 
  //         }
  //       ]
  //     };
  //   }
  
  //   try {
  //     const response = await saveComponents(payload);
  //     console.log("Text border updated successfully:", response);
  //   } catch (error) {
  //     console.error("Error updating text border:", error);
  //   }
  // };

  const handleDeleteText = async () => {
    const newText = "";
    setText(newText);
    try {
      const data = {
        user_id: user.user.id,  
        item_id: metadata?.meta_data?.[0]?.id,
        meta_id: metadata?.meta_id  
      };
  
      const response = await deleteComponent(data);

      console.log("Text deleted successfully:", response?.response);
    } catch (error) {
      console.error("Error deleting Text:", error);
    }
  };

  const onTextSave = async (value, border) => {

    var payload = {};
    if (metadata?.meta_data?.[0]?.id) {
      payload = {
        "user_id": user.user.id,
        "meta_id": metadata?.meta_id,
        "item_id": metadata?.meta_data?.[0]?.id,
        "data": [
          {
            "meta_data": {
              "layer_text": value,
              "layer_border": border
            }
          }
        ]
      };
    } else {
      payload = {
        "user_id": user.user.id,
        "meta_id": metadata?.meta_id,
        "meta_name": "textComponent",
        "item_index": metadata?.item_index,
        "data": [
          {
            "layer_text": value,
            "layer_border": border
          }
        ]
      };
    }
    await saveComponents(payload);
  };
  
  // useEffect(() => {
  //   getLayerInfo(selectedUsername ? selectedUsername : user.user.username)
  //     .then((data) => {
  //       setText(data?.layer_text ? data?.layer_text : "");
  //       setTextBorder(data?.layer_border ? data?.layer_border : false);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching layer info:", error);
  //     });
  // }, [selectedUsername, user.user.username]);


  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, [text]);

  return (
    <>
      <div className="mb-4">

        <textarea
          ref={textareaRef}
          value={text}
          placeholder={!onlyView && !text ? "Text..." : ""}
          onChange={handleTextChange}
          rows="3"
          className={`text-xl w-full p-2 dark:bg-[#252423] dark:text-[#ffffff] text-[#252423] rounded ${textBorder ? "border border-[#E0DFDE]" : ""
            }`}
          readOnly={onlyView}
          style={{ resize: "none", overflow: "hidden" }}
        ></textarea>
      </div>
      {!onlyView && (
        <div className="relative mt-4">
          <div className="relative w-full h-[45px] flex justify-between items-center bg-[#F5F5F5] dark:bg-[#302F2D] rounded-md">
            <div className="rounded cursor-pointer ml-[10px] flex h-[25px] w-[25px] justify-center items-center hidden-btn border border-[#CCEDF8]">
              <img
                src={borderIcon}
                alt="borderIcon"
                onClick={toggleTextBorder}
              />
            </div>
            <div className="rounded cursor-pointer ml-[10px] flex h-[25px] w-[25px] justify-center items-center trash-icon hidden-btn border border-[#F6DAD0] mr-[8px]">
              <img
                src={delete_svg}
                alt="delete_svg"
                onClick={handleDeleteText}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TextComponent;
