import { useEffect } from "react";
import { DiscordOAuth, getDiscordUserInfo,getInfo,getUserIpTimeAddress } from "../../http/userApi";
import { discord_logo } from "../../images";
import { isMobile } from "../../utils";
import { USER_ACCOUNT_ROUTE, USER_SETNAME_ROUTE } from "../../utils/consts";
import { useMedia } from "../../utils/use-media";

function getUrlParams(url) {
  let urlParams = url.split("&");
  let params = {};

  if (urlParams.length) {
    urlParams.map((e) => {
      let objKey = e.split("=")?.[0];
      params[objKey] = e.split("=")?.[1];
    });
  }
  return params;
}

function DiscordAuth() {
  useEffect(() => {
    handleDiscordLogin();
  }, []);

  const handleDiscordLogin = async() => {
    const url = window.location.href;
    const params = getUrlParams(url);
    const responseIp= await getUserIpTimeAddress();
      const {ipAddress, address, currentTime } = responseIp;// destructuring of object 
    if (params?.access_token) {
      // GET ACCOUNT INFO FROM DISCORD
      getDiscordUserInfo(params?.access_token).then((response) => {
      
        if (response?.email) {
          // AUTH FLOW
          DiscordOAuth({
            id: response?.id,
            email: response?.email,
            ipAddress, address, currentTime
          }).then((data) => {
            getInfo().then((userData)=>{
            if (data?.id) {
              if (data?.username && userData?.backup_email) {
                window.location.href = USER_ACCOUNT_ROUTE;
              } else {
                window.location.href = USER_SETNAME_ROUTE;
              }
            }
          }).catch((error) => {
            console.error('Error fetching getInfo:', error);
          });
        }).catch((error) => {
        console.error('Error fetching user info:', error);
      });
    }
    });   
  }};

  const loginDiscord = () => {
    window.open(
      "https://discord.com/api/oauth2/authorize?client_id=1072759047029260338&redirect_uri=https%3A%2F%2Flayer.page%2F&response_type=token&scope=identify%20email"
    );
  };

  return (
    <button
      style={{
        height: useMedia(isMobile) ? 40 : 60,
        width: useMedia(isMobile) ? 40 : 60,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
      onClick={loginDiscord}
      className="border mx-auto rounded-[4px] bg-white
      hover:bg-[#eaeaea4d] dark:hover:bg-[#eaeaea4d] focus:bg-[#eaeaea] dark:focus:bg-[#eaeaea] active:bg-[#eaeaea] dark:active:bg-[#eaeaea] duration-300 ease-in-out cursor-pointer customSplashPage__logBtn"
    >
      <img
        height={useMedia(isMobile) ? 20 : 30}
        width={useMedia(isMobile) ? 20 : 30}
        src={discord_logo}
        alt="discord logo"
      />
    </button>
  );
}

export default DiscordAuth;
