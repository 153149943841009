import { observer } from "mobx-react-lite";
import { useState } from "react";
import { deleteLink, editLink, openLink, getInfo } from "../http/userApi";
import {
  check, deleteIcon, editIcon, linkIcon, titleEmail, titleDelete,
  titleLink
} from "../images";
import ProtectedLink from "./ProtectedLink";

const LinkItem = observer(
  ({ userId, link, onDelete, onEdit, index, onSwap , onlyView }) => {
    const urlPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const [editList, setEditLink] = useState(false);
    const [title, setTitle] = useState(link?.meta_data?.title || '');
    const [newLink, setNewLink] = useState(link?.meta_data.link || '');
    const [description, setNewDescription] = useState(link?.meta_data?.description || '');
    const [password, setPassword] = useState(link?.meta_data?.password);
    const [openLinkForm, setOpenLinkForm] = useState(false);

    ////// Title box 

    const [isEmail, setIsEmail] = useState(emailPattern.test(link?.meta_data?.link || ''));
    const [isUrlValid, setIsUrlValid] = useState(isEmail ? emailPattern.test(link?.meta_data?.link || '') : urlPattern.test(link?.meta_data?.link || ''))

    /// Remove Link   
    const removeLink = async () => {
      // await deleteLink(link.id);
      onDelete(link.id);
      setEditLink(false);
    };
    /// Update Link
    const modifyLink = async (e) => {
      e.preventDefault();
      let verifyLink = newLink || link?.meta_data?.link,
        verifyTitle = title || link?.meta_data?.title;

      if (isEmail) {
        verifyLink = verifyLink.includes("mailto:") ? verifyLink : `mailto:${verifyLink}`;
      } else {
        verifyLink = verifyLink.includes("http://") || verifyLink.includes("https://") ? verifyLink : `http://${verifyLink}`;
      }

      // const data = await editLink(
      //   link.id,
      //   verifyTitle,
      //   verifyLink,
      //   description,
      //   password
      // );
      setEditLink(false);
      onEdit(link.id, verifyTitle, verifyLink, description);
    };

    /// Cancel Protected Settings 
    const cancelCreateLink2 = () => {
      setOpenLinkForm(false);
    };

    /// Open Protected Link
    const readLink = async (e) => {
      e.preventDefault();
      await openLink(link.id, password);
      return false;
    }

    /////////////////   Title box functions    ///////////////
    ///  Link Regex
    const isValidLink = (link) => {
      const urlPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return isEmail ? emailPattern.test(link) : urlPattern.test(link);
    };

    const handleButtonClick = (type) => {
      setIsEmail(type === 'email');
      setNewLink('');
      setTitle('');
      setNewDescription('');
      setIsUrlValid(false);
    };

    const handleLinkInput = (value) => {
      setNewLink(value);

      const urlPattern =
        /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailPattern.test(value) || value.includes("mailto:")) {
        setIsEmail(true);
        setIsUrlValid(emailPattern.test(value) && title.trim() !== "");
      } else if (value.includes("http://") || value.includes("https://")) {
        setIsEmail(false);
        setIsUrlValid(urlPattern.test(value) && title.trim() !== "");
      } else {
        setIsUrlValid(isValidLink(value) && title.trim() !== "");
      }

    };


    const handleLinkChange = (e) => {
      const value = e.target.value;
      if (value.includes("@") || value.includes("mailto:")) {
        setIsEmail(true);
      }
      if (value.includes("http://") || value.includes("https://")) {
        setIsEmail(false);
      }
      setNewLink(value);

      setIsUrlValid(isValidLink(value) && title.trim() !== "");
    };

    const handleTitleChange = (value) => {
      setTitle(value);
      setIsUrlValid(isValidLink(newLink) && value.trim() !== "");
    };


    const cancelChanges = () => {
      setTitle(link?.meta_data?.title);
      setNewDescription(link?.meta_data?.description);
      setNewLink(link?.meta_data?.link);
      setEditLink(false);
      setIsEmail(emailPattern.test(link?.meta_data?.link || ''));
    }

    return (
      <>
        {userId === link?.meta_data?.userId && !onlyView && (
          <>
            {!editList && link?.meta_name == "links" && (
              <div
                onClick={() => {
                  setEditLink(true);
                  setNewDescription(link?.meta_data?.description ?? "");
                }}
                className={`h-[62px] w-full p-[10px] rounded border hover:border-[#CCEDF8] bg-[#F5F5F5] dark:bg-[#302F2D] dark:border-[#CCEDF8]
                ${link?.meta_name != "links" ? "border-[#EAC5C3]" : "border-[#EAEAEA]"}
                `}>
                <div className="flex justify-between">
                  <a
                    href={link?.meta_data?.link}
                    className=" text-sm  font-regular text-[#252423] self-center common-button-text dark:text-[#FFFFFF] overflow-hidden text-ellipsis customProtectedLink__text"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {link?.meta_data?.title}
                  </a>
                  <img
                    height={15}
                    width={15}
                    src={editIcon}
                    className="cursor-pointer object-contain"
                    alt="editIcon"
                  />
                </div>
                <span className="text-xs text-[#645F5B] dark:text-white overflow-hidden text-ellipsis customProtectedLink__text block">
                  {link?.meta_data?.description}
                </span>
              </div>


            )}

            {editList && (
              <form
                className={
                  `w-full p-2.5 rounded flex flex-col justify-between 
                  border ${link?.meta_name != "links" ? "border-[#EAC5C3]" : "border-[#CCEDF8]"}`
                }
                onSubmit={modifyLink}
              >
                <div className="flex justify-between">
                  <input
                    type="text"
                    className="bg-transparent text-[#645F5B] font-semibold outline-0 text-[14px] dark:text-[#FFFFFF] w-full"
                    defaultValue={link?.meta_data?.title}
                    // onChange={(e) => setTitle(e.target.value)}
                    onChange={(e) => handleTitleChange(e.target.value)}
                    placeholder="Title"
                    value={title}
                  />
                </div>

                <input
                  defaultValue={link?.meta_data?.description || ""}
                  placeholder="Description (optional)"
                  className="outline-0 mt-[5px] mb-[20px] bg-transparent text-base text-[#645F5B] dark:text-white"
                  maxLength={50}
                  onChange={(e) => setNewDescription(e.target.value)}
                  value={description}
                />

                <input
                  type="text"
                  className="bg-[#D6D6D633] px-2  mb-[10px] text-[#645F5B] text-base outline-0 text-[14px] dark:text-[#ffffff] h-[35px] rounded border focus:border-[#0A85D1] active:border-[#0A85D1] dark:border-[#575350]"
                  defaultValue={link?.meta_data?.link}
                  // onChange={(e) => setNewLink(e.target.value)}
                  onChange={(e) => handleLinkInput(e.target.value)}
                  value={newLink}
                  placeholder={isEmail ? "Email address" : "Link URL"}
                />

                <div className="flex justify-start space-x-2 mt-2">
                  <button
                    type="submit"
                    className={
                      isUrlValid
                        ? "h-[25px] w-[49px] flex justify-center items-center space-x-1.5 border border-[#5FC650] text-[#5FC650] font-semibold cursor-pointer text-[10px] radius rounded-[5px]"
                        : "h-[25px] w-[49px] flex justify-center items-center space-x-1.5 border border-[#FAF4EE] text-[#645F5B] bg-[#EAEAEA] font-semibold cursor-pointer text-[10px] radius rounded-[5px]"
                    }
                    disabled={!isUrlValid}
                  >
                    <p className="">Save</p>

                  </button>
                  <span
                    className="h-[25px] w-[49px] flex justify-center items-center space-x-1.5 border border-[#FAF4EE] dark:border-[#575350] text-[#645F5B] font-semibold cursor-pointer text-[10px] radius rounded-[5px] dark:text-[#FFFFFF]"
                    onClick={cancelChanges}
                  >
                    Cancel
                  </span>
                </div>

                {link?.meta_name != "links" && (
                  <>
                    <span
                      className="text-[#E1655C] text-base h-[15px] w-[156px] mb-5 text-xs"
                    >The password protecting it:</span>
                    <input
                      placeholder="[asj987lli49-Z]"
                      className="bg-[#FAF4EE] px-2 placeholder-[#E1655C] mt-1.5 mb-5 text-[#E1655C] text-base outline-0 text-[14px] dark:text-[#E1655C] h-[35px] rounded"
                      maxLength={12}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)} />
                  </>
                )}
                <div className="flex justify-between mt-[20px]">
                  {/* Left Container */}
                  <div className="flex space-x-2">
                    <button
                      className={`flex h-[25px] w-[25px] p-[5px] border  rounded justify-center items-center ${!isEmail ? 'bg-[#0A85D1]' : 'border-[#0A85D1]'}`}
                      type="button"
                      onClick={() => handleButtonClick('link')}
                    >
                      <img src={titleLink} height={15} width={15} alt="link" style={{ filter: !isEmail ? 'invert(1) brightness(10)' : 'none' }} />
                    </button>
                    <button
                      className={`flex h-[25px] w-[25px] p-[5px] border  rounded justify-center items-center ${isEmail ? 'bg-[#0A85D1]' : 'border-[#0A85D1]'}`}
                      type="button"
                      onClick={() => handleButtonClick('email')}

                    >
                      <img src={titleEmail} height={15} width={15} alt="link" style={{ filter: isEmail ? 'invert(1) brightness(10)' : 'none' }} />
                    </button>

                  </div>
                  {/* Right Container */}
                  <div className="flex space-x-2">
                    <button type="button"
                      className="flex h-[25px] w-[25px]  border border-[#F6DAD0] rounded justify-center items-center"
                      onClick={() => removeLink()}
                    >
                      <img src={titleDelete} height={15} width={15} alt="delete" />
                    </button>

                  </div>
                </div>
              </form>
            )}
          </>
        )}

        {(userId !== link?.meta_data?.userId || onlyView) && (
          <>
            {link?.meta_name == "links" && (
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={link?.meta_data?.link}
                className="h-[62px] group w-full p-2 bg-[#FAF4EE66] dark:bg-[#302F2D] rounded  border border-[#EAEAEA] hover:border-[#589ED5]"
              >
                <div className="flex justify-between">
                  <span
                    className="w-full text-[14px] text-[#645F5B] font-semibold dark:text-[#FFFFFF] overflow-hidden text-ellipsis customProtectedLink__text block"
                    target="_blank"
                  >
                    {link?.meta_data?.title}
                  </span>
                  <img
                    className="opacity-0 object-contain group-hover:opacity-100"
                    height={15}
                    width={15}
                    src={linkIcon}
                    alt=""
                  />
                </div>
                <span className=" text-base text-[#645F5B] dark:text-[#BCBCBC] overflow-hidden text-ellipsis customProtectedLink__text block">
                  {link?.meta_data?.description}
                </span>
              </a>
            )}
          </>
        )}
      </>
    );
  }
);

export default LinkItem;
