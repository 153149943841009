export const HOME_ROUTE = "/";
export const FEATURES_ROUTE = "/features";
export const HELP_ROUTE = "/help";
export const DOCS_ROUTE = "/docs";
export const TERMS_ROUTE = "/terms";
export const MESSAGE_ROUTE = "/message";
// export const SEARCH_USER = "/username/";
export const SEARCH_USER = "/";

export const USER_NOT_FOUND_ROUTE = "/users/404";

export const USER_ACCOUNT_ROUTE = "/base";
export const USER_SETTINGS_ROUTE = "/base/control";
// export const USER_SETNAME_ROUTE = "/account/username";
export const USER_SETNAME_ROUTE="/base/origin";
export const USER_TIMEOUT_ROUTE="/notice/timeout";
export const USER_COMMS_ROUTE="/base/comms"
export const LAYER_PAGE_CONNECTION="https://layer.guide/connections"
export const MAP_ROUTE="/map";
export const BASE_ORIGIN = 'base/origin';
export const BASE_CONTROL = 'base/control';
export const REPORT_LAYER= "https://layer.guide/safety"