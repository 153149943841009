import React from "react";
import { RotatingLines } from "react-loader-spinner";
import { isMobile } from "../../utils";
import { useMedia, LoadingLayer } from "../../utils/use-media";
import { loadingLayer } from "../../images/index"
// const Loader = ({ isVisible }) => {
//   let mobile = useMedia(isMobile);

//   return (
//     <div className="fixed flex items-center justify-center w-full h-full top-0 left-0 bg-white dark:bg-[#1A1919] z-10">
//       <img 
//         stroke="grey"
//         width="5"
//         animationDuration="0.75"
//         width={mobile ? "30" : "55"}
//         visible={isVisible}
//         src={loadingLayer}
//         alt="loadingLayer"
//       />
//     </div>
//   );
// };
const Loader = ({ isVisible }) => {
  const mobile = useMedia(isMobile);

  const imageStyle = {
    width: mobile ? '30px' : '55px',
    stroke: 'grey', 
  };

  return (
    <div className="fixed flex items-center justify-center w-full h-full top-0 left-0 bg-white dark:bg-[#1A1919] z-10">
      {isVisible && (
        <img
          style={imageStyle}
          src={loadingLayer}
          alt="loadingLayer"
        />
      )}
    </div>
  );
};

export default Loader;
