import { useState, useContext } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { afterStarred, reply, star, trash } from "../images";
import { SEARCH_USER } from "../utils/consts";
import { replyButton, LayerButton } from "../images";
import { Context } from "../index";
import { createMessage } from "../http/messageApi";
import { showToast } from "../utils/BannerPopup";

const MessagePopup = ({
  isOpen,
  onRequestClose,
  message,
  dateTime,
  userId,
  onFav,
  onDelete,
}) => {
  const navigate = useNavigate();
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [messageBody, setMessageBody] = useState(null);
  const { user } = useContext(Context);

  const isSend = message.userId === userId;

  const userLayer = () => {
    let replyUser = isSend ? message?.to : message?.from;
    navigate(SEARCH_USER + replyUser);
  };

  const closeMessageModal = () => {
    setMessageBody("");
    setOpenMessageModal(!openMessageModal);
  };

  // send message api
  const sendMessage = async () => {
    await createMessage(isSend ? message?.to : message?.from, messageBody);
    showToast("Message sent.");
    setMessageBody("");
    setOpenMessageModal(!openMessageModal);
  };

  return (
    <>
      {/* Modal for sending Message */}

      <Modal
        isOpen={openMessageModal}
        onRequestClose={closeMessageModal}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            height: "auto",
            padding: "0",
            background: "transparent",
          },
        }}
        contentLabel="Message Modal"
      >
        <div className="w-[540px]  max-w-full bg-[#F8F8F8] text-[#645F5B] dark:bg-[#252423] dark:text-[#fff] p-[20px]  radius-[5px] rounded-[10px]">
          <p className="text-sm  font-regular text-[#645F5B] dark:text-white">
            To: {isSend ? message?.to : message?.from}
          </p>
          <p className=""></p>
          <textarea
            className=" w-full outline-0 border border-[#E8E7E366] bg-[#D6D6D633] rounded text-[#645F5B] dark:text-[#FFFFFF] p-2.5 resize-none h-[200px] margin-t-20 focus:border-[#589ED5]"
            onChange={(e) => setMessageBody(e.target.value)}
            value={messageBody ? messageBody : ""}
            placeholder="Message.."
          />
          <div className="flex justify-between min-w-100 items-center mt-[20px]">
            {user.isAuth && (
              <button
                className={
                  messageBody
                    ? " w-[63px] h-[35px] border border-[#CCEDF8] text-[#00A3DD] px-[10px] py-[9px] rounded cursor-pointer font-semibold leading-none text-base "
                    : " w-[63px] h-[35px] bg-[#EAEAEA] text-[#9B9B9B] px-[10px] py-[9px] rounded cursor-pointer font-semibold leading-none text-base "
                }
                disabled={!messageBody}
                onClick={sendMessage}
              >
                Send
              </button>
            )}

            <button
              className=" w-[77px] h-[35px] btn-cancel p-[10px] border-[1px] border-[#EAEAEA] dark:border-[#EAEAEA] dark:text-[#ffffff] rounded-[5px]  text-[#645F5B] text-16px font-medium flex items-center leading-[18px]"
              onClick={closeMessageModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>

      {/* Modal for opening Message breakpoint 767*/}
      <Modal
        style={{ overlay: { background: "rgba(26, 25, 25, 0.6)" } }}
        shouldFocusAfterRender={false}
        shouldCloseOnEsc
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        closeTimeoutMS={300}
        className="message-popup fixed w-[600px] h-[400px] bg-[#F8F8F8] self-center dark:bg-[#252423] rounded border-[#DAD7D4] m-auto inset-x-0 inset-y-0 border-[1px]"
        shouldCloseOnOverlayClick={true}
      >
        <div className="flex flex-col h-full py-[20px]">
          {/* Message Info */}
          <section className="flex flex-row justify-between items-center border-b dark:border-[#575350] pb-[20px] leading-none mx-auto width_space-20">
            {message.userId === userId ? (
              <p className="text-sm  text-[#645F5B] dark:text-white">
                To: {message?.to}
              </p>
            ) : (
              <p className="text-sm   text-[#645F5B] dark:text-white ">
                From: {message?.from}
              </p>
            )}

            {/* Badge */}
            <span
              className={` badge px-[5px] py-[2px] rounded-[3px] text-[11px] ${
                message.userId && message.userId !== 1
                  ? "text-[#7D8618] bg-[#F2FB88]"
                  : "text-[#337C73] bg-[#B3FFF5]"
              }`}
            >
              {" "}
              {message.userId && message.userId !== 1 ? "User" : "Non-user"}
            </span>
          </section>
          {/* Message */}
          <textarea
            className="h-full overflow-y-auto my-[10px] text-sm  text-[#645F5B] dark:text-white bg-[transparent]  bookmarkModal__scrollList width_space-20 mx-auto resize-none"
            readOnly
            value={message?.body}
          ></textarea>

          {/* Buttons */}

          <section className="mt-auto flex flex-row justify-between items-center border-t-[1px] border-[#DAD7D4] dark:border-[#575350] pt-[20px] px-[20px]">
            {/* Reply Button */}
            <div className="w-[33.33%] flex space-x-2">
              {message.userId && message.userId !== 1 && (
                //
                <button
                  onClick={() => {
                    setOpenMessageModal(true);
                    onRequestClose();
                  }}
                  className="flex items-center border rounded border-[#CCEDF8] dark:border-[#CCEDF8] text-xs font-semibold w-[30px] h-[30px] justify-center flex-row"
                >
                  <img className="" src={replyButton} alt="replyButton" />
                </button>
              )}
              {message.userId && message.userId !== 1 && (
                //
                <button
                  onClick={userLayer}
                  className="flex items-center border rounded border-[#E0DFDE] dark:border-[#E0DFDE] text-xs font-semibold w-[30px] h-[30px] justify-center flex-row"
                >
                  <img className="box-theme-icon" src={LayerButton} alt="replyButton" />
                </button>
              )}
            </div>
            <p className="text-[9px] md:text-lg12 text-[#808080b5] font-semibold dark:text-[#ffffff] w-[33.33%] text-center">
              {dateTime}
            </p>

            <div className="flex flex-row w-[33.33%] justify-end space-x-2">
              {/* Star Icon */}

              <div
                style={
                  (isSend && message?.SenderStarred) ||
                  (!isSend && message?.ReceiverStarred)
                    ? { background: "#A19526" }
                    : {}
                }
                onClick={onFav}
                className="cursor-pointer ml-[10px] flex h-[30px] w-[30px] rounded  justify-center items-center  border border-[#F2FB88]"
              >
                {message?.ReceiverStarred || message?.SenderStarred ? (
                  <img
                    height={15}
                    width={15}
                    src={afterStarred}
                    alt="afterStarred"
                  />
                ) : (
                  <img height={15} width={15} src={star} alt="star" />
                )}
              </div>

              {/* Delete Icon */}
              <div
                onClick={onDelete}
                className="cursor-pointer flex h-[30px] w-[30px] rounded  justify-center items-center  border border-[#F6DAD0]"
              >
                <img height={14} width={14} src={trash} alt="trash" />
              </div>
            </div>
          </section>
        </div>
      </Modal>
    </>
  );
};

export default MessagePopup;
