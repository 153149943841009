import { React, useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { qrImage } from "../../images";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { regenerateQrCode, getQrCode } from "../../http/userApi";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: '1px solid #DAD7D4',
    borderRadius: '5px',

  },
};

const QrCodeModal = ({ }) => {
  const [qrCode, setQrCode] = useState(null);
  const [imagePath, setImagePath] = useState(null);
  //// Use Effect
  useEffect(() => {
    getQrCode().then((data) => {
      if (data.qr_image) {
        // setImagePath(require(`/root/layerpage/frontend/src/QrCodes/${data.qr_image}`));
        setImagePath(`${process.env.REACT_APP_API_URL}QrCodes/${data.qr_image}`);
        setQrCode(data.qr_image);
      }
      // console.log(qrCode);      
    })
      .finally(() => { });
  }, []);

  //// Add QR Code
  const addQrCode = async () => {
    const res = await regenerateQrCode().then((data) => {
      // setImagePath(require(`/root/layerpage/frontend/src/QrCodes/${data.qr_image}`));
      // setImagePath(require(`../../QrCodes/${data.qr_image}`)); 
      setImagePath(`${process.env.REACT_APP_API_URL}QrCodes/${data.qr_image}`);
      setQrCode(data.qr_image);
      console.log(data.qr_image);
    })
      .finally(() => { });

  }
  //// Add QR Code
  const downloadQrCode = async (e) => {
    var canvas = document.getElementById("my-canvas");
    console.log(canvas.src);
    fetch(canvas.src, {
      method: "GET",
      headers: {}
    })
      .then(response => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", qrCode); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch(err => {
        console.log(err);
      });
  }
  /// Confirm before deleting the message     
  const confirmDelete = async () => {
     confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div
            className=' fixed flex items-center justify-center'>
           <div className="fixed  max-w-full mx-auto p-[10px] w-[540px] message-popup  self-center bg-[#FFFFFF] dark:bg-[#252423] border-[#DAD7D4] m-auto inset-x-0 inset-y-0 border-[1px] rounded-[10px] custom-items-center">
            <h2
              className=" block mb-[20px] justify-left text-lg md:text-xl font-semibold text-[#645F5B] dark:text-white dark:text-[#EAEAEA]"
            >Regenerate QR code</h2>
            <p className="block justify-left mb-[40px] text-base text-[#645F5B] dark:text-white">Create a new QR code to your Layer and invalidate the current.</p>
            <div className='flex justify-between'>
              <button
                className="message-btn btn-delete text-base  p-[5px] text-[#e1655c] border-[1px] border-[#eac5c3] rounded"
                onClick={() => {
                  addQrCode();
                }}
              >
                Regenerate
              </button>
              <button
                className="message-btn btn-cancel text-base   p-[5px] text-[#645f5b] border-[1px] border-[#eaeaea] dark:border-[#ffffff] dark:text-[#ffffff] rounded"
                onClick={onClose}>Cancel</button>
            </div>
            </div>
          </div>
        );
      }
    });

  };

  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }
  function afterOpenModal() {
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div>
      <div className="flex flex-col ">
        <span className="mt-[15px] leading-[24px] text-left text-xl font-semibold text-[#645F5B] dark:text-white dark:text-[#EAEAEA]">
          QR Code
        </span>
        <div className="flex flex-col justify-between mt-3">
          <p className="mb-1 mt-5 font-normal leading-[19px] text-left text-base text-[#645F5B] dark:text-white">
            View, download, or regenerate your QR code.</p>
        </div>
        <div className="flex flex-row lg:flex-row justify-between mt-5">
          <div className="flex--">
            <div className="w-full">
              <button
                onClick={
                  imagePath
                    ? openModal
                    : confirmDelete
                }
                className="border border-[#589ED5] text-[#589ED5] px-[10px] py-[9px] rounded cursor-pointer font-semibold leading-none text-[14px] hover:bg-[#589ed526] active:bg-[#589ED5] focus:bg-[#589ED5] active:text-[#ffffff] focus:text-[#ffffff]">
                View
              </button>
            </div>
            <div className="w-full mt-[10px]">
              <button
                onClick={
                  imagePath
                    ? downloadQrCode
                    : confirmDelete
                }
                className="border border-[#EAEAEA] text-[#645F5B] px-[10px] py-[9px] rounded cursor-pointer font-semibold leading-none text-[14px] hover:bg-[#eaeaea26] focus:bg-[#eaeaea99] active:bg-[#eaeaea99] dark:text-white">
                Download
              </button>
            </div>
            <div className="w-full mt-[10px]">
              <button
                onClick={confirmDelete}
                className="border border-[#EAC5C3] text-[#E1655C] px-[10px] py-[9px] rounded cursor-pointer font-semibold leading-none text-[14px] hover:bg-[#eac5c326] dark:bg-[#eaeaea00] focus:bg-[#EAC5C3] active:bg-[#EAC5C3]">
                Regenerate
              </button>
            </div>
          </div>
          <div className="block lg:mt-0">
            <div
              onClick={openModal}
              className="w-[140px] min-w-[125px] h-[140px] rounded-[5px] relative border-[#EAEAEA]">

              <img id="my-canvas"
                src={
                  imagePath
                    ? imagePath
                    : ""
                }
              />

            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="myOverlayClass customQRModal"

      >
        <div className="m-0 p-0 flex flex-col items-start">
          <span className="text-lg md:text-xl font-semibold text-[#645F5B] block p-[20px] pb-0 w-full">Your Layer QR code</span>
          <img
            className="w-full h-auto block"
            src={
              imagePath
                ? imagePath
                : ""
            }
          />
        </div>
      </Modal>
    </div>
  );
};
export default QrCodeModal;

