import { useCallback, useState } from "react";
import ReactModal from "react-modal";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImage";
import "./styles.css";

const CropImageModal = ({
  isVisible,
  selectedImage,
  onClose,
  onEditComplete,
  onCancel,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [isDark, setDark] = useState(
    localStorage.getItem("theme-color") === "white-content"
  );

  const [croppedArea, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(selectedImage, croppedArea);

      urlToObject(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedArea]);

  const onRest = useCallback(() => {
    setZoom(1);
  }, []);

  const urlToObject = async (img) => {
    const response = await fetch(img);
    // here image is url/location of image
    const blob = await response.blob();
    const file = new File([blob], "image.jpg", { type: blob.type });
    console.log({ file });
    onEditComplete(file);
  };

  return (
    <ReactModal
      isOpen={isVisible}
      onRequestClose={onClose}
      style={{
        overlay: {
          background: isDark ? "rgba(0,0,0,0.1)" : "rgba(0,0,0,0.5)",
        },
        content: {
          height: "540px",
          margin: "auto",
          width: "540px",
          display: "flex",
          background: isDark ? "#000" : "#fff",
          inset: "inherit",
        },
      }}
    >
      <div className="App">
        <div className="image-header flex-row flex absolute z-50 w-full text-left m-0 bg-white">
          <h2 className="min-w-[100%] p-[20px] mb-0 dark:bg-[#252423] border-b-[1px] border-solid  text-lg md:text-xl font-semibold text-[#645F5B] dark:text-white dark:text-[#EAEAEA] ">
            Change photo</h2>
        </div>
        <div className="crop-container">
          <Cropper
            image={selectedImage}
            crop={crop}
            zoom={zoom}
            aspect={4 / 3}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </div>
        <div className="controls ">
          <input
            type="range"
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e) => {
              setZoom(e.target.value);
            }}
            className="zoom-range"
          />
        </div>

        <div className="flex-row flex absolute z-50 bottom-[10px] w-full bottomButtons bottomButtons__changePhotoModal dark:bg-[#252423] border-t-[1px] border-[#EAEAEA] mt-[20px] pt-[20px] pb-[10px] px-[20px]">
          <div
            onClick={showCroppedImage}
            className="flex  border border-[#589ED5] text-[#589ED5] p-[10px] rounded-[5px] cursor-pointer font-semibold common-button w-[100px]"
          >
            Save
          </div>

          <div
            onClick={onRest}
            className="flex border border-[#E1655C] text-[#E1655C] p-[10px] rounded-[5px] cursor-pointer font-semibold common-button w-[100px] ml-auto"
          >
            Reset
          </div>
          <div
            onClick={onCancel}
            className="ml-[20px] flex border border-[#EAEAEA] text-[#645F5B] p-[10px] rounded-[5px] cursor-pointer font-semibold common-button w-[100px] dark:text-[#FFFFFF]"
          >
            Cancel
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default CropImageModal;