import { useState, useContext } from "react";
import {
  addLink,
  deleteComponent,
  getInfo,
  saveComponents,
} from "../../http/userApi";
import {
  titleLink,
  titleEmail,
} from "../../images/index";
import { Context } from "../../index";

import LinksList from "../LinksList";
import { showToast } from "../../utils/BannerPopup";

const LinksComponent = ({ metadata }) => {


  // const {links,user} = useContext(Context);
  const { user } = useContext(Context);
  const [isUrlValid, setIsUrlValid] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const [title, setTitle] = useState("");
  const [link, setLink] = useState();
  const [links, setLinks] = useState(metadata);

  // var linksCounter = 0;
  var linksCounter = links?.meta_data?.length ?? 0;

  const [password, setPassword] = useState("");

  const [createLinkForm, setCreateLinkForm] = useState(false);

  const [description, setDescription] = useState("");

  /// On Delete Refresh Links
  const refreshData = async (e) => {
    // await getInfo().then((data) => {
    //   links.setLinks(data.links);
    //   setCreateLinkForm(false);
    //   setPassword(null);
    // });
    setLinks(metadata);
    setCreateLinkForm(false);
    setPassword(null);
  };
  const deleteLink = async (id) => {
    try {
      const data = {
        user_id: user.user.id,  
        item_id: id, 
        meta_id: metadata?.meta_id 
      };
  
      const response = await deleteComponent(data);
      if (response.status) {

        setLinks({
          "meta_id": metadata?.meta_id,
          "item_index": metadata?.item_index,
          "meta_data": response?.data?.[0]?.data
        });
        metadata.meta_data = data.data;
  
        linksCounter = data.data.length;
      }
      setCreateLinkForm(false);
      
      console.log(" success", response?.response);
    } catch (error) {
      console.error("Error", error);
    }
  };

  ///  Link Regex
  const isValidLink = (link) => {
    const urlPattern =
      /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return isEmail ? emailPattern.test(link) : urlPattern.test(link);
  };

  /// Create New Link
  const createLink = async (e) => {
    const tempLink = isEmail
      ? link?.includes("mailto")
        ? link
        : `mailto:${link}`
      : link?.includes("http")
        ? link
        : `http://${link}`;
    e.preventDefault();
    // await addLink(title, tempLink, description, null);

    var payload = {};
    payload = {
      "user_id": user.user.id,
      "meta_id": metadata?.meta_id,
      "meta_name": "links",
      "item_index": metadata?.item_index,
      "data": [
        {
          "title": title,
          "link": tempLink,
          "userId": user.user.id,
          "description": description,
          "protected": false,
          "password": ""

        }
      ]
    };
    // }
    const data = await saveComponents(payload);
    if (data.status) {

      setLinks({
        "meta_id": metadata?.meta_id,
        "item_index": metadata?.item_index,
        "meta_data": data?.componentItems
      });
      metadata.meta_data = data.componentItems;

      linksCounter = data.componentItems.length;

    } else {
      showToast(data.message);
    }
    cancelCreateLink();
    // await getInfo().then((data) => {
    //   links.setLinks(data.links);
    //   setCreateLinkForm(false);
    //   setPassword(null);
    // });
  };


  const modifyLink = async (id, newTitle, newLink, desc) => {

    // const data = await editItem(tempQuestion, tempAnswer, authUserId, id);
    const payload = {
      "user_id": user.user.id,
      "item_id": id,
      "meta_id": links?.meta_id,
      "data": [
        {
          "meta_data": {
            "title": newTitle,
            "link": newLink,
            "userId": user.user.id,
            "description": desc,
            "protected": false,
            "password": ""

          }
        }
      ]
    };

    const data = await saveComponents(payload);
    if (data.status) {
      setLinks({
        "meta_id": metadata?.meta_id,
        "item_index": metadata?.item_index,
        "meta_data": data?.componentItems
      });
      metadata.meta_data = data.componentItems;
   } else {
      showToast(data.message);
    }
  };

  /// Cancel Settings
  const cancelCreateLink = () => {
    setTitle("");
    setLink("");
    setIsEmail(false);
    setDescription(null);
    setCreateLinkForm(false);
    setIsUrlValid(false);
  };

  const handleButtonClick = (type) => {
    setIsEmail(type === "email");
    setLink("");
    setTitle("");
    setIsUrlValid(false);
  };

  const handleLinkInput = (value) => {
    setLink(value);
    const urlPattern =
      /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailPattern.test(value) || value.includes("mailto:")) {
      setIsEmail(true);
      setIsUrlValid(emailPattern.test(value) && title.trim() !== "");
    } else if (value.includes("http://") || value.includes("https://")) {
      setIsEmail(false);
      setIsUrlValid(urlPattern.test(value) && title.trim() !== "");
    } else {
      setIsUrlValid(isValidLink(value) && title.trim() !== "");
    }
  };

  const handleTitleChange = (e) => {
    const value = e.target.value.trim();
    console.log("handleTitleChange", value);
    setTitle(value);
    setIsUrlValid(isValidLink(link, isEmail) && value.trim() !== "");
  };

  return (
    <>
      {/* {links.met.map((item, index) => {
        if (item.password == null) {
          linksCounter = linksCounter + 1;
        }
      })} */}
      <div className="flex flex-col">
        <form
          className={`
                      ${createLinkForm
              ? "w-full p-2.5 rounded flex flex-col justify-between border"
              : "hidden"
            }
                      border-[#CCEDF8]
                      `}
          onSubmit={createLink}
        >
          <div className="flex justify-between">
            <input
              type="text"
              className="bg-transparent text-[#645F5B] font-semibold outline-0 w-full text-[14px] dark:text-[#FFFFFF]"
              onChange={handleTitleChange}
              value={title}
              placeholder="Title"
            />
          </div>
          <input
            placeholder="Description (optional)"
            className="outline-0 mt-[5px] mb-[15px] text-base text-[#645F5B] bg-transparent dark:text-[#fff]"
            maxLength={50}
            value={description ? description : ""}
            onChange={(e) => setDescription(e.target.value)}
          />
          <input
            type="text"
            className="bg-[#D6D6D633] px-2 mt-1.5 mb-[10px] text-[#645F5B] text-base outline-0 text-[14px] dark:text-[#ffffff] dark:border-[#575350] h-[35px] rounded border focus:border-[#0A85D1] active:border-[#0A85D1]"
            // onChange={(e) => setLink(e.target.value)}
            onChange={(e) => handleLinkInput(e.target.value)}
            value={link}
            placeholder={isEmail ? "Email address" : "Link URL"}
          />
          <div className="flex justify-start space-x-2 mt-2">
            <button
              type="submit"
              className={
                isUrlValid
                  ? "h-[25px] w-[49px] flex justify-center items-center space-x-1.5 border border-[#5FC650] text-[#5FC650] font-semibold cursor-pointer text-[10px] radius rounded-[5px]"
                  : "h-[25px] w-[49px] flex justify-center items-center space-x-1.5 border border-[#FAF4EE] text-[#645F5B] bg-[#EAEAEA] font-semibold cursor-pointer text-[10px] radius rounded-[5px] dark:bg-[#575350] dark:border-[#575350] dark:text-[#FFFFFF]"
              }
              disabled={!isUrlValid}
            >
              <p className="">Save</p>
            </button>
            <span
              className="h-[25px] w-[49px] flex justify-center items-center space-x-1.5 border border-[#FAF4EE] text-[#645F5B] font-semibold cursor-pointer text-[10px] radius rounded-[5px] dark:text-[#FFFFFF] dark:border-[#575350]"
              onClick={() => cancelCreateLink()}
            >
              Cancel
            </span>
          </div>

          <div className="flex justify-between mt-[20px]">
            {/* Left Container */}
            <div className="flex space-x-2">
              <button
                className={`flex h-[25px] w-[25px] p-[5px] border ${!isEmail ? "bg-[#0A85D1]" : "border-[#0A85D1]"
                  } rounded justify-center items-center`}
                type="button"
                onClick={() => handleButtonClick("link")}
              >
                <img
                  src={titleLink}
                  height={15}
                  width={15}
                  alt="link"
                  style={{
                    filter: !isEmail ? "invert(1) brightness(10)" : "none",
                  }}
                />
              </button>
              <button
                className={`flex h-[25px] w-[25px] p-[5px] border ${isEmail ? "bg-[#0A85D1]" : "border-[#0A85D1]"
                  } rounded justify-center items-center`}
                type="button"
                onClick={() => handleButtonClick("email")}
              >
                <img
                  src={titleEmail}
                  height={15}
                  width={15}
                  alt="link"
                  style={{
                    filter: isEmail ? "invert(1) brightness(10)" : "none",
                  }}
                />
              </button>
            </div>
          </div>
        </form>

        {!createLinkForm && (
          <div
            className={`w-full p-[10px] border rounded flex justify-between h-[62px]   ${linksCounter >= 10 ? "border-[#E1655C]" : "border-[#F4F3F3]"
              }`}
          >
            {linksCounter >= 10 && (
              <span className="text-[#E1655C] text-[12px]">
                The maximum number of links is 10
              </span>
            )}
            <>
              <button
                onClick={() =>
                  linksCounter < 10 ? setCreateLinkForm(true) : ""
                }
                className="ml-auto mr-0 h-[25px] w-[40px] flex justify-center items-center rounded bg-[#EAEAEA] font-semibold text-[#589ED5] text-[10px]"
              >
                Add
              </button>
            </>
          </div>
        )}
      </div>

      <LinksList
        onDelete={(id) => {
          deleteLink(id);
          // refreshData();
        }}
        showProtectedList={false}
        links={links}
        modifyLink={(id, newTitle, newLink, desc) => modifyLink(id, newTitle, newLink, desc)}
      />
    </>
  );
};

export default LinksComponent;
