import { getTwitterToken } from "../../http/userApi";
import ConnectedBox from "./ConnectedBox";

const TwitterConnection = ({ data, onRemove }) => {
  let isTwitterConnected = data?.findIndex((e) => e.service === "twitter");

  const onLogin = async () => {
    try {
      const response = await getTwitterToken();
      if (response?.status == 1) {
        let url = `https://api.twitter.com/oauth/authorize?oauth_token=${response.response.oauth_token}&oauth_token_secret=${response.response.oauth_token_secret}&oauth_callback_confirmed=true`;
        window.open(url, "_blank");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <ConnectedBox
      title={"Twitter"}
      isRemove={isTwitterConnected > -1 && data?.length}
      onRemove={() => onRemove("twitter")}
      info={data[isTwitterConnected]}
    >
      {isTwitterConnected === -1 && (
        <button
          className="bg-[#EAEAEA] h-[35px] w-[50px] rounded font-semibold text-sm text-[#589ED5] cursor-pointer"
          onClick={onLogin}
        >
          Add
        </button>
      )}
    </ConnectedBox>
  );
};

export default TwitterConnection;
