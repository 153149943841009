import { useEffect, useState } from "react";
import { deleteConnect } from "../../http/userApi";
import { greenCheck } from "../../images";
import {
  USER_ACCOUNT_ROUTE,
  USER_SETNAME_ROUTE,
  USER_SETTINGS_ROUTE,
  LAYER_PAGE_CONNECTION,
} from "../../utils/consts";
import { confirmAlert } from "react-confirm-alert";

const GithubConnection = ({ data }) => {
  const [connectGithub, setConnectGithub] = useState(false);
  const [connectedAPI, setConnectedAPI] = useState(false);
  const [info, setInfo] = useState(null);

  // const clientId = "278ebeee654546b331c5";
  const clientId = "7a5b9eeac74f6ad14299";

  useEffect(() => {
    for (let info of data) {
      if (info.service === "github") {
        setInfo(info);
        setConnectGithub(true);
      }
    }

    setConnectedAPI(data.length);
  }, [data]);

  const addGithub = () => {
    window.location.assign(
      "https://github.com/login/oauth/authorize?client_id=" +
        clientId +
        "&scope=user"
    );
  };

  const deleteGithub = async () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className=" fixed  mx-auto p-[10px] w-[540px] message-popup bg-[#F8F8F8] self-center dark:bg-[#000000] border-[#DAD7D4] m-auto inset-x-0 inset-y-0 border-[1px] rounded-md custom-items-center">
            <h2 className="block mb-[20px] justify-left text-lg md:text-xl font-semibold text-[#645F5B] dark:text-white dark:text-[#EAEAEA]">
              Unlink account
            </h2>
            <p className="block justify-left mb-[40px] text-base text-[#645F5B] dark:text-white">
              Confirm removal of connected account.
            </p>
            <div className="flex justify-between">
              <button
                className="message-btn btn-delete text-base  p-[5px] text-[#e1655c] border-[1px] border-[#eac5c3] rounded"
                onClick={async () => {
                  const deleteThis = await deleteConnect("github");
                  if (deleteThis) {
                    setConnectGithub(false);
                    window.location.href = USER_SETTINGS_ROUTE;
                  }
                  onClose();
                }}
              >
                Remove
              </button>
              <button
                className="message-btn btn-cancel text-base   p-[5px] text-[#645f5b] border-[1px] border-[#eaeaea] dark:border-[#ffffff] dark:text-[#ffffff] rounded"
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          </div>
        );
      },
    });
  };

  return (
    <div className="border-[1px] border-color-[#e3e3e3] rounded py-1.5 px-2 h-[93px] flex flex-col justify-between">
      <div className="flex flex-row justify-between">
        <p className="text-[#645F5B] dark:text-[#ffffff] font-bold text-sm">
          Github
        </p>
        <div className="flex cursor-pointer">
          {!connectGithub && (
            <button
              onClick={addGithub}
              className="bg-[#EAEAEA] h-[35px] w-[50px] rounded font-semibold text-sm text-[#589ED5] cursor-pointer hover:bg-[#E2E2E2] active:bg-[#CCC]"
            >
              Add
            </button>
          )}
          {connectGithub && (
            <>
              {connectedAPI && (
                <div
                  className="flex items-center border border-[#EAEAEA] rounded cursor-pointer h-[35px] w-[75px]  justify-center"
                  onClick={deleteGithub}
                >
                  <span className="font-semibold text-[#645F5B] dark:text-[#fff] text-sm leading-none">
                    Remove
                  </span>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {info &&
      (info?.is_protected === true || info?.is_protected?.trim() == "true") ? (
        <em className="text-[#645F5B] dark:text-[#ffffff] text-[14px] truncate block ">
          Email hidden with provider (
          <span className="text-[#0A85D1]">
            <a
              href={LAYER_PAGE_CONNECTION}
              target="_blank"
              rel="noopener noreferrer"
              className="underline  hover:underline focus:underline"
            >
              learn more
            </a>
          </span>
          )
        </em>
      ) : (
        info?.email && (
          <div className="flex flex-row justify-between">
            <span className="text-[#645F5B] dark:text-[#ffffff] text-sm truncate block">
              {info?.email}
            </span>
            <img height={20} width={20} src={greenCheck} alt="greenCheck" />
          </div>
        )
      )}
    </div>
  );
};

export default GithubConnection;
