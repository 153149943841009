import React, { useContext, useEffect, useRef, useState } from "react";
import { Context } from "../index";
import { observer } from "mobx-react-lite";
import RightMenu from "../components/RightMenu";
import Header from "../components/Header";

import Loader from "../components/Loader";
import {Themefooter} from "../components/ThemeFooter"
import SignInMenu from "./Authorization/signInMenu";


const TimeOut = observer(() => {
  const { user } = useContext(Context);
  const headerRef = useRef();
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme-color") === "white-content" ? false : true
  );
  const [authState, setAuthState] = useState(false);

  useEffect(() => {
    window.addEventListener("theme-color", () => {
      setDarkMode(
        localStorage.getItem("theme-color") === "white-content" ? false : true
      );
    });
  }, []);

  return (
    <>
    <main className="flex flex-col justify-between h-screen bg-white dark:bg-[#1A1919] overflow-hidden">
      {authState && <Loader isVisible={authState} />}
      <Header ref={headerRef} />
      <div className="flex justify-between bg-white dark:bg-[#1A1919] pt-[105px] h-full">
        <div className=" home_left_wrapper">
          <div className="flex flex-col justify-between container md:max-w-5xl px-5 mx-auto h-full overflow-y-auto no-scrollbar">
            <div className=" space-x-2 items-center">
              <span className=" text-sm  font-regular text-[#645F5B] dark:text-white">Notice</span>
              <span className="text-sm  font-regular text-[#645F5B] dark:text-white"> • </span>
                <span className="text-sm  font-regular text-[#645F5B] dark:text-white"> Verification</span>
            </div>

            <div className="flex flex-col lg:flex-row justify-between lg:space-x-10 space-y-10 lg:space-y-0 mt-[60px]">
              <div>
                <p className="md:text-xl leading-[24px] md:leading-[29px]  lg:text-2xl text-xl font-bold text-[#645F5B] dark:text-[#EAEAEA]">
                Your Layer link and verification code have timed out (they are valid for one hour to keep your Layer secure). Please request a new verification email.
                </p>
              </div>
            </div>
            <SignInMenu headerRef={headerRef}/>
        </div>
      </div>
        <RightMenu />
      </div>
      <Themefooter/>
    </main>
    </>
  );
});
export default TimeOut;
