import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import {
  getInfo,
  changeUserAvatar,
  saveUserBio,
  setComponents,
  listComponents,
  saveComponents,
  saveComponentsTypes,
  setComponentsData,
  deleteComponentGroup,
} from "../../http/userApi";
import {
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Context } from "../../index";
import { defaultImage } from "../../images/index";
import RightMenu from "../../components/RightMenu";
import Header from "../../components/Header";

import { observer } from "mobx-react-lite";
import Loader from "../../components/Loader";
import Modal from "react-modal";
import CropImageModal from "../../components/CropImageModal";
import { USER_SETNAME_ROUTE } from "../../utils/consts";
import QuestionAndAnswer from "../../components/Base/QuestionAndAnswer";
import Showcase from "../../components/Base/Showcase";
import LinksComponent from "../../components/Base/LinksComponent";
import ProtectedLinkComponent from "../../components/Base/ProtectedLinkComponent";
import ProfileSection from "../../components/DnD/ProfileSection";
import ToolBar from "../../components/ToolBar";
import ImageComponent from "../../components/Base/ImageComponent";
import TextComponent from "../../components/Base/TextComponent";
import { COMPONENT_ID } from "../../utils/index";
import { showToast } from "../../utils/BannerPopup";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "transparent",
    height: "auto",
    border: "0",
  },
};

export const Account = observer(() => {
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const { user, messages, links, Themefooter } = useContext(Context);
  const isAuthPremium = user.user.is_premium;
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [avatar, setAvatar] = useState(null);
  const [arrangement, setArrangement] = useState(false);
  const [countComponents, setCountComponents] = useState(0);
  /// change bio text and modal states

  // const [userInfo, setUserInfo] = useState(null);

  const [selectedImage, setSelectedImage] = useState();
  const [isPrivate, setIsPrivate] = useState(
    localStorage.getItem("isPrivate")
      ? localStorage.getItem("isPrivate")
      : false
  );
  const [emailNotification, setEmailNotification] = useState(false);

  //crop image modal
  const [isCropPopup, setCropPopup] = useState(false);
  const fileInput = useRef(null);

  // dnd content start

  // const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const [sections, setSections] = useState([]);

  // Bio component
  const BioComponent = (metadata) => {
    const { user } = useContext(Context);
    // const [userInfo, setUserInfo] = useState(null);

    const [userBio, setUserBio] = useState(
      metadata?.metadata?.meta_data?.[0]?.meta_data?.description
    );
    const [bioLength, setBioLength] = useState(
      metadata?.metadata?.meta_data?.[0]?.meta_data?.description.length
    );
    const [userbioState, setUserbioState] = useState(false);
    const [bioChanged, setBioChanged] = useState(false);
    const [initialBio, setInitialBio] = useState(
      metadata?.metadata?.meta_data?.[0]?.meta_data?.description
    );
    //////// start bio text modal function calls //////////
    const [bioModalIsOpen, setBioModalIsOpen] = useState(false);

    const closeBioModal = () => {
      setBioModalIsOpen(false);
    };

    const onBioSave = async () => {
      // await saveBio();
      // const data = userInfo;
      // data["bio"] = userBio;
      // setUserInfo(data);
      closeBioModal();
      setUserbioState(false);
      var payload = {};
      if (metadata?.metadata?.meta_data?.[0]?.id) {
        payload = {
          user_id: user.user.id,
          item_id: metadata?.metadata?.meta_data?.[0]?.id,
          meta_id: metadata?.metadata?.meta_id,
          data: [
            {
              meta_data: {
                title: "bio",
                userId: user.user.id,
                description: userBio,
                //  ...( metadata?.metadata?.[0]?.id ? {'id' : metadata?.metadata?.[0]?.id } : {})
              },
            },
          ],
        };
      } else {
        payload = {
          user_id: user.user.id,
          meta_id: metadata?.metadata?.meta_id,
          meta_name: "bio",
          item_index: metadata?.metadata?.item_index,
          data: [
            {
              title: "bio",
              userId: user.user.id,
              description: userBio,
              //  ...( metadata?.metadata?.[0]?.id ? {'id' : metadata?.metadata?.[0]?.id } : {})
            },
          ],
        };
      }
      const res = await saveComponents(payload);
      if (res.status) {
        metadata.metaData.meta_data = res.componentItems;
        setUserBio(metadata?.metadata?.meta_data?.[0]?.meta_data?.description);
        setInitialBio(
          metadata?.metadata?.meta_data?.[0]?.meta_data?.description
        );
      }
      // setUserBio(metadata?.metadata?.meta_data?.[0]?.meta_data?.description);
    };

    const returnBio = () => {
      if (metadata?.metadata?.meta_data?.[0]?.meta_data?.description) {
        setUserBio(metadata?.metadata?.meta_data?.[0]?.meta_data?.description);
      } else {
        setUserBio("");
        setBioChanged(false);
      }
      setBioModalIsOpen(false);
    };
    //////////////////////// end Bio text Modal /////////////////////////

    const handleInputChange = (e) => {
      const newBio = e.target.value;
      setUserBio(newBio);
      setBioChanged(newBio !== "" && newBio !== initialBio);
    };

    useEffect(() => {
      setInitialBio(userBio);
    }, [bioModalIsOpen]);

    return (
      <>
        <div className="flex justify-between">
          <textarea
            className="bg-transparent border border-[#E0DFDE] dark:border-[#575350] rounded focus:outline-none h-20 w-full resize-none p-[10px] dark:text-[#FFFFFF] cursor-pointer hover:border-[#0A85D1]"
            // value={metadata?.metadata?.[0]?.meta_data?.description ? metadata?.metadata?.[0]?.meta_data?.description : ""}
            value={initialBio ? initialBio : ""}
            // value={userInfo && userInfo.bio ? userInfo.bio : ""}
            placeholder="Welcome to my layer."
            readOnly={true}
            onClick={() => {
              setBioModalIsOpen(true);
            }}
          />
        </div>

        <Modal
          isOpen={bioModalIsOpen}
          onRequestClose={closeBioModal}
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              height: "auto",
              padding: "0",
              background: "transparent",
            },
          }}
          contentLabel="Bio Edit Modal"
        >
          <div className="w-[540px]  max-w-full bg-[#F8F8F8] text-[#645F5B] dark:bg-[#252423] dark:text-[#fff] p-[20px]  radius-[5px] rounded-[10px] text-center">
            <h1 className="block justify-left mb-[20px] leading-none md:text-xl text-lg font-semibold text-[#645F5B] dark:text-white ">
              Edit your Bio
            </h1>
            <p className="justify-left mb-[40px] leading-none text-base text-[#645F5B] dark:text-white">
              Maximum 170 characters.
            </p>
            <textarea
              className="bg-transparent border border-[#EAEAEA] focus:border-[#589ED5] text-[#645F5B] dark:text-[#fff] w-full resize-none outline-0 p-2 h-[80px] mb-[20px] min-h-[100px] rounded-[5px]"
              onChange={handleInputChange}
              value={userBio || ""}
              placeholder="Write about yourself"
              maxLength={170}
            />
            <div className="flex justify-between min-w-100 items-center">
              <button
                className={`border border-[#EAEAEA]${
                  bioChanged ? "[#5FC650]" : "[#EAEAEA]"
                } text-[${bioChanged ? "#5FC650" : "#645F5B"}]
              message-btn btn-delete p-[10px] border-[1px] border-[#0A85D1] rounded-[5px] mt-0 text-[#0A85D1] text-base  flex items-center leading-[18px] disabled:text-[#9B9B9B] disabled:bg-[#EAEAEA] disabled:border-[#EAEAEA]`}
                onClick={onBioSave}
                disabled={!bioChanged}
              >
                Save
              </button>
              <button
                className=" btn-cancel p-[10px] border-[1px] border-[#EAEAEA] dark:border-[#EAEAEA] dark:text-[#ffffff] rounded-[5px] mt-0 text-[#645F5B] text-16px font-medium flex items-center leading-[18px]"
                onClick={returnBio}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      </>
    );
  };

  const handleDragEnd = async (event) => {
    const { active, over } = event;
    if (active?.id && over?.id && active.id !== over.id) {
      const oldIndex = sections.findIndex((item) => item.id === active.id);
      const newIndex = sections.findIndex((item) => item.id === over.id);
      const newComponents = arrayMove(sections, oldIndex, newIndex);
      setSections(newComponents);

      const payload = {
        data: newComponents?.map((component) => {
          return {
            meta_id: component?.id,
            meta_name: component?.meta_name,
          };
        }),
      };
      try {
        const response = await setComponentsData(payload);
        if (response.status === 1) {
          const componentsData = response.data || [];
          const formattedSections = componentsData.map((component) => ({
            id: component.meta_id,
            meta_name: component.meta_name,
            item_index: component.item_index,
            data: component.data,
          }));
          getOrderedComponents(formattedSections);
          // setSections(formattedSections);
        } else {
          console.error("Failed to fetch components:", response.message);
        }
      } catch (error) {
        console.error("Error fetching components:", error);
      }
    }
  };

  const maxComponents = isAuthPremium ? 30 : 10;

  useEffect(() => {
    if (countComponents > maxComponents) {
      const updatedSections = sections.slice(0, maxComponents);
      setSections(updatedSections);
    }
  }, [countComponents, isAuthPremium]);

  const addSection = async (id, children, payload) => {
    if (countComponents >= maxComponents) {
      showToast(
        `You have reached the maximum limit of ${maxComponents} components.`
      );
      return;
    }

    const response = await saveComponentsTypes(payload);

    if (response.status === 1) {
      const componentsData = response.components || [];

      const totalComponents = componentsData.length;
      setCountComponents(totalComponents);

      const formattedSections = componentsData.map((component) => ({
        id: component.meta_id,
        meta_name: component.meta_name,
        data: component.data,
      }));
      getOrderedComponents(formattedSections);
      // setSections(formattedSections);
    } else {
      console.error("Failed to fetch components:", response.message);
    }
  };

  const removeSection = async (id) => {
    const payload = {
      user_id: user.user.id,
      group_id: id,
    };

    try {
      const response = await deleteComponentGroup(payload);
      if (response.status === 1) {
        const componentsData = response.data || [];
        const formattedSections = componentsData.map((component) => ({
          id: component.meta_id,
          meta_name: component.meta_name,
          item_index: component.item_index,
          data: component.data,
        }));
        setCountComponents(componentsData.length);
        getOrderedComponents(formattedSections);
      } else {
        console.error("Failed to fetch components:", response.message);
      }
    } catch (error) {
      console.error("Error fetching components:", error);
    }
    // setSections((sections) => sections.filter((section) => section.id !== id));
  };

  const fetchComponents = async () => {
    try {
      const response = await listComponents(user.user.id);
      if (response.status === 1) {
        const componentsData = response.data || [];
        const formattedSections = componentsData.map((component) => ({
          id: component.meta_id,
          meta_name: component.meta_name,
          item_index: component.item_index,
          data: component.data,
        }));
        setCountComponents(formattedSections?.length);
        getOrderedComponents(formattedSections);
        // setSections(formattedSections);
      } else {
        console.error("Failed to fetch components:", response.message);
      }
    } catch (error) {
      console.error("Error fetching components:", error);
    }
  };


  const getOrderedComponents = (orderedMetaData) => {
    const orderedComponents = orderedMetaData?.map((metaData) => {
      const sectionData = allSections.find(
        (section) => section.meta_name === metaData?.meta_name
      );
      return {
        ...sectionData,
        ...metaData,
      };
    });
    setSections(orderedComponents);
    return orderedComponents;
  };

  // const getOrderedComponents = (orderedIds) => {
  //   if (!orderedIds || !allSections) return;

  //   const sectionsMap = new Map(allSections.map((section) => [section.id, section]));
  //   const orderedComponents = orderedIds.map((id) => sectionsMap.get(id)).filter(Boolean);
  //   setSections(orderedComponents);
  // };

  // const allSections = [
  //   {
  //     id: COMPONENT_ID.LINK_COMPONENT,
  //     content: <LinksComponent />,
  //   },
  //   { id: COMPONENT_ID.BIO_COMPONENT, content: <BioComponent /> },
  //   {
  //     id: COMPONENT_ID.QA_COMPONENT,
  //     content: <QuestionAndAnswer />,
  //   },
  //   {
  //     id: COMPONENT_ID.SHOWCASE_COMPONENT,
  //     content: <Showcase />,
  //   },
  //   { id: COMPONENT_ID.TEXT_COMPONENT, content: <TextComponent /> },
  //   { id: COMPONENT_ID.IMAGE_COMPONENT, content: <ImageComponent /> },
  //   {
  //     id: COMPONENT_ID.PROTECTED_COMPONENT,
  //     content: <ProtectedLinkComponent />,
  //   },
  // ];

  const allSections = [
    {
      meta_name: COMPONENT_ID.LINK_COMPONENT,
      content: (props) => <LinksComponent metadata={props} />,
    },
    {
      meta_name: COMPONENT_ID.BIO_COMPONENT,
      content: (props) => <BioComponent metadata={props} />,
    },
    {
      meta_name: COMPONENT_ID.QA_COMPONENT,
      content: (props) => <QuestionAndAnswer metadata={props} />,
    },
    isAuthPremium && {
      meta_name: COMPONENT_ID.SHOWCASE_COMPONENT,
      content: (props) => <Showcase metadata={props} />,
    },

    {
      meta_name: COMPONENT_ID.TEXT_COMPONENT,
      content: (props) => <TextComponent metadata={props} />,
    },

    isAuthPremium && {
      meta_name: COMPONENT_ID.IMAGE_COMPONENT,
      content: (props) => <ImageComponent metadata={props} />,
    },

    isAuthPremium && {
      meta_name: COMPONENT_ID.PROTECTED_COMPONENT,
      content: (props) => <ProtectedLinkComponent metadata={props} />,
    },
  ].filter(Boolean);

  /// start crop avatar modal
  const selectFile = async (e) => {
    setCropPopup(true);
    setSelectedImage(e.target.files[0]);
  };

  const onEditComplete = async (file) => {
    setCropPopup(false);
    let formData = new FormData();
    formData.append("img", file);
    const data = await changeUserAvatar(formData);
    if (data) {
      user.setAvatar(data || null);
      setAvatar(`${process.env.REACT_APP_API_URL}${data}` || null);
      setSelectedImage(file);
    }
  };

  const handleAvatarClick = () => {
    fileInput.current.click();
  };
  /////end crop avatar modal

  /// Load User Data
  useEffect(() => {
    getInfo()
      .then((data) => {
        const isPrivate = data?.ignore_layer;
        const emailNotification = data?.when_sign_in;
        localStorage.setItem("isPrivate", isPrivate);
        setIsPrivate(localStorage.getItem("isPrivate"));
        localStorage.setItem("emailNotification", emailNotification);
        setEmailNotification(localStorage.getItem("emailNotification"));
        links.setLinks(data.links);
        setUserInfo(data);
        if (data.avatar) setAvatar(process.env.REACT_APP_API_URL + data.avatar);

        // getOrderedComponents(data?.arrange_components || []);
      })
      .catch((error) => console.log("error at account", error))
      .finally(
        setTimeout(function () {
          setLoading(false);
        }, 1000)
      );
  }, []);

  useEffect(() => {
    if (userInfo && (!userInfo.username || !userInfo?.backup_email)) {
      let url = USER_SETNAME_ROUTE;
      window.location.href = url;
    }
  }, [userInfo]);

  useEffect(() => {
    if (user.user.id) fetchComponents();
  }, [user.user.id]);

  if (loading) {
    return <Loader isVisible={loading} />;
  }

  return (
    <main className="flex flex-col justify-start dark:bg-[#1A1919]">
      <Header />
      <div className="flex justify-between border-b border-EA custom-wrapper ">
        <div className="w-full customContainer__left">
          <div
            id="scrollTotop"
            className="flex flex-col max-w-5xl px-5 mx-auto h-auto"
          >
            <div className="hidden lg:flex md:pt-[40px] pt-[20px] pb-[60px] space-x-2 items-center text-sm">
              <span className="text-[#645F5B] dark:text-[#FFFFFF]">Layer</span>
              <span className="block w-1.5 h-1.5 bg-EA rounded-full"></span>
              <span className="text-[#645F5B] dark:text-[#FFFFFF]">
                @{userInfo && userInfo.username}
              </span>
            </div>
            <div className="flex flex-col lg:flex-row justify-between lg:space-x-10">
              {/* <div className=" mt-4 grid grig-cols-1 md:grid-cols-2 gap-8"> */}
              <div className="custom_grid_container lg:w-full">
                <div className=" mt-4 custom_grid">
                  <div className="lg:border-t border-[#E0DFDE] dark:border-[#575350] overflow-x-hidden ">
                    <div className="flex justify-between md:pt-[40px] pt-[20px]">
                      <div className="flex flex-col ">
                        <h2 className="text-[#645F5B] dark:text-[#FFFFFF] text-xl md:text-2xl font-semibold">
                          @{userInfo && userInfo.username}
                        </h2>
                      </div>
                      <div className="w-[60px] h-[60px] border hover:border-[#0A85D1] cursor-pointer rounded-[5px] flex justify-center items-center border-[#E0DFDE]">
                        <img
                          src={avatar || defaultImage}
                          className={
                            avatar
                              ? "w-[100%] h-[100%] object-cover rounded-[5px]  "
                              : "w-auto h-auto "
                          }
                          onClick={handleAvatarClick}
                          alt="avatar"
                        />
                        <input
                          ref={fileInput}
                          id="file"
                          name="file"
                          type="file"
                          onChange={selectFile}
                          hidden={true}
                        />
                      </div>
                    </div>
                  </div>

                  {arrangement && sections?.length > 0 && (
                    <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
                      <SortableContext
                        items={sections}
                        strategy={rectSortingStrategy}
                      >
                        {sections?.map((section) => (
                          // <>
                          //   <div key={section.id} id={section.id} >
                          //     {section.content}
                          //   </div>
                          // </>

                          // <ProfileSection
                          //   key={section?.id}
                          //   id={section?.id}
                          //   content={section?.content}
                          //   onRemove={removeSection}
                          // />
                          <ProfileSection
                            key={section?.id}
                            id={section?.id}
                            content={section?.content}
                            data={section?.data}
                            item_index={section?.item_index}
                            onRemove={removeSection}
                          />
                        ))}
                      </SortableContext>
                    </DndContext>
                  )}
                  {!arrangement &&
                    sections?.length > 0 &&
                    sections?.map((section) => (
                      <>
                        <div key={section?.id} id={section?.id}>
                          {/* {section?.content(section?.data)} */}
                          {/* {section?.content} */}

                          {/* {section?.content({ meta_data: section?.data, meta_id: section?.id, item_index: section?.item_index })} */}
                          {typeof section.content === "function" && (
                            section.content({
                              meta_data: section?.data,
                              meta_id: section?.id,
                              item_index: section?.item_index,
                            })
                          )}
                        </div>
                      </>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="sticky bottom-[20px] flex justify-center mb-[20px]">
            {arrangement && (
              <div className="absolute top-[-55px] left-1/2 transform -translate-x-1/2 mt-4">
                <button
                  className="h-[30px] w-[62px] text-sm bg-[#00A3DD] border-[1px] rounded-[100px] border-[#00A3DD] text-[#ffffff]"
                  onClick={() => setArrangement(false)}
                >
                  Done
                </button>
              </div>
            )}
            <div className="mx-auto max-w-[250px]">
              <ToolBar
                addSection={addSection}
                BioComponent={BioComponent}
                setArrangement={setArrangement}
                arrangement={arrangement}
                countComponents={countComponents}
                setCountComponents={setCountComponents}
              />
            </div>
          </div>
          <Themefooter></Themefooter>
        </div>
        <RightMenu />
      </div>
      <CropImageModal
        isVisible={isCropPopup}
        selectedImage={
          (selectedImage && URL?.createObjectURL(selectedImage)) || ""
        }
        onClose={() => setCropPopup(false)}
        onEditComplete={onEditComplete}
        onCancel={() => {
          setCropPopup(false);
          setSelectedImage(null);
        }}
      />
    </main>
  );
});
export default Account;
