import { useEffect, useState } from "react";
import { gapi } from "gapi-script";
// import GoogleLogin from "react-google-login";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { useGoogleLogin } from "@react-oauth/google";

import { deleteConnect, GoogleConnect } from "../../http/userApi";
import { check, greenCheck } from "../../images";
import jwtDecode, { InvalidTokenError } from "jwt-decode";
import {
  USER_ACCOUNT_ROUTE,
  USER_SETNAME_ROUTE,
  USER_SETTINGS_ROUTE,
  LAYER_PAGE_CONNECTION,
} from "../../utils/consts";
import { confirmAlert } from "react-confirm-alert";
import { showToast } from "../../utils/BannerPopup";

const GoogleConnection = ({ data }) => {
  const [connectGoogle, setConnectGoogle] = useState(false);
  const [connectedAPI, setConnectedAPI] = useState(false);
  const [info, setInfo] = useState(null);
  // const clientId = "175140570001-gu5nmfhcttepqtrlbuqc8u28d7pjcf2t.apps.googleusercontent.com";
  const clientId =
    "728207504628-o8baeuht3hom6u2ov3lklp49lidssk4h.apps.googleusercontent.com";

  useEffect(() => {
    for (let info of data) {
      if (info.service === "google") {
        setInfo(info);
        setConnectGoogle(true);
      }
    }
    setConnectedAPI(data.length);
  }, [data]);

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: "",
      });
    }

    gapi.load("client:auth2", start);
  }, []);

  const addGoogle = async (res) => {
    if (res && res.credential) {
      const decoded = jwtDecode(res.credential);
      const data = await GoogleConnect(decoded.email, decoded.sub);
      if (data.status === 0) {
        showToast(data.message, { type: "error" });
        return;
      }
      if (data.status === 1) {
        setInfo({ email: decoded.email });
        setConnectGoogle(true);
        window.location.href = USER_SETTINGS_ROUTE;
      }
    }
  };

  const deleteGoogle = async () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="fixed  mx-auto p-[10px] w-[540px] message-popup bg-[#F8F8F8] self-center dark:bg-[#000000] border-[#DAD7D4] m-auto inset-x-0 inset-y-0 border-[1px] rounded-md custom-items-center">
            <h2 className="block mb-[20px] justify-left text-lg md:text-xl font-semibold text-[#645F5B] dark:text-white dark:text-[#EAEAEA]">
              Unlink account
            </h2>
            <p className="block justify-left mb-[40px] text-base text-[#645F5B] dark:text-white">
              Confirm removal of connected account.
            </p>
            <div className="flex justify-between">
              <button
                className="message-btn btn-delete text-base  p-[5px] text-[#e1655c] border-[1px] border-[#eac5c3] rounded"
                onClick={async () => {
                  const deleteThis = await deleteConnect("google");
                  if (deleteThis) {
                    setConnectGoogle(false);
                    window.location.href = USER_SETTINGS_ROUTE;
                  }
                  onClose();
                }}
              >
                Remove
              </button>
              <button
                className="message-btn btn-cancel text-base   p-[5px] text-[#645f5b] border-[1px] border-[#eaeaea] dark:border-[#ffffff] dark:text-[#ffffff] rounded"
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          </div>
        );
      },
    });
  };

  return (
    <div className="border-[1px] border-color-[#e3e3e3] rounded py-1.5 px-2 h-[93px] flex flex-col justify-between">
      <div className="flex flex-row justify-between">
        <p className="text-[#645F5B] dark:text-[#ffffff] font-bold text-sm">
          Google
        </p>
        <div className="flex cursor-pointer">
          {!connectGoogle && (
            <div id="googleSignInButton" className="mx-auto ConnectedAccount">
              <button className="bg-[#EAEAEA] absolute right-0 h-[35px] w-[50px] rounded font-semibold text-sm text-[#589ED5] cursor-pointer hover:bg-[#E2E2E2] active:bg-[#CCC]">
                Add
              </button>
              <GoogleOAuthProvider clientId={clientId} logo_alignment="left">
                <GoogleLogin
                  onSuccess={addGoogle}
                  onError={() => {
                    console.log("Login Failed");
                  }}
                  theme="outline"
                  type="icon"
                  size="large"
                  shape="rectangular"
                />
              </GoogleOAuthProvider>
            </div>
          )}
          {connectGoogle && (
            <>
              {connectedAPI && (
                <div
                  className="flex items-center h-[35px] w-[75px] justify-center  border border-[#EAEAEA] rounded cursor-pointer"
                  onClick={deleteGoogle}
                >
                  <span className="font-semibold text-[#645F5B] dark:text-[#fff] text-sm leading-none">
                    Remove
                  </span>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {info &&
        (info?.is_protected === true ||
        info?.is_protected?.trim() === "true" ? (
          <em className="text-[#645F5B] dark:text-[#ffffff] text-[14px] truncate block ">
            Email hidden with provider (
            <span className="text-[#0A85D1]">
              <a
                href={LAYER_PAGE_CONNECTION}
                target="_blank"
                rel="noopener noreferrer"
                className="underline hover:underline focus:underline"
              >
                learn more
              </a>
            </span>
            )
          </em>
        ) : (
          info?.email && (
            <div className="flex flex-row justify-between">
              <span className="text-[#645F5B] dark:text-[#ffffff] text-sm truncate block">
                {info?.email}
              </span>
              <img height={20} width={20} src={greenCheck} alt="greenCheck" />
            </div>
          )
        ))}
    </div>
  );
};

export default GoogleConnection;
