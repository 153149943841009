import { useState, useContext } from "react";

import { toast } from "react-toastify";
import { addLink, deleteComponent, editLink, getInfo, saveComponents } from "../../http/userApi";
import {
  lockIconRed,
  titleDelete,
  editIcon,
  titleLink,
  titleEmail,
} from "../../images/index";

import LinksList from "../LinksList";
import { Context } from "../../index";
import { showToast } from "../../utils/BannerPopup";

const ProtectedLinkComponent = ({ metadata }) => {

  const { user } = useContext(Context);

  const isAuthPremium = user.user.is_premium;
  ////// states for protected link
  const [isProtectedValid, setProtectedValid] = useState(false);
  const [isProtectedEmail, setProtectedEmail] = useState(false);
  const [protectedTitle, setprotectedTitle] = useState("");
  const [protectedDescription, setProtectedDescription] = useState("");
  const [protectedLink, setprotectedLink] = useState("");
  const [createLinkForm, setCreateLinkForm] = useState(false);
  const [createLinkForm2, setCreateLinkForm2] = useState(false);
  const [createProtected, setCreateProtected] = useState(true);
  const [itemId, setTimeId] = useState(0);

  const [links, setLinks] = useState(metadata);

  const [password, setPassword] = useState("");

  // var linksCounterProtected = 0;
  var linksCounterProtected = links?.meta_data?.length ?? 0;;

  /// On Delete Refresh Links
  const refreshData = async (e) => {
    // await getInfo().then((data) => {
    //   links.setLinks(data.links);
    //   setCreateLinkForm(false);
    //   setPassword(null);
    //   setTimeId(0);
    setLinks(metadata)
    setprotectedLink("");
    setPassword("");
    setCreateLinkForm2(false);
    setCreateLinkForm(false);
    setCreateProtected(true);
  };

  const deleteLink = async (id) => {
    try {
      const data = {
        user_id: user.user.id,
        item_id: id,
        meta_id: metadata?.meta_id
      };

      const response = await deleteComponent(data);
      if (response.status) {
        setLinks({
          "meta_id": metadata?.meta_id,
          "item_index": metadata?.item_index,
          "meta_data": response?.data?.[0]?.data
        });
        metadata.meta_data = response.data;

        linksCounterProtected = response.data.length;
      }
      setprotectedLink("");
      setPassword("");
      setCreateLinkForm2(false);
      setCreateProtected(true);

      console.log("Text deleted successfully:", response?.response);
    } catch (error) {
      console.error("Error deleting Text:", error);
    }
  };



  /// Create Protected Link
  const createProtectedLink = async (e) => {
    e.preventDefault();
    if (protectedLink.length == 0) {
      toast("Please enter url.");
      return false;
    }
    if (password.length == 0) {
      toast("Please enter password.");
      return false;
    }
    const tempProtectedLink = isProtectedEmail
      ? protectedLink?.includes("mailto")
        ? protectedLink
        : `mailto:${protectedLink}`
      : protectedLink?.includes("http")
        ? protectedLink
        : `http://${protectedLink}`;

    // setLoading(true);

    // const addPvtLink = await addLink(
    //   protectedTitle,
    //   tempProtectedLink,
    //   protectedDescription,
    //   password
    // );

    // await getInfo().then((data) => {
    //   links.setLinks(data.links);
    // });


    var payload = {};
    payload = {
      "user_id": user.user.id,
      "meta_id": metadata?.meta_id,
      "meta_name": "protectedLink",
      "item_index": metadata?.item_index,
      "data": [
        {
          "title": protectedTitle,
          "link": tempProtectedLink,
          "userId": user.user.id,
          "description": protectedDescription,
          "protected": true,
          "password": password

        }
      ]
    };


    const data = await saveComponents(payload);
    if (data.status) {
      setTimeId(0);
      // setprotectedLink("");
      // setPassword("");
      // setprotectedTitle("");
      // setProtectedDescription("");
      // setCreateLinkForm2(false);
      // setCreateProtected(true);
      // setProtectedValid(false);
      // setProtectedEmail(false);
      setLinks({
        "meta_id": metadata?.meta_id,
        "item_index": metadata?.item_index,
        "meta_data": data?.componentItems
      });
      metadata.meta_data = data.componentItems;

      linksCounterProtected = data.componentItems.length;

    } else {
      showToast(data.message);
    }
    cancelCreateLink2();
  };
  /////////////////  Protected Title box functions    ///////////////

  const handleProtectedButtonClick = (type) => {
    setProtectedEmail(type === "email");
    setprotectedLink("");
    setprotectedTitle("");
    setProtectedDescription("");

    setProtectedValid(false);
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value.trim();
    setPassword(value);
    setProtectedValid(
      isProtectedValidLink(protectedLink) && value.trim() !== ""
    );
  };
  const modifyLink = async (itemId, protectedTitle, tempProtectedLink, protectedDescription, password) => {

    // const data = await editItem(tempQuestion, tempAnswer, authUserId, id);
    const payload = {

      "user_id": user.user.id,
      "item_id": itemId,
      "meta_id": links?.meta_id,
      "data": [
        {
          "meta_data": {
            "title": protectedTitle,
            "link": tempProtectedLink,
            "userId": user.user.id,
            "description": protectedDescription,
            "protected": true,
            "password": password

          }
        }
      ]
    };

    const data = await saveComponents(payload);
    if (data.status) {
      setLinks({
        "meta_id": metadata?.meta_id,
        "item_index": metadata?.item_index,
        "meta_data": data?.componentItems
      });
      metadata.meta_data = data.componentItems;

    } else {
      showToast(data.message);
    }
  };

  /// Edit Protected Link
  const updateProtectedLink = async (e) => {
    e.preventDefault();
    if (protectedLink.length == 0) {
      toast("Please enter url.");
      return false;
    }
    if (password.length == 0) {
      toast("Please enter password.");
      return false;
    }
    const tempProtectedLink = isProtectedEmail
      ? protectedLink?.includes("mailto")
        ? protectedLink
        : `mailto:${protectedLink}`
      : protectedLink?.includes("http")
        ? protectedLink
        : `http://${protectedLink}`;

    // const data = await editLink(
    //   itemId,
    //   protectedTitle,
    //   tempProtectedLink,
    //   protectedDescription,
    //   password
    // );

    // if (data) {
    //   setTimeId(data.id);
    //   setprotectedLink(data.link);
    //   setPassword(data.password);
    //   setCreateProtected(false);
    // }
    // setCreateLinkForm2(false);
  };

  /// Delete Protected Link
  const removeLink = async () => {
    setprotectedTitle("");
    setProtectedDescription("");
    // await deleteLink(itemId);
    setTimeId(0);
    setprotectedLink("");
    setPassword("");
    setCreateLinkForm2(false);
    setCreateProtected(true);
    refreshData();
  };
  /// Cancel Protected Settings
  const cancelCreateLink2 = () => {
    setprotectedTitle("");
    setProtectedDescription("");
    setprotectedLink("");
    setPassword("");
    setProtectedValid(false);
    setProtectedEmail(false);
    setCreateLinkForm2(false);
  };

  const isProtectedValidLink = (link) => {
    const urlPattern =
      /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    console.log("isProtectedEmail", isProtectedEmail);
    console.log(
      "isProtectedEmail ? emailPattern.test(link) : urlPattern.test(link)",
      isProtectedEmail ? emailPattern.test(link) : urlPattern.test(link)
    );
    return isProtectedEmail ? emailPattern.test(link) : urlPattern.test(link);
  };

  const handleProtectedLinkInput = (value) => {
    setprotectedLink(value);
    const urlPattern =
      /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailPattern?.test(value) || value?.includes("mailto:")) {
      setProtectedEmail(true);
      setProtectedValid(emailPattern.test(value) && password.trim() !== "");
    } else if (value?.includes("http://") || value?.includes("https://")) {
      setProtectedEmail(false);
      setProtectedValid(urlPattern?.test(value) && password.trim() !== "");
    } else {
      setProtectedValid(isProtectedValidLink(value) && password.trim() !== "");
    }
  };

  return (
    <>
      {/* {links.links.map((item, index) => {
        if (item.password) {
          linksCounterProtected = linksCounterProtected + 1;
        }
      })} */}
      {isAuthPremium && (
        <>
          {/* <div className="border-b border-[#E0DFDE] dark:border-[#575350] mt-[40px] mb-[40px] "></div> */}
          <div
            className={`w-full p-[10px] border rounded flex justify-between h-[62px] border-[#F4F3F3] mb-[10px] 
                     ${protectedLink ? "hidden" : ""}  
                     ${linksCounterProtected >= 3
                ? "border-[#E1655C]"
                : "border-[#F4F3F3]"
              }
                 ${createLinkForm2 ? "hidden" : ""}`}
          >
            {linksCounterProtected >= 3 && (
              <span className="text-[#E1655C] text-[12px]">
                The maximum number of Protected links is 3
              </span>
            )}
            <button
              onClick={() => setCreateLinkForm2(true)}
              disabled={linksCounterProtected >= 3}
              className="ml-auto mr-0 px-[10px] py-[6px] flex justify-center 
                     items-center rounded bg-[#EAEAEA] font-semibold text-[10px]
                      text-[#E1655C] border border-[#E8E7E366] leading-[12px] h-[27px]"
            >
              Add Protected Link
              <img
                src={lockIconRed}
                className="cursor-pointer ml-[10px] h-[15px]"
                alt="lockIconRed"
              />
            </button>
          </div>

          <div
            className={`w-full p-[10px] border rounded flex justify-between h-[62px] border-[#F4F3F3] mb-[10px] ${protectedLink ? "" : "hidden"
              } 
                 ${createLinkForm2 ? "hidden" : ""}`}
          >
            <button
              onClick={() => setCreateLinkForm2(true)}
              className="ml-auto mr-0 px-[10px] py-[6px] flex justify-center 
                     items-center rounded bg-[#EAEAEA] font-semibold text-[10px] text-[#E1655C] border border-[#E8E7E366] leading-[12px] h-[27px]"
            >
              Edit Protected Link
              <img
                src={editIcon}
                className="cursor-pointer ml-[10px] h-[15px]"
                alt="editIcon"
              />
            </button>
          </div>
        </>
      )}

      <form
        className={`
                   ${createLinkForm2
            ? "w-full p-2.5 rounded flex flex-col justify-between border"
            : "hidden"
          }
                   
                     border-[#EAEAEA] mb-[40px]`}
        onSubmit={createProtected ? createProtectedLink : updateProtectedLink}
      >
        <div className="flex justify-between">
          <input
            type="text"
            className="bg-transparent text-[#645F5B] font-semibold outline-0 w-full text-[14px] dark:text-[#FFFFFF]"
            onChange={(e) => setprotectedTitle(e.target.value)}
            value={protectedTitle ? protectedTitle : ""}
            placeholder="Title (optional)"
          />
        </div>
        <input
          placeholder="Description (optional)"
          className="outline-0 mt-[5px] mb-[15px] text-base text-[#645F5B] bg-transparent dark:text-[#fff]"
          maxLength={50}
          value={protectedDescription ? protectedDescription : ""}
          onChange={(e) => setProtectedDescription(e.target.value)}
        />
        <p className="text-xs text-[#645F5B] dark:text-white">
          The protected link or email address:
        </p>
        <input
          type="text"
          className="bg-[#eaeaea] px-2 mt-1.5 mb-5 text-[#645F5B] text-base outline-0 text-[14px] dark:text-[#645F5B] h-[35px] rounded w-full"
          onChange={(e) => handleProtectedLinkInput(e.target.value)}
          value={protectedLink ? protectedLink : ""}
          placeholder={isProtectedEmail ? "Email address" : "Link URL"}
        />
        <span className="text-[#E1655C] text-base text-[12px]">
          The password protecting it:
        </span>
        <div className="relative mt-2 mb-5">
          <input
            placeholder="Password"
            className="bg-[#FAF4EE] px-2 placeholder-[#E1655C]  text-[#E1655C] text-base outline-0 text-[14px] dark:text-[#E1655C] h-[35px] rounded w-full"
            maxLength={12}
            value={password}
            onChange={handlePasswordChange}
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
          />
          <img
            src={lockIconRed}
            className="cursor-pointer ml-[10px] h-[15px] absolute right-[10px] top-[10px]"
            alt="lockIconRed"
          />
        </div>
        <div className="flex justify-between align-center">
          <div className="flex justify-start space-x-2">
            <button
              type="submit"
              className={
                isProtectedValid
                  ? "h-[25px] w-[49px] flex justify-center items-center space-x-1.5 border border-[#5FC650] text-[#5FC650] font-semibold cursor-pointer text-[10px] radius rounded-[5px]"
                  : "h-[25px] w-[49px] flex justify-center items-center space-x-1.5 border border-[#FAF4EE] text-[#645F5B] bg-[#EAEAEA] font-semibold cursor-pointer text-[10px] radius rounded-[5px]"
              }
              disabled={!isProtectedValid}
            >
              <p className="">Save</p>
            </button>
            <span
              className="border border-white text-[#645F5B] dark:text-[#FFFFFF] font-semibold cursor-pointer h-[25px] text-[10px] flex justify-center items-center rounded-[5px] w-[49px]"
              onClick={() => cancelCreateLink2()}
            >
              Cancel
            </span>
          </div>
        </div>
        <div className="flex justify-between mt-[20px]">
          <div className="flex space-x-2">
            <button
              className={`flex h-[25px] w-[25px] p-[5px] border ${!isProtectedEmail ? "bg-[#0A85D1]" : "border-[#0A85D1]"
                } rounded justify-center items-center`}
              type="button"
              onClick={() => handleProtectedButtonClick("link")}
            >
              <img
                src={titleLink}
                height={15}
                width={15}
                alt="link"
                style={{
                  filter: !isProtectedEmail
                    ? "invert(1) brightness(10)"
                    : "none",
                }}
              />
            </button>
            <button
              className={`flex h-[25px] w-[25px] p-[5px] border ${isProtectedEmail ? "bg-[#0A85D1]" : "border-[#0A85D1]"
                } rounded justify-center items-center`}
              type="button"
              onClick={() => handleProtectedButtonClick("email")}
            >
              <img
                src={titleEmail}
                height={15}
                width={15}
                alt="link"
                style={{
                  filter: isProtectedEmail
                    ? "invert(1) brightness(10)"
                    : "none",
                }}
              />
            </button>
          </div>

          <div className="flex space-x-2">
            <button
              className="flex h-[25px] w-[25px] bg-[#FFFFFF] border border-[#E1655C] rounded justify-center items-center"
              onClick={() => removeLink()}
            >
              <img src={titleDelete} height={15} width={15} alt="delete" />
            </button>
          </div>
        </div>
      </form>

      <LinksList
        onDelete={(id) => {
          deleteLink(id);
          // refreshData();
        }}
        showProtectedList={true}
        links={links}
        modifyLink={(itemId, protectedTitle, tempProtectedLink, protectedDescription, password) => modifyLink(itemId, protectedTitle, tempProtectedLink, protectedDescription, password)}
      />
    </>
  );
};
export default ProtectedLinkComponent;
