import React, { useContext, useEffect, useRef, useState } from "react";
import { check,Checkdone,disbaleCheck,tickCirlce } from "../../images";
import { freeUserTypeArr, paidUserTypeArr } from "../../utils"
import { USER_ACCOUNT_ROUTE } from "../../utils/consts";
import {setPremiumUser,createCheckoutSession} from "../../http/userApi";
import { useNavigate } from "react-router-dom";
import { Context } from "../../index";


const BoxData=()=>{

    const navigate = useNavigate();
    const { user, Themefooter } = useContext(Context);
    const headerRef = useRef();


const onConfirm = () => {
    if (user?.isAuth) {
      navigate(USER_ACCOUNT_ROUTE);
    } 
    else {
      headerRef?.current?.callBack();
    }
  };

// const onPaid=async()=>{
//   const res=await setPremiumUser(true);
//   if(res){
//     onConfirm()
//   }
// }  

// const onFree=async()=>{
//   const res=await setPremiumUser(false);
//   if(res){
//     onConfirm()
//   }
// }  

}

export const Box = ({ onConfirm, isConfirmed, isPlus, endPoint }) => {
  const handlePortal = async () => {
    try {
            
      const session = await createCheckoutSession(endPoint);
      
    console.log('session',session)
    window.location.href = session.url;
  } catch (error) {
    console.log('error',error)
  }
  };
    return (
      <div className=" mr-10 w-full md:w-2/4">
        <p className={`text-base  font-regular leading-[19px] text-left dark:text-gray-400 text-[#252423]`}>
        {/* {isPlus ? "Current Plan":"Plus: support Layer with more features"}  */}
        {!isPlus && "Plus: support Layer with more features"}
        </p>
        {!isPlus && <div className="bg-[#F5F5F5] p-4 mt-5 border-[1px] border-[#B30A8E] rounded  dark:bg-[#302F2D]">
          <h3 className={` text-[#9D34DA] text-xl font-semibold `}>$4 per month</h3>
          <p className="mt-2 mb-[8px] text-base text-[#645F5B] dark:text-white">
            Everything in Free and:
          </p>
          {paidUserTypeArr.map((e, i) => {
            return (
              <div key={i} className="flex flex-row mb-[8px] user-type-div">
                {e ? (
                  <img
                    alt="checkIcon"
                    src={e === "Analytics" ? disbaleCheck : Checkdone}
                    className="w-[10px] h-[7.8px] mt-[7px]"
                  />
                ) : (
                  <div className="h-[20px]" />
                )}
                <p className="ml-2 text-sm text-[#645F5B] dark:text-white">
                  {e}
                </p>
              </div>
            );
          })}
          <p className="mt-[20px] text-xs font-regular text-[#6D6D6D] dark:text-[#BCBCBC]">
          <a href="https://layer.guide/features" target="_blank" rel="noreferrer" className="underline  hover:underline focus:underline"> Learn more </a>
          </p>
        </div>
        }
        <button
          onClick={handlePortal}
          className={`my-[20px] text-sm font-semibold border-[1px] rounded py-[5px] px-[8px]  mb-[50px] ${
            isConfirmed ? 'flex text-[#ffffff] border-[#9D34DA] bg-[#9D34DA]' : 'flex text-[#9D34DA] border-[#F0CEE8]'
          }`}
        >
        { isConfirmed ? (isPlus ? "Plus":"Plus confirmed") : 'Get Plus'}
        {isConfirmed  && <img className='w-[16px] ml-[8px]' src={tickCirlce} alt='tick' />}
        </button>
      </div>
    );
  };

 export const FreeBox = ({ isLoggedIn=false ,isConfirmed, onConfirm ,isFree=false }) => {
    return (
     <div className="mr-10 w-full md:w-2/4">
        <p className={`text-base  font-regular leading-[19px] text-left text-[#252423]  dark:text-white`}>
        {/* {isFree ? "Current Plan":"Free: use Layer for free"}  */}
        Free: use Layer for free
        </p>
       {!isFree && <div className="bg-[#F5F5F5] p-4 mt-5 border-[1px] border-[#00A3DD] rounded dark:bg-[#302F2D]">
          <h2 className={`  text-[#0A85D1] text-xl font-semibold  `}>Free</h2>
          <p className="mt-2 mb-[8px] text-base text-[#645F5B] dark:text-white">
          Free features:
          </p>
          {freeUserTypeArr.map((e, i) => {
            return (
              <div key={i} className="flex flex-row mb-[8px] user-type-div">
                <img alt="checkIcon" src={Checkdone} className="w-[10px] h-[7.8px] mt-[7px]" />
  
                <p className="ml-2 text-sm text-[#645F5B] dark:text-white">
                  {e}
                </p>
              </div>
            );
          })}
          <p className="mt-[20px] text-xs font-regular text-[#6D6D6D]">
          <a href="https://layer.guide/features" target="_blank" rel="noreferrer" className="underline  hover:underline focus:underline"> Learn more </a>
          </p>
        </div>
       } 
        <button
          onClick={onConfirm}
          className={`mt-[20px]  text-sm font-semibold border-[1px] rounded py-[5px] px-[8px] border-[#CCEDF8] mb-[10px] ${
            isConfirmed ? 'flex text-[#ffffff] border-[#00A3DD] bg-[#00A3DD]' : 'flex text-[#0A85D1] border-[#CCEDF8]'
          }`}
        >
          {isLoggedIn && isConfirmed ? (isFree ? "Free":"Free confirmed") : 'Get Free'}
          {isConfirmed  && <img className='w-[16px] ml-[8px]' src={tickCirlce} alt='tick' />}
        </button>
      </div>
    );
  };

export const TitleBox = ({ title, buttonText, href }) => {
    return (
      <div className="">
        <p className={`text-sm text-[#645F5B] dark:text-[#fff]`}>{title}</p>
        <a href={href} target="_blank" rel="noreferrer">
          <button
            className={`mt-[15px] text-[#645F5B] font-semibold text-base  border-[1px] rounded py-[5px] px-[8px] border-[#eaeaea] min-w-[120px] dark:text-[#fff]`}
          >
            {buttonText}
          </button>
        </a>
      </div>
    );
  };