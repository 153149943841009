import React from "react";
import { toast } from "react-toastify";

const CustomToast = (message) => {
  let fontSizeClass = "text-base"; 

  if (message.length > 100) {
    fontSizeClass = "text-xs"; 
  } else if (message.length > 50) {
    fontSizeClass = "text-base"; 
  }

  return (
    <>
      <div className={`flex-grow-1 absolute top-2 left-2 text-lg font-medium  dark:text-[#EAEAEA]  ${fontSizeClass}`}>{message}</div>
      <span className="absolute bottom-2 left-2 w-2 h-2 bg-[#F2FB88] rounded-full"></span>
    </>
  );
};

const BannerPopup = ({ message, isVisible }) => {
  if (!isVisible) return null;

  return (
    <>
     {/* <div className="absolute top-2 left-2 text-lg font-medium  dark:text-[#EAEAEA]">{message}</div> */}
     {/* <span className="absolute bottom-2 left-2 w-2 h-2 bg-[#F2FB88] rounded-full"></span> */}
    </>
  );
};

export const showToast = (message) => {
  toast(CustomToast(message), {
    position: "bottom-left",
    className: "custom-toast",
    closeButton:false,
  });
};

export default BannerPopup;
