import React, { useState, useContext, useEffect, useRef } from "react";
import {
  Fishcropped1,
  Fishcropped2,
  Fishcropped3,
  Fishcropped4,
  Fishcropped5,
  Fishcropped6,
  Fishcropped7,
  previewIcon,
  shareIcon,
  Arrangements,
  ComponentsIcon,
} from "../images/index";
import { Context } from "../index";
import QuestionAndAnswer from "../components/Base/QuestionAndAnswer";
import LinksComponent from "../components/Base/LinksComponent";
import ProtectedLinkComponent from "../components/Base/ProtectedLinkComponent";
import Showcase from "../components/Base/Showcase";
import TextComponent from "../components/Base/TextComponent";
import ImageComponent from "../components/Base/ImageComponent";
import { useNavigate } from "react-router-dom";
import { COMPONENT_ID } from "../utils/index";
import { showToast } from "../utils/BannerPopup";
import { createCheckoutSession } from "../http/userApi";
import { BASE_ORIGIN } from "../utils/consts";

const Toolbar = ({ addSection, BioComponent, setArrangement, arrangement,countComponents,setCountComponents  }) => {
  const { user } = useContext(Context);
  const isAuthPremium = user.user.is_premium;
  const [showOptions, setShowOptions] = useState(false);
  const modalRef = useRef(null);
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [componentFilter, setComponentFilter] = useState("Total");
  const navigate = useNavigate();

  const handlePortal = async () => {
    try {
      const session = await createCheckoutSession(BASE_ORIGIN);
      window.location.href = session.url;
    } catch (error) {
      console.log("error", error);
    }
  };

  const getFilteredComponents = () => {
    if (componentFilter === "Free") {
      //  Filter free components

      return [
        {
          id: COMPONENT_ID.TEXT_COMPONENT,
          component: TextComponent,
          label: "Text",
          description: "Larger text with or without a border.",
          image: Fishcropped4,
          bgColor: "#904736",
          type: "Free",
        },
        {
          id: COMPONENT_ID.LINK_COMPONENT,
          component: LinksComponent,
          label: "Link",
          description: "Links/emails (up to 10 per module).",
          image: Fishcropped3,
          bgColor: "#633690",
          type: "Free",
        },
        {
          id: COMPONENT_ID.BIO_COMPONENT,
          component: BioComponent,
          label: "Brief",
          description: "Smaller biography/text.",
          image: Fishcropped1,
          bgColor: "#906736",
          type: "Free",
        },
        {
          id: COMPONENT_ID.QA_COMPONENT,
          component: QuestionAndAnswer,
          label: "Expand",
          description: "Dropdown information boxes.",
          image: Fishcropped2,
          bgColor: "#366B90",
          type: "Free",
        },
      ];
    }
    if (componentFilter === "Plus") {
      //   Filter plus components

      return [
        {
          id: COMPONENT_ID.LINK_COMPONENT,
          component: ProtectedLinkComponent,
          label: "Lock",
          description: "Locked links/emails (up to 5 per module).",
          image: Fishcropped5,
          bgColor: "#903651",
          type: "Plus",
        },
        {
          id: COMPONENT_ID.SHOWCASE_COMPONENT,
          component: Showcase,
          label: "Block",
          description: "Horizontal color information blocks.",
          image: Fishcropped6,
          bgColor: "#908D36",
          type: "Plus",
        },
        {
          id: COMPONENT_ID.IMAGE_COMPONENT,
          component: ImageComponent,
          label: "Image",
          description: "Add images with or without a border.",
          image: Fishcropped7,
          bgColor: "#369065",
          type: "Plus",
        },
      ];
    }
    return [
      {
        id: COMPONENT_ID.TEXT_COMPONENT,
        component: TextComponent,
        label: "Text",
        description: "Larger text with or without a border.",
        image: Fishcropped4,
        bgColor: "#904736",
        type: "Free",
      },
      {
        id: COMPONENT_ID.LINK_COMPONENT,
        component: LinksComponent,
        label: "Link",
        description: "Links/emails (up to 10 per module).",
        image: Fishcropped3,
        bgColor: "#633690",
        type: "Free",
      },
      {
        id: COMPONENT_ID.BIO_COMPONENT,
        component: BioComponent,
        label: "Brief",
        description: "Smaller biography/text.",
        image: Fishcropped1,
        bgColor: "#906736",
        type: "Free",
      },
      {
        id: COMPONENT_ID.QA_COMPONENT,
        component: QuestionAndAnswer,
        label: "Expand",
        description: "Dropdown information boxes.",
        image: Fishcropped2,
        bgColor: "#366B90",
        type: "Free",
      },
      {
        id: COMPONENT_ID.PROTECTED_COMPONENT,
        component: ProtectedLinkComponent,
        label: "Lock",
        description: "Locked links/emails (up to 5 per module).",
        image: Fishcropped5,
        bgColor: "#903651",
        type: "Plus",
      },
      {
        id: COMPONENT_ID.SHOWCASE_COMPONENT,
        component: Showcase,
        label: "Block",
        description: "Horizontal color information blocks.",
        image: Fishcropped6,
        bgColor: "#908D36",
        type: "Plus",
      },
      {
        id: COMPONENT_ID.IMAGE_COMPONENT,
        component: ImageComponent,
        label: "Image",
        description: "Add images with or without a border.",
        image: Fishcropped7,
        bgColor: "#369065",
        type: "Plus",
      },
    ];
  };

  const handleToggle = () => {
    setShowOptions(!showOptions);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    }
    if (showOptions) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
      setShowOptions(false);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showOptions]);

  const PreviewUser = () => {
    const userView = user.user.username;
    navigate(`/${userView}`);
  };


  const handleAddComponent = async () => {
    if (selectedComponent) {
      const componentTypesMap = {
        [COMPONENT_ID.TEXT_COMPONENT]: 'textComponent',
        [COMPONENT_ID.LINK_COMPONENT]: 'links',
        [COMPONENT_ID.BIO_COMPONENT]: 'bio',
        [COMPONENT_ID.QA_COMPONENT]: 'qa',
        [COMPONENT_ID.PROTECTED_COMPONENT]: 'protectedLink',
        [COMPONENT_ID.SHOWCASE_COMPONENT]: 'showcase',
        [COMPONENT_ID.IMAGE_COMPONENT]: 'images',
      };

      const metaName = componentTypesMap[selectedComponent.id];

      const payload = {
        user_id: user.user.id, 
        meta_name: metaName,   
      };

      try {
      //  const response= await saveComponentsTypes(payload);
        // addSection(selectedComponent);

        addSection(selectedComponent.id, selectedComponent.component, payload);
        setShowOptions(false);
      } catch (error) {
        console.error("Failed to add component:", error);
      }
    } else {
      showToast("Please select a component.");
    }
  };

  const handleComponentSelect = (component) => {
    const isSelected = selectedComponent?.id === component.id;
    const isComponentFreeOrPremium = component.type === "Free" || isAuthPremium;
  
    if (isComponentFreeOrPremium) {
      if (isSelected) {
        setSelectedComponent(null);
        setCountComponents((prevCount) => Math.max(prevCount - 1, 0));
      } else if (!selectedComponent) {
        setSelectedComponent(component);
        setCountComponents((prevCount) => prevCount + 1);
      }
    }
  };
  
  


  return (
    <>
      <div className=" flex flex-col items-center justify-center">
        <div className="pt-[21.5px]" ref={modalRef}>
          {showOptions && (
            <div className=" w-[600px] h-[600px] bg-[#1A1A1A] p-[12px] rounded-[8px] mb-4 addComponent_tool max-w-full">
              <h2 className="text-lg font-medium text-[#fff]">
                Add components
              </h2>
              <div className="flex justify-between mt-[12px] mb-[20px]">
                <div className="flex space-x-2 ">
                  <button
                    onClick={() => setComponentFilter("Total")}
                    className={`w-[54px] h-[30px] border-[1px] p-[3px] rounded-[100px] text-sm ${
                      componentFilter === "Total"
                        ? "bg-[#FFFFFF] text-[#252423]"
                        : "text-[#FFFFFF]"
                    } `}
                  >
                    Total
                  </button>
                  <button
                    onClick={() => setComponentFilter("Free")}
                    className={`w-[54px] h-[30px] border-[1px] p-[3px] rounded-[100px] text-sm ${
                      componentFilter === "Free"
                        ? "bg-[#FFFFFF] text-[#252423]"
                        : "text-[#FFFFFF]"
                    }`}
                  >
                    Free
                  </button>
                  <button
                    onClick={() => setComponentFilter("Plus")}
                    className={`w-[54px] h-[30px] border-[1px] p-[3px] rounded-[100px] text-sm ${
                      componentFilter === "Plus"
                        ? "bg-[#FFFFFF] text-[#252423]"
                        : "text-[#FFFFFF]"
                    }`}
                  >
                    Plus
                  </button>
                </div>
              </div>

              <div className="mb-[20px]">
                {/* Render components */}
                <div className="addComponent_lists overflow-y-auto pr-[12px]">
                  {getFilteredComponents().map((component) => (
                    <div
                      key={component.id}
                      className={` ComponentLists flex justify-between items-center p-[12px] h-[48px] bg-[#333333] rounded mb-[12px] ${
                        selectedComponent?.id === component.id
                          ? "bg-[#4A4A4A]"
                          : "bg-[#333333]"
                      }`}
                      onClick={() => handleComponentSelect(component)}
                    >
                      <div className="flex items-center">
                        <div
                          className={`ml-[-8px] w-[40px] h-[40px] p-[12px_8px] rounded gap-[10px]`}
                          style={{ backgroundColor: component.bgColor }}
                        >
                          <img
                            src={component.image}
                            alt={component.label}
                            className="w-[24px] h-[12px] rounded-md mr-[12px]"
                          />
                        </div>
                        <div className="flex flex-col ml-4">
                          <div className="text-sm font-regular text-white">
                            {component.label}
                          </div>
                          <div className="text-xs font-regular text-white">
                            {component.description}
                          </div>
                        </div>
                      </div>
                      {component.type === "Free" || isAuthPremium ? (
                        <input
                          type="radio"
                          className="custom-radio"
                          checked={selectedComponent?.id === component.id}
                          readOnly
                        />
                      ) : (
                        <div className="w-[20px] h-[20px]"></div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div className="relative border-solid border-t-[1px] border-[#575350]  mx-[-12px] hidden"></div>
              <div className="flex justify-between items-center mt-[12px] absolute left-0 bottom-0 w-full p-[10px] px-[20px] border-solid border-[0px] border-y-[1px] border-[#ffffff20]">
                <div className="text-[#ffffff]">
                 {countComponents}/
                  {isAuthPremium ? (
                    "30"
                  ) : (
                    <>
                      10
                      <span
                        className="underline cursor-pointer ml-1"
                        onClick={handlePortal}
                      >
                        (move to Plus for 30)
                      </span>
                    </>
                  )}
                </div>
                <button
                  className="w-[55px] h-[30px] p-[3px] rounded-[100px] bg-[#00A3DD] text-[#ffffff] "
                  onClick={handleAddComponent}
                >
                  Add
                </button>
              </div>
            </div>
          )}
        </div>

        <div className="flex justify-between items-center bg-[#645F5BE5] p-2 rounded-md w-[170px]">
          <div
            className={`hover-container w-[30px] h-[30px] p-[7px] rounded gap-[10px] bg-[#BCBCBC] ${
              arrangement ? "active-container" : ""
            }`}
            onClick={() => {
              setArrangement(true);
            }}
          >
             {!arrangement && <span className="hover-text">Arrangement</span>}
            <img
              src={Arrangements}
              alt="Arrangements"
              className="w-[18px] h-[18px] rounded-md"
            />
          </div>
             {!arrangement && <span className="hover-dot"></span>}  

          <div
            className={`hover-container w-[30px] h-[30px] p-[7px] rounded gap-[10px] bg-[#BCBCBC] ${
              showOptions ? "active-container" : ""
            }`}
            onClick={() => {
              handleToggle();
              setArrangement(false);
            }}
          >
            {!showOptions && <span className="hover-text">Components</span>}
            <img
              src={ComponentsIcon}
              alt="ComponentsIcon"
              className="w-[18px] h-[18px] rounded-md"
            />
          </div>
          {!showOptions && <span className="hover-dot"></span>}

          <div
            className="hover-container w-[30px] h-[30px] p-[7px] rounded gap-[10px] bg-[#000000]"
            onClick={() => {
              navigator.clipboard.writeText(
                `https://layer.page/${user?.user?.username}`
              );
              setArrangement(false);
              showToast("Layer Copied");
            }}
          >
          {!arrangement && <span className="hover-text">Share</span>}
            <img
              src={shareIcon}
              alt="shareIcon"
              className="w-[18px] h-[18px] rounded-md items-center"
            />
          </div>
          {!arrangement && <span className="hover-dot"></span>}

          <div
            className="hover-container w-[30px] h-[30px] p-[7px] rounded gap-[10px] bg-[#000000]"
            onClick={() => {
              setArrangement(false);
              PreviewUser();
            }}
          >
            <span className="hover-text">Preview</span>
            <img
              src={previewIcon}
              alt="previewIcon"
              className="w-[18px] h-[18px] rounded-md"
            />
            <span className="hover-dot"></span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Toolbar;
