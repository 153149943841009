import { useContext } from "react";
import { Context } from "../../index";

const BioContainer = (metadata) => {
  const { selectedUser } = useContext(Context);

    return (
      <>
        {/* {selectedUser?.selectedUser?.bio && ( */}
        {metadata?.metadata?.meta_data?.[0]?.meta_data?.description && (
          <div className="flex justify-between">
            <textarea
              className="bg-transparent border border-EA rounded focus:outline-none h-20 w-full resize-none p-2.5 dark:text-[#FFFFFF]"
              // value={selectedUser?.selectedUser?.bio}
              value={metadata?.metadata?.meta_data?.[0]?.meta_data?.description}
              readOnly={true}
            />
          </div>
        )}
      </>
    );
  };

  export default BioContainer;