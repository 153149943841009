import React, { useState, useContext } from "react";
import ReactModal from "react-modal";
import { useNavigate } from "react-router-dom";
import { reply, star, trash, replyButton, LayerButton, afterStarred } from "../images";
import { SEARCH_USER } from "../utils/consts";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Modal from "react-modal";
import { Context } from "../index";
import { createMessage } from "../http/messageApi";
import { showToast } from "../utils/BannerPopup";

const PreviewMessage = ({ message, userId, onFav, onDelete }) => {
  const navigate = useNavigate();

  const isSend = message?.userId === userId;
  const [isOpen, setOpen] = useState(false);
  const { user } = useContext(Context);

  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [messageBody, setMessageBody] = useState(null);

  const [userInfo, setUserInfo] = useState(null);

  const date = new Date(message?.createdAt);
  const dateTime =
    date.getHours() +
    ":" +
    (date.getMinutes() < 10 ? "0" : "") +
    date.getMinutes() +
    ", " +
    date.getDate() +
    " " +
    date.toLocaleString("en-US", { month: "short" }) +
    " " +
    date.getFullYear();

  const userLayer = () => {
    let replyUser = isSend ? message?.to : message?.from;
    navigate(SEARCH_USER + replyUser);
  };

  const closeMessageModal = () => {
    setMessageBody("");
    setOpenMessageModal(!openMessageModal);
  };

  const sendMessage = async () => {
    await createMessage(isSend ? message?.to : message?.from, messageBody);
    showToast("Message sent");
    setMessageBody("");
    setOpenMessageModal(!openMessageModal);
  };

  const destroyMessage = async () => {
    // const data = await deleteMessage(message.id);
    onDelete(message);

    isOpen && setOpen(false);
  };

  const confirmDelete = async () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="fixed mx-auto p-[10px] w-[540px] message-popup bg-[#F8F8F8] self-center dark:bg-[#000000] border-[#DAD7D4] m-auto inset-x-0 inset-y-0 border-[1px] rounded-md custom-items-center">
            <h2 className="block mb-[20px] justify-left text-lg md:text-xl font-semibold text-[#645F5B]  dark:text-[#EAEAEA]">
              Permanently delete?
            </h2>
            <div className="flex justify-between">
              <button
                className="message-btn btn-delete text-base  p-[5px] text-[#e1655c] border-[1px] border-[#eac5c3] rounded"
                onClick={() => {
                  destroyMessage();
                  onClose();
                }}
              >
                Delete
              </button>
              <button
                className="message-btn btn-cancel text-base   p-[5px] text-[#645f5b] border-[1px] border-[#eaeaea] dark:border-[#ffffff] dark:text-[#ffffff] rounded"
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          </div>
        );
      },
    });
  };

  return (
    <>
      {/* Modal for sending Message */}

      <Modal
        isOpen={openMessageModal}
        onRequestClose={closeMessageModal}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            height: "auto",
            padding: "0",
            background: "transparent",
          },
        }}
        contentLabel="Message Modal"
      >
        <div className="w-[540px]  max-w-full dark:border-[#000000] bg-[#F8F8F8] text-[#645F5B] dark:bg-[#252423] dark:text-[#fff] p-[20px]  radius-[5px] rounded-[10px]">
          <p className="text-sm  font-regular text-[#645F5B] dark:text-white">
            To: {isSend ? message?.to : message?.from}
          </p>
          <p className=" border-[1px] border-[#E0DFDE] dark:border-[#575350] mt-[12px] "></p>
          <textarea
            className=" w-full outline-0 border border-[#E8E7E366] dark:border-[#575350] bg-[#D6D6D633] rounded text-[#645F5B] dark:text-[#FFFFFF] p-2.5 resize-none h-[200px] margin-t-20 focus:border-[#589ED5]"
            onChange={(e) => setMessageBody(e.target.value)}
            value={messageBody ? messageBody : ""}
            placeholder="Message.."
          />
          <div className="flex justify-between min-w-100 items-center mt-[20px]">
            {user.isAuth && (
              <button
                className={
                  messageBody
                    ? " w-[63px] h-[35px] border border-[#CCEDF8] text-[#00A3DD] px-[10px] py-[9px] rounded cursor-pointer font-semibold leading-none text-base "
                    : "w-[63px] h-[35px] bg-[#EAEAEA] text-[#9B9B9B] px-[10px] py-[9px] rounded cursor-pointer font-semibold leading-none text-base "
                }
                disabled={!messageBody}
                onClick={sendMessage}
              >
                Send
              </button>
            )}

            <button
              className=" w-[77px] h-[35px] btn-cancel p-[10px] border-[1px] border-[#EAEAEA] dark:border-[#EAEAEA] dark:text-[#ffffff] rounded-[5px]  text-[#645F5B] text-16px font-medium flex items-center leading-[18px]"
              onClick={closeMessageModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>

      {message && message.body && (
        <div className="flex-1 ">
          <div className="flex-col border-[1px] border-[#E0DFDE] dark:border-[#575350] rounded-md h-full">
            {/* Message Info */}
            <div className="p-[20px] comms_message flex flex-col">
              <section className="flex flex-row justify-between items-center border-b dark:border-[#575350] pb-[20px] leading-none w-full">
                {message.userId === userId ? (
                  <p className="text-sm text-[#645F5B] dark:text-white">
                    To: {message?.to}
                  </p>
                ) : (
                  <p className="text-sm  text-[#645F5B] dark:text-white ">
                    From: {message?.from}
                  </p>
                )}

                {/* Badge */}
                <span
                  className={` badge px-[5px] py-[2px] rounded-[3px] text-[11px] ${
                    message.userId && message.userId !== 1
                      ? "text-[#7D8618] bg-[#F2FB88]"
                      : "text-[#337C73] bg-[#B3FFF5]"
                  }`}
                >
                  {" "}
                  {message.userId && message.userId !== 1 ? "User" : "Non-user"}
                </span>
              </section>
              {/* Message */}
              <textarea
                className="overflow-y-auto my-[10px] text-sm  text-[#645F5B] dark:text-white bg-[transparent]  bookmarkModal__scrollList w-full mx-auto resize-none flex-1 "
                readOnly={true}
                value={message?.body}
              ></textarea>
            </div>
            {/* Buttons */}

            <section className="mt-auto flex flex-row justify-between items-center border-t-[1px] border-[#DAD7D4] p-[20px]">
              {/* Reply Button */}
              <div className="w-[33.33%]  flex space-x-2">
                {message.userId && message.userId !== 1 && (
                  //
                  <button
                    onClick={() => {
                      setOpenMessageModal(true);
                    }}
                    className="flex items-center border rounded border-[#CCEDF8] dark:border-[#CCEDF8] text-xs font-semibold w-[30px] h-[30px] justify-center flex-row"
                  >
                    <img className="" src={replyButton} alt="replyButton" />
                  </button>
                )}
                {message.userId && message.userId !== 1 && (
                  //
                  <button
                    onClick={userLayer}
                    className="flex items-center border rounded border-[#E0DFDE] dark:border-[#E0DFDE] text-xs font-semibold w-[30px] h-[30px] justify-center flex-row"
                  >
                    <img className="box-theme-icon" src={LayerButton} alt="replyButton" />
                  </button>
                )}
              </div>

              <p className="text-[9px] md:text-lg12 text-[#808080b5] font-semibold dark:text-[#ffffff] w-[33.33%] text-center">
                {dateTime}
              </p>

              <div className="flex flex-row w-[33.33%] justify-end space-x-2">
                {/* Star Icon */}
                <div
                  style={
                    (message?.SenderStarred === true &&
                      message?.userId === user.user.id) ||
                    (message?.ReceiverStarred === true &&
                      message?.userId !== user.user.id)
                      ? { background: "#A19526" }
                      : { background: "" }
                  }
                  onClick={() => onFav(message)}
                  className="cursor-pointer ml-[10px] flex h-[30px] w-[30px] roundedhover:bg-[#eaeaeb99] justify-center items-center  border border-[#F2FB88] rounded "
                >
                 {(message?.ReceiverStarred || message?.SenderStarred) ? (
                    <img
                      height={15}
                      width={15}
                      src={afterStarred}
                      alt="afterStarred"
                    />
                  ) : (
                    <img height={15} width={15} src={star} alt="star" />
                  )}
                </div>

                {/* Delete Icon */}
                <div
                  onClick={confirmDelete}
                  className="cursor-pointer flex h-[30px] w-[30px] rounded  justify-center items-center  border border-[#F6DAD0]"
                >
                  <img height={14} width={14} src={trash} alt="trash" />
                </div>
              </div>
            </section>
          </div>
        </div>
      )}
      {!message?.body && (
        <div className="flex-1 flex-col h-full py-[20px]  text-[#645F5B] dark:text-[#FFFFFF]">
          No Data Available yet!
        </div>
      )}
    </>
  );
};
export default PreviewMessage;
