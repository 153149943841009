import React, { useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "..";
import Header from "../components/Header";
import RightMenu from "../components/RightMenu";
import { USER_ACCOUNT_ROUTE, HOME_ROUTE } from "../utils/consts";
import { setPremiumUser } from "../http/userApi";
import FaqAccordion from "../page/FaqAccordion"

const MapAbout = () => {
  const navigate = useNavigate();
  const { user, Themefooter } = useContext(Context);
  const headerRef = useRef();

  return (
    <main className="flex flex-col justify-between bg-white dark:bg-[#1A1919]">
      <Header ref={headerRef} />

      <div className="border-b border-EA h-full dark:bg-[#1A1919] custom-wrapper">
        <div className="w-full customContainer__left">
          <div
            id="scrollTotop"
            className="flex flex-col justify-between container max-w-5xl px-5 mx-auto h-full"
          >
            <div className=" lg:flex pb-[60px] md:pt-[40px] pt-[20px] space-x-2 items-center">
              <span
                className="text-[#645F5B] dark:text-[#FFFFFF] cursor-pointer"
                onClick={() => navigate(HOME_ROUTE)}
              >
                Home
              </span>
              <span className="text-[#645F5B] dark:text-[#FFFFFF]">/</span>
              <span className="text-[#645F5B] dark:text-[#FFFFFF]">Map</span>
            </div>

            <div className="">
              <h1 className="md:text-xl text-2xl font-bold text-[#252423] dark:text-[#EAEAEA]">
                About
              </h1>
              <p className="text-base font-regular text-[#645F5B] dark:text-white mt-[20px]">
                Layer is a simplified profile page platform—built without the
                noise of social media and with extensive controls. Designed with
                functionality and neutrality in mind. Connect accounts,
                bookmark, change messaging permissions, links and protected
                links, QR codes, blocks, and more features being released.
              </p>
              <div className="mt-[60px]">
                <h1 className="md:text-xl text-2xl font-bold text-[#252423] dark:text-[#EAEAEA] ">
                  Index
                </h1>
              </div>

              <div className="flex justify-between w-full mt-[20px] flex-wrap">
                {/* Left Section */}
                <div className="flex flex-col lg:w-[25%] w-[50%] lg:mb-0 mb-[40px]">
                  <div>
                    <p className="text-base font-semibold text-[#645F5B] dark:text-white">
                      Layer
                    </p>
                    <p
                      className="text-base font-regular text-[#645F5B] dark:text-white mt-[8px] cursor-pointer"
                      onClick={() => navigate(HOME_ROUTE)}
                    >
                      Home
                    </p>
                    <p
                      className="text-base font-regular text-[#645F5B] dark:text-white mt-[8px] cursor-pointer"
                      onClick={() => navigate(HOME_ROUTE)}
                    >
                      Enter
                    </p>
                    <p
                      className="text-base font-regular text-[#645F5B] dark:text-white mt-[8px] cursor-pointer"
                      onClick={() => navigate(HOME_ROUTE)}
                    >
                      Guide
                    </p>
                  </div>
                </div>

                {/* Middle Section */}
                <div className="flex flex-col lg:w-[25%] w-[50%] lg:mb-0 mb-[40px]">
                  <div>
                    <p className="text-base font-semibold text-[#645F5B] dark:text-white cursor-pointer">
                      Legal
                    </p>
                    <p
                      className="text-base font-regular text-[#645F5B] dark:text-white mt-[8px] cursor-pointer"
                      onClick={() => navigate(HOME_ROUTE)}
                    >
                      Privacy
                    </p>
                    <p
                      className="text-base font-regular text-[#645F5B] dark:text-white mt-[8px] cursor-pointer"
                      onClick={() => navigate(HOME_ROUTE)}
                    >
                      Terms
                    </p>
                  </div>
                </div>

                {/* Right Section */}
                <div className="flex flex-col lg:w-[25%] w-[50%]">
                  <div>
                    <p className="text-base font-semibold text-[#645F5B] dark:text-white ">
                      Learn
                    </p>
                    <p
                      className="text-base font-regular text-[#645F5B] dark:text-white mt-[8px] cursor-pointer"
                      onClick={() => navigate(HOME_ROUTE)}
                    >
                      Features
                    </p>
                    <p
                      className="text-base font-regular text-[#645F5B] dark:text-white mt-[8px] cursor-pointer"
                      onClick={() => navigate(HOME_ROUTE)}
                    >
                      Changes
                    </p>
                    <p
                      className="text-base font-regular text-[#645F5B] dark:text-white mt-[8px] cursor-pointer"
                      onClick={() => navigate(HOME_ROUTE)}
                    >
                      Control
                    </p>
                    <p
                      className="text-base font-regular text-[#645F5B] dark:text-white mt-[8px] cursor-pointer"
                      onClick={() => navigate(HOME_ROUTE)}
                    >
                      Connections
                    </p>
                    <p
                      className="text-base font-regular text-[#645F5B] dark:text-white mt-[8px] cursor-pointer"
                      onClick={() => navigate(HOME_ROUTE)}
                    >
                      Payments
                    </p>
                  </div>
                </div>

                {/* Social Section */}
                <div className="flex flex-col lg:w-[25%] w-[50%]">
                  <div>
                    <p className="text-base font-semibold text-[#645F5B] dark:text-white">
                      Social
                    </p>
                    <p
                      className="text-base font-regular text-[#645F5B] dark:text-white mt-[8px] cursor-pointer"
                      onClick={() => navigate(HOME_ROUTE)}
                    >
                      LinkedIn
                    </p>
                    <p
                      className="text-base font-regular text-[#645F5B] dark:text-white mt-[8px] cursor-pointer"
                      onClick={() => navigate(HOME_ROUTE)}
                    >
                      X
                    </p>
                    <p
                      className="text-base font-regular text-[#645F5B] dark:text-white mt-[8px] cursor-pointer"
                      onClick={() => navigate(HOME_ROUTE)}
                    >
                      Instagram
                    </p>
                  </div>
                </div>
              </div>
            <FaqAccordion />
            </div>
          </div>
        </div>
        <Themefooter></Themefooter>
      
      </div>
      <RightMenu />
    </main>
  );
};

export default MapAbout;
