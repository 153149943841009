import React, { useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "..";
import Header from "../components/Header";
import RightMenu from "../components/RightMenu";
import { disbaleCheck, freesketch, plussketch,Checkdone } from "../images";
import { freeUserTypeArr, paidUserTypeArr } from "../utils";
import { USER_ACCOUNT_ROUTE,HOME_ROUTE } from "../utils/consts";
import {setPremiumUser} from "../http/userApi"
import {Box,FreeBox,TitleBox} from "../components/PlanDescription/BoxData"

const Features = () => {
  const navigate = useNavigate();
  const { user, Themefooter } = useContext(Context);
  const headerRef = useRef();

  const onConfirm = () => {
    if (user?.isAuth) {
      navigate(USER_ACCOUNT_ROUTE);
    } else {
      headerRef?.current?.callBack();
    }
  };

  const onPaid=async()=>{
    const res=await setPremiumUser(true);
    if(res){
      onConfirm()
    }
  }  

  const onFree=async()=>{
    const res=await setPremiumUser(false);
    if(res){
      onConfirm()
    }
  } 


  return (
    <main className="flex flex-col justify-between bg-white dark:bg-[#1A1919]">
      <Header ref={headerRef} />

      <div className="border-b border-EA h-full dark:bg-[#1A1919] custom-wrapper">
        <div className="w-full pb-[20px] md:pb-[40px]">
          <div id="scrollTotop"  className="flex flex-col justify-between container max-w-5xl px-5 mx-auto h-full">
            <div className="hidden lg:flex pb-[60px] md:pt-[40px] pt-[20px] space-x-2 items-center">
              <span className="text-[#645F5B] dark:text-[#FFFFFF] cursor-pointer" onClick={() => navigate(HOME_ROUTE)}>
                Layer
              </span>
              <span className="block w-1.5 h-1.5 bg-EA rounded-full"></span>
              <span className="text-[#645F5B] dark:text-[#FFFFFF]">
                Features
              </span>
            </div>

            <div className="flex flex-1  border-[#EAEAEA] md:pt-10 pt-[20px] mt-25 flex-col lg:border-t-[1px]"> {/*divider*/}
              <h2 className="md:text-xl text-lg font-semibold text-[#645F5B] dark:text-white dark:text-[#EAEAEA]">
                Plans
              </h2>

              {/*  */}
              <div className="flex justify-between flex-col md:flex-row w-full pt-10">
              <Box
                  onConfirm={onPaid}
                />

                <div className="flex w-full md:w-2/4 justify-center">
                  <img
                    style={{ objectFit: "contain" }}
                    width={"60%"}
                    src={plussketch}
                    height={100}
                  />
                </div>
              </div>

              {/* Free */}
              <div className="flex justify-between flex-col md:flex-row w-full pt-10">
              <FreeBox
              isLoggedIn={user?.isAuth} 
               onConfirm={onFree}
               />

                <div className="flex w-full md:w-2/4 justify-center">
                  <img
                    style={{ objectFit: "contain" }}
                    width={"60%"}
                    src={freesketch}
                    height={104}
                  />
                </div>
              </div>

              <div className="h-[1px] w-full bg-[#EAEAEA] mt-10 md:mt-0" />
              {/* Bottom Buttons */}
              <div className="mt-10 flex-col">
                <TitleBox
                  title={"View all of the features in action:"}
                  buttonText={"Showcase"}
                  href="https://layer.guide/features"
                />
                <TitleBox
                  title={"Contact us:"}
                  buttonText={"Sales"}
                  href="https://layer.guide/support"
                />
                <TitleBox
                  title={"View the guide:"}
                  buttonText={"Guide"}
                  href="https://layer.guide"
                />
              </div>
            </div>
          </div>
        </div>
        <Themefooter></Themefooter>
      </div>
      <RightMenu />
    </main>
  );
};

export default Features;

