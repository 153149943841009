import React, { useState, useEffect, useRef } from 'react';
import { greenCheck, tickCirlce } from '../images/index'
import {getOtpSignin} from "../http/userApi"

const EmailModal = ({ isOpen, onClose, onSubmit }) => {
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [showSent, setShowSent] = useState(false);
  const modalRef = useRef(null);
  const [validationMessage, setValidationMessage] = useState(null);

  const validTlds = [".com", ".org", ".net", ".in", ".co.uk", ".io", ".co", ".tv", "..."]; 

  function isValidTld(email) {
    const domain = email.split('@')[1];
    return domain.length >= 2 && validTlds.includes(domain.split('.').pop());
  }
  
    const validateEmail = (inputEmail) => {

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
      if (!emailRegex.test(inputEmail)) {
        setValidationMessage('Invalid email format.');
        return; 
      }

    if (/^\s*|\s*$/.test(inputEmail)) {
      return { valid: false, message: "Email cannot start or end with whitespace." };
    }
  
    if (/[.]\./.test(inputEmail)) {
      return { valid: false, message: "Email cannot contain consecutive dots." };  }
  
      if (!isValidTld(inputEmail)) {
        setValidationMessage('Email address may not be deliverable due to uncommon TLD.');
        return;
      }
      setValidationMessage('Email appears valid.');
    };

  const handleInputChange = (e) => {
    const inputEmail = e.target.value;
    setEmail(inputEmail);
    setIsValidEmail(validateEmail(inputEmail));
    setShowSent(false);
  };


  const cancelButton = () => {
    setEmail("");
    setIsValidEmail(false);
  }
 
  const sendOtp = async (email) => {
    try {
      const response = await getOtpSignin(email);
      console.log('OTP sent:', response);
    } catch (error) {
      console.error('Error sending OTP:', error.response ? error.response.data : error.message);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault(); 
    // sendOtp(email)
    setShowSent(true)
    setTimeout(onSubmit(email), 1000);
  };


  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        // setIsOpen(false);
        onClose();
      }
    }
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      onClose();
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 z-[99] overflow-y-auto customLogin_modalOverlay enterMail_overlay mt-0">
          <div className="flex items-center justify-center min-h-screen">
            <div className="fixed inset-0 bg-[#EAEAEA] bg-opacity-80 dark:bg-[#606060CC] transition-opacity customLogin_modalOverlay" aria-hidden="true"></div>

            <div ref={modalRef} className="relative bg-white p-4 modal-content dark:bg-[#252423]  dark:border-[#EAEAEA] dark:border-1 rounded-[10px]" >
              {/* Modal content */}
              <div className="text-left">
                <div className="flex justify-between mb-[20px]">
                  <h2 className=" mb-0 text-lg md:text-xl font-semibold text-[#645F5B]  dark:text-[#EAEAEA]">Enter your Email</h2>
                  <h2 className=" mb-0 text-lg md:text-xl font-semibold text-[#645F5B]  dark:text-[#EAEAEA]">1/2</h2>
                </div>
                <p className="block mb-2 text-[#645F5B] dark:text-[#EAEAEA] text-base">Enter your email address and receive a verification email </p>
                <form>
                  <div className='relative'>
                    <input
                      type="email"
                      placeholder="Email address"
                      className="bg-gray-50 border border-[#EAEAEA] text-sm rounded-md focus:border-[#0A85D1] block w-full py-2 px-4 dark:bg-[#D6D6D633] 
                               dark:placeholder-[#FFFFFF] dark:text-white mt-10 mb-[20px] font-normal text-[#645F5B] dark:border-white dark:border-1 focus:ring-[#0A85D1] min-h-[40px] outline-none text-base outline-none"
                      value={email}
                      onChange={handleInputChange}
                      required
                    />
                    {isValidEmail && <img className='absolute right-[5px] bottom-[8px] w-[23px]' src={greenCheck} alt='greencheck'/>}

                  </div>
                  <div className='flex justify-between'>
                    {!showSent && <button
                      type="submit"
                      className={isValidEmail
                        ? "border border-[#5FC650] text-[#5FC650] px-[10px] py-[9px] rounded cursor-pointer font-semibold leading-none text-base "
                        : "bg-[#EAEAEA] text-[#9B9B9B] px-[10px] py-[9px] rounded cursor-pointer font-semibold leading-none text-base "
                      }
                      disabled={!isValidEmail} 
                      onClick={handleSubmit}
                    >
                      Send
                    </button>
                    }
                    {showSent && <div className='relative'>
                      <button
                        type="button"
                        className="border border-[#5FC650] text-[white] bg-[#5FC650] pl-[10px] pr-[30px] py-[9px] rounded cursor-pointer font-semibold leading-none text-base "
                        onClick={handleSubmit}
                      >
                        Sent
                      </button>
                      <img className='absolute right-[5px] bottom-[9px] w-[16px]' src={tickCirlce} alt='tickcircle' />
                    </div>
                    }
                    <div className="w-4" />
                    {email && !showSent && <button
                      type="button"
                      onClick={cancelButton}
                      className="rounded leading-none border border-[#EAEAEA] bg-white px-[10px] py-[9px] text-sm  font-medium text-[#9B9B9B]"
                    >
                      Cancel
                    </button>
                    }
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EmailModal;