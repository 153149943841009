import { observer } from "mobx-react-lite";
import { useState } from "react";
import { deleteLink, editLink, openLink, getInfo } from "../http/userApi";
import {
  check, deleteIcon, lockIconRed, editIcon, linkIcon, titleEmail, titleDelete,
  titleLink
} from "../images";
import ProtectedLink from "./ProtectedLink";

const LinkItemProtected = observer(
  ({ userId, link, onDelete, onEdit, index, onSwap }) => {
    const urlPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const [editList, setEditLink] = useState(false);
    const [title, setTitle] = useState(link?.meta_data?.title || '');
    const [newLink, setNewLink] = useState(link?.meta_data?.link || '');
    const [description, setNewDescription] = useState(link?.meta_data?.description || '');
    const [password, setPassword] = useState(link?.meta_data?.password || '');
    const [openLinkForm, setOpenLinkForm] = useState(false);

    ////// Title box 

    const [isEmail, setIsEmail] = useState(emailPattern.test(link?.meta_data?.link || ''));
    const [isUrlValid, setIsUrlValid] = useState(isEmail ? emailPattern.test(link?.meta_data?.link || '') : urlPattern.test(link?.meta_data?.link || ''))

    /// Remove Link   
    const removeLink = async () => {
      // await deleteLink(link.id);
      onDelete(link.id);
      setEditLink(false);
    };

    /// Update Link
    const modifyLink = async (e) => {
      e.preventDefault();
      let verifyLink = newLink || link?.meta_data?.link;

      if (verifyLink.includes("@")) {
        verifyLink = verifyLink.includes("mailto:") ? verifyLink : `mailto:${verifyLink}`;
      } else {
        verifyLink = verifyLink.includes("http://") || verifyLink.includes("https://") ? verifyLink : `http://${verifyLink}`;
      }

      // const data = await editLink(
      //   link.id,
      //   title,
      //   verifyLink,
      //   description,
      //   password
      // );
      setEditLink(false);
      onEdit(link.id, title, verifyLink, description, password);
    };

    /// Cancel Protected Settings 
    const cancelCreateLink2 = () => {
      setOpenLinkForm(false);
    };

    /// Open Protected Link
    const readLink = async (e) => {
      e.preventDefault();
      await openLink(link.id, password);
      return false;
    }

    /////////////////   Title box functions    ///////////////
    ///  Link Regex
    const isValidLink = (link) => {
      const urlPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return isEmail ? emailPattern.test(link) : urlPattern.test(link);
    };

    const handleButtonClick = (type) => {
      setIsEmail(type === 'email');
      setNewLink('');
      setTitle('');
      setNewDescription('');
      setIsUrlValid(false);
    };

    const handleLinkInput = (value) => {
      setNewLink(value);

      const urlPattern =
        /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailPattern.test(value) || value.includes("mailto:")) {
        setIsEmail(true);
        setIsUrlValid(emailPattern.test(value) && password.trim() !== "");
      } else if (value.includes("http://") || value.includes("https://")) {
        setIsEmail(false);
        setIsUrlValid(urlPattern.test(value) && password.trim() !== "");
      } else {
        setIsUrlValid(isValidLink(value) && password.trim() !== "");
      }

    };


    const handleLinkChange = (e) => {
      const value = e.target.value;
      if (value.includes("@") || value.includes("mailto:")) {
        setIsEmail(true);
      }
      if (value.includes("http://") || value.includes("https://")) {
        setIsEmail(false);
      }
      setNewLink(value);

      setIsUrlValid(isValidLink(value, isEmail) && title.trim() !== "");
    };

    const handleTitleChange = (value) => {
      setTitle(value);
      // setIsUrlValid(isValidLink(link, isEmail) && value.trim() !== "");
    };
    const handlePasswordChange = (e) => {
      const value = e.target.value.trim();
      setPassword(value);
      setIsUrlValid(isValidLink(newLink) && value.trim() !== "");
    };



    const cancelChanges = () => {
      setTitle(link?.meta_data?.title);
      setNewDescription(link?.meta_data?.description);
      setNewLink(link?.meta_data?.link);
      setPassword(link?.meta_data?.password || '');
      setEditLink(false);
      setIsEmail(emailPattern.test(link?.meta_data?.link || ''));
    }

    return (
      <>
        {userId === link?.meta_data?.userId && (
          <>
            {!editList && link?.meta_name == "protectedLink" && (

              <div
                onClick={() => {
                  setEditLink(true);
                  setNewDescription(link?.meta_data?.description ?? "");
                }}
                className={`h-[62px] w-full p-[10px] rounded border hover:border-[#F7E7F4] bg-[#F5F5F5] dark:bg-[#302F2D] dark:border-[#F7E7F4]
                ${link?.meta_name != "protectedLink" ? "border-[#EAC5C3]" : "border-[#EAEAEA]"}
                `}>
                <div className="flex justify-between">
                  <a
                    href={link?.meta_data?.link}
                    className="  text-sm  font-regular text-[#252423] self-center common-button-text dark:text-[#FFFFFF] overflow-hidden text-ellipsis customProtectedLink__text"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {link?.meta_data?.title}
                  </a>
                  {/* <img
                    height={15}
                    width={15}
                    src={editIcon}
                    className="cursor-pointer object-contain"
                    alt="editIcon"
                  /> */}
                  <button
                    className="ml-auto mr-0 px-[10px] py-[6px] flex justify-center 
                      items-center rounded bg-[#EAEAEA] font-semibold text-[10px] text-[#E1655C] border border-[#E8E7E366] leading-[12px] h-[27px]"
                  >
                    Edit Protected Link
                    <img
                      src={editIcon}
                      className="cursor-pointer ml-[10px] h-[15px]"
                      alt="editIcon"
                    />
                  </button>
                </div>
                <span className="text-xs text-[#645F5B] dark:text-white overflow-hidden text-ellipsis customProtectedLink__text block">
                  {link?.meta_data?.description}
                </span>
              </div>


            )}

            {editList && (
              <form
                className={
                  `w-full p-2.5   rounded flex flex-col justify-between 
                  border  ${link?.meta_name != "protectedLink" ? "border-[#EAC5C3]" : "border-[#EAC5C3]"}`
                }
                onSubmit={modifyLink}
              >
                <div className="flex justify-between">
                  <input
                    type="text"
                    className="bg-transparent text-[#645F5B] font-semibold outline-0 text-[14px] w-full dark:text-[#FFFFFF]"
                    defaultValue={link?.meta_data?.title}
                    // onChange={(e) => setTitle(e.target.value)}
                    onChange={(e) => handleTitleChange(e.target.value)}
                    placeholder="Title (optional)"
                    value={title}
                  />
                </div>

                <input
                  defaultValue={link?.meta_data?.description || ""}
                  placeholder="Description (optional)"
                  className="outline-0 mt-[5px] mb-[20px] bg-transparent text-base text-[#645F5B] dark:text-white"
                  maxLength={50}
                  onChange={(e) => setNewDescription(e.target.value)}
                  value={description}
                />

                <input
                  type="text"
                  className="bg-[#D6D6D633] px-2  mb-[10px] text-[#645F5B] text-base outline-0 text-[14px] dark:text-[#ffffff] h-[35px] rounded border focus:border-[#0A85D1] active:border-[#0A85D1] dark:border-[#575350]"
                  defaultValue={link?.meta_data?.link}
                  // onChange={(e) => setNewLink(e.target.value)}
                  onChange={(e) => handleLinkInput(e.target.value)}
                  value={newLink}
                  placeholder={isEmail ? "Email address" : "Link URL"}
                />

                <span className="text-[#E1655C] text-base text-[12px]">
                  The password protecting it:
                </span>
                <div className="relative mt-2 mb-5">
                  <input
                    placeholder="Password"
                    className="bg-[#FAF4EE] px-2 placeholder-[#E1655C]  text-[#E1655C] text-base outline-0 text-[14px] dark:text-[#E1655C] h-[35px] rounded w-full dark:border-[#575350]"
                    maxLength={12}
                    value={password}
                    onChange={handlePasswordChange}
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                  />
                  <img
                    src={lockIconRed}
                    className="cursor-pointer ml-[10px] h-[15px] absolute right-[10px] top-[10px]"
                    alt="lockIconRed"
                  />
                </div>

                <div className="flex justify-start space-x-2 mt-2">
                  <button
                    type="submit"
                    className={
                      isUrlValid
                        ? "h-[25px] w-[49px] flex justify-center items-center space-x-1.5 border border-[#5FC650] text-[#5FC650] font-semibold cursor-pointer text-[10px] radius rounded-[5px]"
                        : "h-[25px] w-[49px] flex justify-center items-center space-x-1.5 border border-[#FAF4EE] text-[#645F5B] bg-[#EAEAEA] font-semibold cursor-pointer text-[10px] radius rounded-[5px]"
                    }
                    disabled={!isUrlValid}
                  >
                    <p className="">Save</p>

                  </button>
                  <span
                    className="h-[25px] w-[49px] flex justify-center items-center space-x-1.5 border border-[#FAF4EE] dark:border-[#575350] text-[#645F5B] font-semibold cursor-pointer text-[10px] radius rounded-[5px] dark:text-[#FFFFFF]"
                    onClick={cancelChanges}
                  >
                    Cancel
                  </span>
                </div>

                <div className="flex justify-between mt-[20px]">
                  {/* Left Container */}
                  <div className="flex space-x-2">
                    <button
                      className={`flex h-[25px] w-[25px] p-[5px] border  rounded justify-center items-center ${!isEmail ? 'bg-[#0A85D1]' : 'border-[#0A85D1]'}`}
                      type="button"
                      onClick={() => handleButtonClick('link')}
                    >
                      <img src={titleLink} height={15} width={15} alt="link" style={{ filter: !isEmail ? 'invert(1) brightness(10)' : 'none' }} />
                    </button>
                    <button
                      className={`flex h-[25px] w-[25px] p-[5px] border  rounded justify-center items-center ${isEmail ? 'bg-[#0A85D1]' : 'border-[#0A85D1]'}`}
                      type="button"
                      onClick={() => handleButtonClick('email')}

                    >
                      <img src={titleEmail} height={15} width={15} alt="link" style={{ filter: isEmail ? 'invert(1) brightness(10)' : 'none' }} />
                    </button>

                  </div>
                  {/* Right Container */}
                  <div className="flex space-x-2">
                    <button  type="button"
                      className="flex h-[25px] w-[25px] border border-[#F6DAD0] rounded justify-center items-center"
                      onClick={() => removeLink()}
                    >
                      <img src={titleDelete} height={15} width={15} alt="delete" />
                    </button>

                  </div>
                </div>
              </form>
            )}
          </>
        )}

        {userId !== link?.meta_data?.userId && (
          <>
            {link?.meta_name == "protectedLink" && (
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={link?.meta_data?.link}
                className="h-[62px] group w-full p-2 bg-[#FAF4EE66] dark:bg-[#302F2D] rounded  border border-[#EAEAEA] hover:border-[#589ED5]"
              >
                <div className="flex justify-between">
                  <span
                    className="w-full text-[14px] text-[#645F5B] font-semibold dark:text-[#FFFFFF]"
                    target="_blank"
                  >
                    {link?.meta_data?.title}
                  </span>
                  <img
                    className="opacity-0 object-contain group-hover:opacity-100"
                    height={15}
                    width={15}
                    src={linkIcon}
                    alt=""
                  />
                </div>
                <span className=" text-base text-[#645F5B] dark:text-white">
                  {link?.meta_data?.description}
                </span>
              </a>
            )}
          </>
        )}
      </>
    );
  }
);

export default LinkItemProtected;
