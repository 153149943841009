import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import ReactModal from "react-modal";
import { useLocation, useNavigate } from "react-router-dom";
import {
  blank_page_svg,
  layerlogo,
  searchIcon,
  defaultImage,
  mapIcon,
  guideIcon,
} from "../images";
import {
  HOME_ROUTE,
  MAP_ROUTE,
  SEARCH_USER,
  USER_ACCOUNT_ROUTE,
  USER_SETTINGS_ROUTE,
  USER_COMMS_ROUTE
} from "../utils/consts";
import { getInfo, listBookmarks, signOutSession } from "../http/userApi";
import Modal from "react-modal";
import { ThemeContext, themes } from "../store/ThemeContext";
import { Context } from "../index";
import AccountsMenu from "./AccountsMenu";
import { googleLogout } from "@react-oauth/google";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "transparent",
    height: "auto",
    border: "0",
  },
};

ReactModal.setAppElement("#root");

const Header = forwardRef((props, ref) => {
  const { user } = useContext(Context);
  const [isAuthPremium, setIsAuthPremium] = useState(user.user.is_premium);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [showModalMenu, setShowModalMenu] = useState(false);
  const [showModalAccounts, setShowModalAccounts] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme-color") === "white-content" ? false : true
  );
  const [userInfo, setUserInfo] = useState(null);
  const callBack = () => {
    setShowModalAccounts(true);
  };
  useImperativeHandle(ref, () => ({
    callBack: callBack,
  }));

  const searchUser = (e) => {
    e.preventDefault();
    navigate(SEARCH_USER + searchValue.toLowerCase());
    navigate(0);
  };

  const logOut = async () => {
    try {
      await signOutSession();
      user.setUser({});
      localStorage.removeItem("token");
      localStorage.removeItem("isPrivate");
      localStorage.removeItem("emailNotification");
      localStorage.removeItem("session_id");
      localStorage.removeItem("MessagingPermission");
      googleLogout();
      window.location.href = HOME_ROUTE;
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const [bookmarks, setBookmarks] = useState("");
  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }
  function closeModal() {
    setIsOpen(false);
  }
  const loadBookmarks = async (e) => {
    const data = await listBookmarks()
      .then((data) => {
        setBookmarks(data);
        setIsOpen(true);
      })
      .finally();
  };

  const searchBookmark = (data) => {
    if (data && data.length > 0) {
      const filtered = bookmarks.data.filter((entry) =>
        Object.values(entry).some(
          (val) => typeof val === "string" && val.includes(data)
        )
      );
      setBookmarks({ data: filtered });
    } else {
      loadBookmarks();
    }
  };

  useEffect(() => {
    const body = document.querySelector("body");
    body.style.overflow =
      showModalMenu || showModalAccounts ? "hidden" : "auto";
  }, [showModalMenu, showModalAccounts]);
  
    useEffect(() => {
    getInfo().then((data) => {
      setUserInfo(data.username);
      setIsAuthPremium(data.is_premium);
    });
  }, []);
    useEffect(()=>{setIsAuthPremium(user.user.is_premium)},[user.user.is_premium]);
  return (
    <>
      <div className="flex justify-between lg:border-b border-EA dark:border-[#575350] customMainNav">
        <div className="flex justify-between items-center border-b border-EA lg:border-none w-full bg-white dark:bg-[#1A1919] customMainNav__inner">
          <div className="p-[12px]">
            <img
              src={layerlogo}
              className=" h-[30px] cursor-pointer notBrighterFillter"
              alt="Layer.Page logo"
              onClick={() => navigate(HOME_ROUTE)}
            />
          </div>
          <div className="hidden lg:flex gap-[10px] items-center p-[12px]">
            <span
              className={`py-[7px] px-[14px] w-auto border border-EA dark:border-[#575350] hover:bg-[#eaeaea4d] dark:focus:bg-[#eaeaea99] dark:active:bg-[#eaeaea99] focus:bg-[#eaeaea99] active:bg-[#eaeaea99] duration-300 ease-in-out rounded-full cursor-pointer text-[#645F5B] dark:text-[#FFFFFF] dark:hover:bg-[#eaeaea4d] dark:bg-[#eaeaea26] font-semibold text-sm common-button ${
                pathname == MAP_ROUTE ? "bg-[#E8E7E366]" : ""
              }`}
              onClick={() => navigate(MAP_ROUTE)}
            >
              Map
            </span>
          <a href="https://layer.guide" target="_blank" rel="noreferrer">
              <span className="py-[7px] px-[14px] w-auto border border-EA dark:border-[#575350] hover:bg-[#eaeaea4d] dark:focus:bg-[#eaeaea99] dark:active:bg-[#eaeaea99] focus:bg-[#eaeaea99] active:bg-[#eaeaea99]  dark:hover:bg-[#eaeaea4d] dark:bg-[#eaeaea26] duration-300 ease-in-out rounded-full cursor-pointer text-[#645F5B] dark:text-[#FFFFFF] font-semibold text-sm common-button">
                Guide
              </span>
            </a>
          </div>
        </div>
        <div className="flex lg:bg-side lg:px-[10px] md:px-[10px] pr-5 dark:lg:bg-side dark:bg-[#1A1919] border-b lg:border-none lg:border-l border-EA lg:w-[160px] md:w-[160px] lg:hidden">
          <div
            className="text-[14px] leading-[16.94px] py-[9px] px-[30px]  hover:bg-[#EAEAEA] duration-300 ease-in-out rounded-full cursor-pointer text-[#645F5B] font-semibold  text-center   self-center hover:bg-[#eaeaea4d] dark:hover:bg-[#eaeaea4d] focus:bg-[#eaeaea99] dark:focus:bg-[#eaeaea99] active:bg-[#eaeaea99] hidden md:block lg:block w-40 dark:text-[#ffffff]"
            onClick={() => setShowModalMenu(true)}
          >
            Menu
          </div>
          <div
            className="text-[14px]  leading-[16.94px] flex items-center cursor-pointer text-[#645F5B] font-semibold  hover:text-[#589ED5] dark:text-white lg:hidden md:hidden dark:hover:text-[#589ED5]"
            onClick={() => setShowModalMenu(true)}
          >
            Menu
          </div>
        </div>
      </div>

      <ReactModal
        isOpen={showModalMenu}
        onRequestClose={() => setShowModalMenu(false)}
        closeTimeoutMS={300}
        overlayClassName="fixed flex-col top-0 bottom-0 left-0 right-0 bg-white dark:bg-[#1A1919]"
        className="fixed bottom-0 w-full h-full bg-[#FAF4EE] outline-none pb-[20px] customHeaderNav__modal dark:bg-[#252423]"
      >
        <div className="flex flex-col h-full justify-between">
          <div className="flex flex-col overflow-y-scroll no-scrollbar">
            <div className="flex justify-between border-b border-EA">
              <div className="flex justify-between w-full bg-white dark:bg-[#252423]">
                <img
                  src={layerlogo}
                  className="p-[13px] h-14 cursor-pointer notBrighterFillter"
                  alt="Layer.Page logo"
                  onClick={() => {
                    navigate(HOME_ROUTE);
                    setShowModalMenu(false);
                  }}
                />
              </div>
              <div className="flex border-EA w-40 bg-white dark:bg-[#252423]">
                <div className="flex justify-end mr-5  lg:justify-center space-x-1 items-center w-40">
                  <div
                    className="text-[14px] leading-[16.94px] py-[9px] px-[30px]  hover:bg-[#EAEAEA] duration-300 ease-in-out rounded-full cursor-pointer text-[#645F5B] font-semibold  text-center w-full  self-center hover:bg-[#eaeaea4d] dark:hover:bg-[#eaeaea4d] focus:bg-[#eaeaea99] dark:focus:bg-[#eaeaea99] active:bg-[#eaeaea99] dark:active:bg-[#eaeaea99] dark:text-white hidden md:block lg:block"
                    onClick={() => setShowModalMenu(false)}
                  >
                    Close
                  </div>

                  <div
                    className="text-[14px]  leading-[16.94px] cursor-pointer text-[#645F5B] font-semibold  hover:text-[#589ED5] dark:text-white lg:hidden md:hidden dark:hover:text-[#589ED5]"
                    onClick={() => setShowModalMenu(false)}
                  >
                    Close
                  </div>
                </div>
              </div>
            </div>
            {!user.isAuth && (
              <div className="flex flex-col space-y-5 mt-5 mobile-action-button md:w-[400px] mx-auto w-full">
                <span
                  className="py-[9px] px-7 border border-EA hover:bg-[#EAEAEA] duration-300 ease-in-out rounded cursor-pointer text-[#645F5B] font-semibold text-sm text-center w-full  self-center hover:bg-[#eaeaea4d] dark:hover:bg-[#eaeaea4d] focus:bg-[#eaeaea99] dark:focus:bg-[#eaeaea99] active:bg-[#eaeaea99] dark:active:bg-[#eaeaea99] leading-none dark:text-[#ffffff]"
                  onClick={() => {
                    setShowModalAccounts(true);
                    setTimeout(() => {
                      setShowModalMenu(false);
                    }, 300);
                  }}
                >
                  Enter
                </span>
                
              </div>
            )}

            {user.isAuth && (
              <div className="flex flex-col space-y-[8px] mt-5 md:w-[500px] w-full self-center  mobile-action-button max-w-[540px]">
                
                {userInfo && (
                  <button
                    className={`border border-EA rounded font-semibold  text-[#645F5B] dark:text-[#FFFFFF] text-sm hover:bg-[#EAEAEA] hover:bg-[#eaeaea4d] dark:hover:bg-[#eaeaea4d] focus:bg-[#eaeaea99] dark:focus:bg-[#eaeaea99] active:bg-[#eaeaea99]  dark:active:bg-[#eaeaea99] dark:border-[#575350] text-center py-[9px] leading-none  ${
                      pathname == USER_ACCOUNT_ROUTE ? "bg-[#EAEAEA]" : ""
                    }`}
                    onClick={() => navigate(USER_ACCOUNT_ROUTE)}
                  >
                    Layer
                  </button>
                )}
                {userInfo && (
                  <button
                    className={`border border-EA rounded py-[9px] w-full leading-none font-semibold  text-[#645F5B] dark:text-[#FFFFFF] text-sm hover:bg-[#eaeaea4d] dark:hover:bg-[#eaeaea4d] focus:bg-[#eaeaea99] dark:focus:bg-[#eaeaea99] active:bg-[#eaeaea99] dark:active:bg-[#eaeaea99] dark:border-[#575350] text-center  ${
                      pathname == USER_SETTINGS_ROUTE ? "bg-[#EAEAEA]" : ""
                    }`}
                    onClick={() => navigate(USER_SETTINGS_ROUTE)}
                  >
                    Control
                  </button>
                )}
                {userInfo && (
                  <button
                    className={`border border-EA rounded py-[9px] w-full leading-none font-semibold  text-[#645F5B] dark:text-[#FFFFFF] text-sm hover:bg-[#eaeaea4d] dark:hover:bg-[#eaeaea4d] focus:bg-[#eaeaea99] dark:focus:bg-[#eaeaea99] active:bg-[#eaeaea99] dark:active:bg-[#eaeaea99] dark:border-[#575350] text-center  ${
                      pathname == USER_COMMS_ROUTE ? "bg-[#EAEAEA]" : ""
                    }`}
                    onClick={() => navigate(USER_COMMS_ROUTE)}
                  >
                    Comms
                  </button>
                )}
                {user.isAuth && isAuthPremium && (
                  
                  <div
                    onClick={() => loadBookmarks()}
                    className="flex justify-center items-center mt-20 lg:mt-0 border border-[#CCEDF8] px-2.5 py-[9px] rounded bg-[#eaeaea00] hover:bg-[#E5F6FC] active:bg-[#CCEDF8] active:text-[#00A3DD] focus:bg-[#CCEDF8] focus:text-[#00A3DD] text-[#00A3DD] text-[14px] w-[100%] font-[600] cursor-pointer"
                  >
                    <span className=" ">Bookmarks</span>
                  </div>
                )}
                {userInfo && (
                  <div className="bg-[#F5F5F5] dark:bg-[#302F2D] rounded p-2.5 ">
                    <div className="flex justify-between">
                      <span>
                        <img src={blank_page_svg} alt="Blank page icon" />
                      </span>
                      <div className="h-[30px] w-[30px] border-[1px] border-[#E0DFDE] flex items-center justify-center rounded-[5px]">
                       
                        <img
                          className={
                            user.avatar
                              ? "max-w-full h-full object-cover "
                              : "max-w-full h-auto object-contain rounded-none"
                          }
                          src={user?.avatar || defaultImage}
                          alt=""
                        />
                      </div>
                    </div>

                    <span className="text-base text-[#645F5B] dark:text-[#BCBCBC]">
                      @{userInfo}
                    </span>
                  </div>
                )}
                
                <div>
                  <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                  >
                    <>
                      <div
                        className=" w-[500px] max-w-full bg-[#fff] h-[auto] text-[#645F5B] dark:bg-[#252423] dark:text-[#fff] p-[20px]
                     border-[#EAEAEA] border-[1px] radius-[10px] rounded-[10px] cursor-pointer"
                      >
                        <div className="mb-[20px] leading-none  text-lg md:text-xl font-semibold text-[#645F5B]  dark:text-[#EAEAEA]">
                          Bookmarks
                        </div>
                        <div className="font-normal text-base  mb-[10px] leading-none text-[#645F5B] dark:text-[#fff] ">
                          Search and access your bookmarks.
                        </div>

                        {bookmarks && (
                          <>
                            <div className="overflow-y-auto h-[228px] mt-[40px] bookmarkModal__scrollList">
                              {bookmarks.data.map((item, i) => (
                                <>
                                  <div
                                    className={`relative outline-0 text-base text-[#645F5B] dark:text-white w-full px-[2px] py-[12px] cursor-pointer placeholder-[#fff] border-[#EAEAEA] hover:bg-[#D6D6D633] h-[44px] dark:hover:bg-#D6D6D6 ${
                                      i == 0
                                        ? " border-t-[1px]"
                                        : "border-b-[1px]"
                                    } bookmarkModal__item`}
                                  >
                                    <a
                                      className="flex justify-between"
                                      href={HOME_ROUTE.concat(item.username)}
                                    >
                                      @{item.username}
                                      <img
                                        src={
                                          item.avatar
                                            ? process.env.REACT_APP_API_URL +
                                              item.avatar
                                            : defaultImage
                                        }
                                        alt=""
                                        className="cursor-pointer text-[#fff] ml-[10px] w-[25px] h-[25px] absolute right-[10px] top-[8px] object-contain"
                                      />
                                    </a>
                                  </div>
                                </>
                              ))}
                            </div>
                            <div className="relative border-solid border-t-[1px] border-[#EAEAEA] mt-[20px] mx-[-20px] pt-[20px] px-[20px]">
                              <input
                                type="text"
                                className="bg-[#D6D6D633] focus:border-[#0A85D1] px-2 text-[#645F5B] dark:text-[#fff] text-base outline-0  h-[35px] rounded w-full p-[10px] cursor-pointer placeholder-[#645F5B] dark:placeholder-[#fff] border-solid border-[1px] border-[#EAEAEA]"
                                placeholder="Search"
                                onKeyUp={(e) => searchBookmark(e.target.value)}
                                onKeyDown={(e) =>
                                  searchBookmark(e.target.value)
                                }
                              />
                              <svg
                                className="cursor-pointer dark:text-[#fff] text-[#645F5B] mt-[10px] w-[15px] h-[15px] absolute right-[30px] top-[50%] translate-y-[-50%]"
                                aria-hidden="true"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                ></path>
                              </svg>
                            </div>
                          </>
                        )}
                        {!bookmarks && (
                          <>
                            <div className="relative px-2 text-[#645F5B] text-base outline-0 text-[14px] dark:text-[#fff] h-[35px] rounded w-full p-[10px] cursor-pointer placeholder-[#fff]  border-[#DAD7D4] hover:bg-[#D6D6D6] mt-[10px] border-y-[1px]">
                              No Bookmarks Yet!
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  </Modal>
                </div>
                

                <button
                  className="border border-[#F6DAD0] rounded py-[9px] text-[#D14414] leading-none font-semibold  text-sm hover:bg-[#FAECE7] dark:bg-[#eaeaea00] dark:hover:bg-[#FAECE7] focus:bg-[#F6DAD0] dark:focus:bg-[#F6DAD0] active:bg-[#F6DAD0] dark:active:bg-[#F6DAD0]"
                  onClick={logOut}
                >
                  Sign out
                </button>
              </div>
            )}
          </div>

          <div className="flex justify-between px-[20px] mt-[15px] gap-x-[20px]">
            {user.isAuth && (
              <button
                onClick={() => {
                  setShowModalMenu(false);
                  navigate(MAP_ROUTE);
                }}
                style={{ height: 35, width: 35 }}
                className="flex items-center justify-center p-2.5 bg-[#eaeaeb66] hover:bg-[#eaeaebe6] rounded-lg min-w-[35px] dark:bg-[#45423F]"
              >
                <img
                  src={mapIcon}
                  alt="map icon"
                  className="box-theme-icon"
                />
              </button>

            )}

            {!user.isAuth && (
              <div>
                <button
                onClick={() => {
                  setShowModalMenu(false);
                  navigate(MAP_ROUTE);
                }}
                style={{ height: 35, width: 35 }}
                className="flex items-center justify-center p-2.5 bg-[#eaeaeb66] hover:bg-[#eaeaebe6] rounded-lg min-w-[35px] dark:bg-[#45423F]"
              >
                <img
                  src={mapIcon}
                  alt="map icon"
                  className="box-theme-icon"
                />
              </button>
              
            </div>
            )}
            <form
              className="relative block  w-full lg:w-[500px] max-w-[500px]"
              onSubmit={searchUser}
            >
              <input
                className="placeholder:text-[#B30A8E] bg-transparent block w-full border border-[#B30A8E] rounded-md py-2 pr-9 pl-3 outline-none hover:border-[#B30A8E] focus:border-[#B30A8E] text-xs"
                placeholder="Search"
                type="text"
                name="search"
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <button
                type="submit"
                className="absolute inset-y-0 right-0 flex items-center pr-1.5"
              >
                <img
                  className={searchValue?.length ? "bg-[#67743D] rounded" : ""}
                  height={10}
                  width={10}
                  src={searchIcon}
                  alt="search logo"
                />
              </button>
            </form>
            {user.isAuth && (
              <button
                onClick={() => {
                  setShowModalMenu(false);
                  window.open('https://layer.guide', '_blank');
                }}
                style={{ height: 35, width: 35 }}
                className="flex items-center justify-center p-2.5 bg-[#eaeaeb66] hover:bg-[#eaeaebe6] rounded-lg min-w-[35px] dark:bg-[#45423F]"
              >
                <img
                  src={guideIcon}
                  alt="map icon"
                  className="box-theme-icon"
                />
              </button>

            )}
            {!user.isAuth && (
              <button
                onClick={() => {
                  setShowModalMenu(false);
                  window.open('https://layer.guide', '_blank');
                }}
                style={{ height: 35, width: 35 }}
                className="flex items-center justify-center p-2.5 bg-[#eaeaeb66] hover:bg-[#eaeaebe6] rounded-lg min-w-[35px] dark:bg-[#45423F]"
              >
                <img
                  src={guideIcon}
                  alt="map icon"
                  className="box-theme-icon"
                />
              </button>

            )}
          </div>
        </div>
      </ReactModal>

      <AccountsMenu
        isOpen={showModalAccounts}
        onRequestClose={() => setShowModalAccounts(false)}
        onMenu={() => {
          setShowModalMenu(true);
          setTimeout(() => {
            setShowModalAccounts(false);
          }, 300);
        }}
      />
    </>
  );
});

export default Header;
