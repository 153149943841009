import React, { useContext, useEffect, useState } from "react";
import Dropdown from "react-dropdown";
import { useMedia } from "../../../utils/use-media";
import { isMobile } from "../../../utils";
import { ThemeContext, themes } from "../../../store/ThemeContext";
import "react-dropdown/style.css";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Context } from "../../..";
import {
  deleteUser,
  getThemeSetting,
  themeSetting,
} from "../../../http/userApi";
import { HOME_ROUTE } from "../../../utils/consts";

const SystemSettings = ({ section }) => {
  const { user } = useContext(Context);
  const { theme, changeTheme } = useContext(ThemeContext);
  const [selectedTheme, setSelectedTheme] = useState(0);
  const [confirmInput, setConfirmInput] = useState("");

  // const dropdownOptions = ["Light", "Dark"];
  const dropdownOptions = [
    { label: "Light", value: "Light", className: "dropdown-options" },
    { label: "Dark", value: "Dark", className: "dropdown-options" },
  ];

  let mobile = useMedia(isMobile);

  // useEffect(() => {
  //   setSelectedTheme(theme === "white-content" ? 1 : 0);
  // }, [theme]);

  useEffect(() => {
    getThemeInfo();
  }, []);

  const getThemeInfo = async () => {
    try {
      const response = await getThemeSetting(user?.user?.id);
      if (response?.theme) {
        setSelectedTheme(response?.theme !== "light" ? 1 : 0);
      }
    } catch (error) {}
  };

  const onSelect = async (value) => {
    const status = dropdownOptions.findIndex((e) => e.value === value);
    if (selectedTheme !== status) {
      setSelectedTheme(status);

      changeTheme(status ? themes.light : themes.dark);

      await themeSetting({
        user_id: user.user.id,
        tmode: status ? "dark" : "light",
      });
    }
  };

  const onDelete = () => {
    if (confirmInput.toLowerCase() !== "delete") {
      alert(`Please confirm by typing "Delete"`);
    } else {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div
            className=' fixed  max-w-full mx-auto p-[10px] w-[540px] message-popup  self-center bg-[#FFFFFF] dark:bg-[#252423] border-[#DAD7D4] m-auto inset-x-0 inset-y-0 border-[1px] rounded-[10px] custom-items-center '>
            <h2
              className=" block mb-[20px] justify-left text-lg md:text-xl font-semibold text-[#645F5B]  dark:text-[#EAEAEA]"
            >Delete account</h2>
            <p className="block justify-left mb-[40px] text-base text-[#645F5B] dark:text-white">Confirm deletion.</p> 
              <div className='flex justify-between'>
            <button
              className="message-btn btn-delete text-base  p-[5px] text-[#e1655c] border-[1px] border-[#eac5c3] rounded"
              onClick={() => {
                onConfirmDelete();
              }}
            >
              Delete
            </button>
            <button
              className="message-btn btn-cancel text-base   p-[5px] text-[#645f5b] border-[1px] border-[#eaeaea] dark:border-[#ffffff] dark:text-[#ffffff] rounded"
              onClick={onClose}>Cancel</button>
              </div>
          </div>
        );
      }
    });
      }
  };

  const onConfirmDelete = async () => {
    try {
      await deleteUser(user.user.id);
      user.setUser({});
      localStorage.removeItem("token");
      window.location.href = HOME_ROUTE;
    } catch (error) {}
  };

  return (
    <div
      ref={section.ref}
      className="border-t border-EA w-full mt-10  space-y-4"
    >
      <h1 className="mt-10 leading-[24px] text-left md:text-2xl text-xl font-bold text-[#645F5B] dark:text-[#EAEAEA] ">
        System
      </h1>
      <div className="flex flex-col space-y-5">
        <div className="flex flex-col">
          <h2 className=" mt-[30px] text-left md:text-xl text-lg font-semibold text-[#645F5B] dark:text-white dark:text-[#EAEAEA]">
          System design</h2>
          <p className="leading-[19px] text-left mt-8 text-base text-[#645F5B] dark:text-white">
            Your Layer’s default: light, dark, or system mode</p>
          <div className="flex flex-col lg:flex-row justify-between mt-5">
            <Dropdown
              className=" w-full lg:w-[450px]"
              menuClassName="rounded p-2 text-sm font-semibold mt-[5px]"
              controlClassName="flex justify-between px-[10px] py-[9px] bg-[#EAEAEA] border-none rounded-md hover:bg-[#E2E2E2] active:bg-[#CCC] leading-none"
              placeholderClassName="font-normal text-[#252423]"
              arrowClassName="dropdownArrow down"
              arrowClosed={<span className="dropdownArrow down" />}
              arrowOpen={<span classNamwe="dropdownArrow down" />}
              options={dropdownOptions}
              onChange={(e) => onSelect(e.value)}
              value={dropdownOptions[selectedTheme]?.value}
            />
          </div>
          <div className="flex flex-col lg:flex-row justify-between mt-5">
            <div className="flex flex-col mt-5  w-full">
              {/* Delete Info */}
              <div>
                <h2 className="leading-[24px] text-left md:text-xl text-lg font-semibold text-[#645F5B] dark:text-white dark:text-[#EAEAEA]">
                  Delete your Layer
                </h2>

                <p className="leading-[19px] text-left mt-8 text-base text-[#645F5B] dark:text-white">
                 Type Delete below and confirm deletion.
                </p>
              </div>
              <div
                style={{ marginLeft: 0 }}
                className="flex flex-col lg:flex-row justify-between  mt-[20px]"
              >
                <input 
                  type="text"
                  placeholder=""
                  className="px-[10px] py-[9px] w-full lg:w-[450px] outline-0 text-[#A09992] dark:text-[#FFFFFF] bg-[#D6D6D633] rounded-md border border-EA h-[35px]"
                  onChange={(e) => setConfirmInput(e.target.value)}
                /> 
              </div>
              <div className="mt-5">
              <button
                  onClick={onDelete}
                  // style={mobile ? { marginTop: 20, marginBottom: 10 } : {}}
                  className="border border-[#E1655C] text-[#E1655C] hover:bg-[#EAC5C326] rounded font-semibold text-[14px] focus:bg-[#EAC5C3]  active:bg-[#EAC5C3] leading-none px-[10px] py-[9px]"
                >
                  Delete Layer
                </button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SystemSettings;
