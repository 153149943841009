import Header from "../components/Header";
import RightMenu from "../components/RightMenu";
import {TitleBox} from "../components/PlanDescription/BoxData"

const ERROR_404 = () => {
  return (
    <main className="flex flex-col h-screen justify-between bg-white dark:bg-[#1A1919]">
      <Header />

      <div className="flex justify-between border-b border-EA h-full dark:bg-[#1A1919]">
        <div className="w-full">
          <div className="flex flex-col justify-between container w-full lg:w-10/12   px-5 mx-auto h-full">
            <div className="hidden lg:flex mt-12 space-x-2 items-center mb-8">
              <span className="text-[#645F5B] dark:text-[#FFFFFF]">404</span>
            </div>

            <div className="flex flex-1 lg:border-t-[1px] border-[#EAEAEA] pt-3 flex-col">
              <span className="text-xl text-[#E1655C] font-medium dark:text-[#fff]">
                404: this content does not exist.
              </span>

              <div className="h-[1px] w-full bg-[#EAEAEA] mt-[25px]" />
              {/* Bottom Buttons */}
              <div className="mt-10 flex-col">
                <TitleBox
                  title={"View all of the features in action:"}
                  buttonText={"Showcase"}
                  href="https://docs.layer.page/features"
                />
                <TitleBox
                  title={"Contact sales:"}
                  buttonText={"Sales"}
                  href="https://docs.layer.page/help"
                />
                <TitleBox
                  title={"View the guide:"}
                  buttonText={"Guide"}
                  href="https://docs.layer.page"
                />
              </div>
            </div>
          </div>
        </div>
        <RightMenu />
      </div>
    </main>
  );
};

export default ERROR_404;

