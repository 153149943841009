import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Modal from 'react-modal';
import RightMenu from "../components/RightMenu";
import Header from "../components/Header";
import VerifyModal from '../components/VerifyModal';
import { Themefooter } from "../components/ThemeFooter"
import Loader from "../components/Loader";
import { Box, FreeBox } from "../components/PlanDescription/BoxData"
import { Context } from "..";
import { check, disbaleCheck } from "../images";
import { USERNAME_EXIST, freeUserTypeArr, paidUserTypeArr, PREMIUM_USERNAME } from "../utils"
import { USER_ACCOUNT_ROUTE, BASE_ORIGIN } from "../utils/consts";
import { greenCheck, redCross, tickCirlce, Checkdone, purple_tick } from "../images";
import {
  changeUsername,
  checkUsername,
  getInfo,
  getOtpSignin,
  verifyOtpSignin,
  postBackupEmail,
  getOtpThemePlain,
  setPremiumUser,
  createCheckoutSession
} from "../http/userApi";
import { useMedia } from "../utils/use-media";
import { isMobile } from "../utils";
import { showToast } from "../utils/BannerPopup";

// const AccountCreation = ({setLoading}) => {
const AccountCreation = () => {
  const navigate = useNavigate();
  const { user, Themefooter } = useContext(Context);
  const headerRef = useRef();
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme-color") === "white-content" ? false : true
  );
  let mobile = useMedia(isMobile);
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [showSent, setShowSent] = useState(false);
  const modalRef = useRef(null);
  const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false);
  const [newUsername, setNewUsername] = useState("");
  const [usernameState, setUsernameState] = useState(false);
  const [isAvailable, setIsAvailable] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [isChecking, setIsChecking] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState(false);
  const [buttonText, setButtonText] = useState("Confirm");
  const changeText = (text) => setButtonText(text);
  const [showVerified, setShowVerified] = useState(false);
  const [verifiedEmail, setVerifiedEmail] = useState('');
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [validationMessage, setValidationMessage] = useState(null);
  const [isFreeConfirmed, setIsFreeConfirmed] = useState(false);
  const [isPlusConfirmed, setIsPlusConfirmed] = useState(false);
  const [secondSection, setSecondSection] = useState(false);
  const [loading, setLoading] = useState(true);
  const [usernameType, setUsernameType] = useState({ isAvailable: false, isFreeUsername: false, isPaidUsername: false });

  const isAuthPremium = user.user.is_premium;


  // states and method of banner
  const [isBannerVisible, setIsBannerVisible] = useState(false);
  const showBanner = () => {
    setIsBannerVisible(true);
    setTimeout(() => {
      setIsBannerVisible(false);
    }, 3000);
  };

  //=============================================================



  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      background: 'transparent',
      height: 'auto',
      border: '0',

    },
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getInfo();
        user.setUser(data);
        setUserInfo(data);
        setNewUsername(data?.username);
        if (data?.username) setIsConfirmed(true);
        if (data?.backup_email) {
          setSecondSection(true);
          setVerifiedEmail(data?.backup_email);
          setShowVerified(true);
          setEmail(data?.backup_email || " ");
        }
      } finally {
        setTimeout(() => setLoading(false), 1000);
      }
    };
    fetchData();
  }, [user]);

  function afterOpenModal() { }

  function closeModal() {
    setIsOpen(false);
    setTitle('');
  }

  function containsUppercase(str) {
    return /[A-Z]/.test(str);
  }

  const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;


  const validTlds = [".com", ".org", ".net", ".in", ".co.uk", ".io", ".co", ".tv", "..."];

  function isValidTld(email) {
    const domain = email.split('@')[1];
    return domain.length >= 2 && validTlds.includes(domain.split('.').pop());
  }

  const validateEmail = (inputEmail) => {

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(inputEmail)) {
      setValidationMessage('Invalid email format.');
      return;
    }

    if (/^\s*|\s*$/.test(inputEmail)) {
      return { valid: false, message: "Email cannot start or end with whitespace." };
    }

    if (/[.]\./.test(inputEmail)) {
      return { valid: false, message: "Email cannot contain consecutive dots." };
    }

    if (!isValidTld(inputEmail)) {
      setValidationMessage('Email address may not be deliverable due to uncommon TLD.');
      return;
    }
    setValidationMessage('Email appears valid.');
  };

  const openVerifyModal = () => {
    setIsVerifyModalOpen(true); // Function to open the modal
  };

  const handleInputChange = (e) => {
    const inputEmail = e.target.value;
    setEmail(inputEmail);
    setIsValidEmail(validateEmail(inputEmail));
    setShowSent(false);
    setShowVerified(false);
  };

  const sendOtp = async (email) => {
    try {
      const response = await getOtpThemePlain(email);
    } catch (error) {
      console.error('Error sending OTP:', error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendOtp(email)
    setShowSent(true)
    setTimeout(openVerifyModal, 1000);
  };

  const handleNavigateToAccount = () => {
    navigate(USER_ACCOUNT_ROUTE);
  };

  const cancelButton = () => {
    setEmail(verifiedEmail);
    if (verifiedEmail) setShowVerified(true)
    setIsValidEmail(false);
  }

  const closeVerifyModal = () => {
    setIsVerifyModalOpen(false); // Function to close the modal
  };

  const resendVerify = () => {
    sendOtp(email);
  }

  const onVerify = async (otp) => {
    try {
      const response = await verifyOtpSignin(otp);
      if (response?.status === 1) {
        try {
          const res = await postBackupEmail(userInfo.id, email);
          if (res?.status === 1) {
            console.log('postBackupEmail ');
            setVerifiedEmail(email);
            setShowVerified(true);
            setTimeout(closeVerifyModal, 1000);
            setTimeout(() => setSecondSection(true), 2000);
            return 1;
          }
          showToast(res?.message);
          return 0
        } catch (err) {
          console.error('Error verifying OTP:', err);
          return 0
        }
      } else {
        showToast(response?.message);
        return 0
      }
    } catch (error) {
      console.error('Error verifying OTP:', error.response ? error.response.data : error.message);
      return 0;
    }

  }

  const changeUsernameTick = (str) => {
    // const isValidLength = isAuthPremium ? str.length >= 3 : str.length > 4;
    const isValidLength = str.length >= 3;
    const isWithinMaxLength = str.length <= 12;
    const containsNumber = /\d/.test(str);

    const noUppercase = !/[A-Z]/.test(str);
    const noSpecialChars = !/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(str);

    let isFreeUsername = false;
    let isPaidUsername = false;

    if (str.length >= 3 && str.length <= 4) { // 
      if (containsNumber) {
        isFreeUsername = isValidLength && isWithinMaxLength && noUppercase && noSpecialChars;
      } else {
        isPaidUsername = isValidLength && isWithinMaxLength && noUppercase && noSpecialChars;
      }
    } else {
      isFreeUsername = isValidLength && isWithinMaxLength && noUppercase && noSpecialChars;
    }
    const isAvailable = isFreeUsername || isPaidUsername;

    setIsAvailable(isAvailable);

    return { isAvailable, isFreeUsername, isPaidUsername };
  }


  const changeUserUsername = async (userNameChanged) => {
    console.log("change username function clickedc")
    const localUsername = localStorage.getItem('newUsername')

    const containsUppercase = (str) => /[A-Z]/.test(str);
    const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

    const validationResult = changeUsernameTick(userNameChanged);


    if (containsUppercase(userNameChanged)) {
      setIsOpen(true);
      setTitle(
        "Your username can only contain lowercase letters and/or numbers."
      );
    } else if (format.test(userNameChanged)) {
      setIsOpen(true);
      setTitle(
        "Your username can only contain lowercase letters and/or numbers."
      );
    } else if (userNameChanged.length < 3) {
      setIsOpen(true);
      setTitle(
        "Usernames with 3 or 4 letters only are premium usernames."
      );
    }
    else if (userNameChanged.length > 12) {
      setIsOpen(true);
      setTitle("Your username length must not exceed 12 characters.");

    } else if (validationResult.isAvailable || localUsername) {
      try {
        const data = await checkUsername(userNameChanged);
        if (data.status === 1) {
          if ((!isAuthPremium && userNameChanged.length >= 3 && userNameChanged.length <= 4 && !/\d/.test(userNameChanged))) {
            setIsOpen(true);
            setTitle(
              PREMIUM_USERNAME
            );
          } else {
            const res = await changeUsername(userNameChanged);
            const userData = userInfo;
            userData["username"] = userNameChanged;
            setUserInfo(userData);
            user.setUser(userInfo);
            setIsAvailable(false);
            setIsConfirmed(true);
          }
        } else {
          setIsOpen(true);
          setTitle(data.message);
          setIsAvailable(false);
        }
        setUsernameState(false);
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };



  const handleUsernameChange = (newUsername) => {
    const result = changeUsernameTick(newUsername);
    setUsernameType(result);
  };

  const { isFreeUsername, isPaidUsername } = usernameType;

  const handleFreeConfirm = async () => {
    setIsFreeConfirmed(true);
    const res = await setPremiumUser(false);
  }

  const handlePortal = async () => {
    localStorage.setItem('newUsername', newUsername)
    try {
      const session = await createCheckoutSession(BASE_ORIGIN);
      window.location.href = session.url;
    } catch (error) {
      console.log('error', error)
    }
  };

  useEffect(() => {
    if (isAuthPremium) {
      const localUsername = localStorage.getItem('newUsername')
      if (localUsername) {
        setNewUsername(localUsername);
        setUsernameState(true);
        setIsConfirmed(false);
        changeUserUsername(localUsername);
        localStorage.removeItem("newUsername");
      }
      setIsPlusConfirmed(true);
    }
  }, [isAuthPremium]);

  if (loading) {
    return <Loader isVisible={loading} />;
  }

  return (
    <>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div
          className=" max-w-full bg-[#F8F8F8] text-[#645F5B] dark:bg-[#252423] dark:text-[#fff] p-[10px]
            border-[#DAD7D4] border-[1px] radius-[5px] rounded-[10px] text-center"
        >
          <h2 className=" block mb-[20px] justify-left text-lg md:text-xl font-semibold text-[#645F5B]  dark:text-[#EAEAEA]">
            {" "}
            {title == USERNAME_EXIST
              ? "Username already exists"
              : (title == PREMIUM_USERNAME ? PREMIUM_USERNAME : "Username parameters")}{" "}
          </h2>
          <p className="block justify-left mb-[40px] text-base text-[#645F5B] dark:text-white">
            {title == USERNAME_EXIST
              ? "Please choose another username."
              : (title == PREMIUM_USERNAME ? "Usernames with 3 or 4 letters only are premium usernames."
                : `Lowercase letters or numbers and between 3 and 12 characters (usernames with 3 or 4 letters only are for Plus Layers).`)}{" "}
            {/* : (isAuthPremium ? "Usernames can only contain lowercase letters or numbers and must be between 3 and 12 characters." : "Usernames can only contain lowercase letters or numbers and must be between 4 and 12 characters."))}{" "} */}
          </p>

          <div className="flex justify-between">
            {(!isAuthPremium && title == PREMIUM_USERNAME &&
              <button
                className="text-base font-regular p-[5px] text-[#9D34DA] border-[1px] border-[#9D34DA] dark:border-[#9D34DA] dark:text-[#9D34DA] rounded"
                onClick={handlePortal}
              >
                Get Plus
              </button>
            )}
            <div className="flex-1 flex justify-end">
            <button
              className="message-btn btn-cancel text-base p-[5px] text-[#645f5b] border-[1px] border-[#eaeaea] dark:border-[#ffffff] dark:text-[#ffffff] rounded"
              onClick={() => {
                closeModal();
              }}
            >
              Back
            </button>
            </div>
          </div>
        </div>
      </Modal>


      <main className="flex flex-col justify-between bg-white dark:bg-[#1A1919]">


        {/* {authState && <Loader isVisible={authState} />} */}
        <Header ref={headerRef} />
        <div className="border-b border-EA h-full dark:bg-[#1A1919] custom-wrapper">
          <div className="w-full customContainer__left">
            <div id="scrollTotop" className="flex flex-col justify-between container max-w-5xl px-5 mx-auto  ">
              <div className="flex flex-col justify-between container">
                <div className=" my-12 space-x-2 items-center">
                  <span className=" text-base text-[#645F5B] dark:text-white">Layer</span>
                  <span className="  text-base text-[#645F5B] dark:text-white"> / </span>
                  <span className=" text-base text-[#645F5B] dark:text-white"> Origin</span>
                </div>
              </div>
              <h1 className="md:text-2xl text-xl font-bold text-[#645F5B] dark:text-[#EAEAEA]">
                Email, username and plan
              </h1>
              { }
              {!secondSection && <div className="flex flex-1 pt-10 mt-25 flex-col ">
                <div className="flex justify-between ">
                  <h2 className=" leading-[24px] text-left md:text-xl text-lg font-semibold text-[#645F5B]  dark:text-[#EAEAEA]">
                    Layer email address
                  </h2>
                  <h3 className=" mb-2 leading-[24px] md:text-xl text-lg font-semibold text-[#645F5B]  dark:text-[#EAEAEA]">1/3</h3>
                </div>
                <p className=" mt-5 text-base text-[#645F5B] dark:text-white">Your backup email address</p>

                <form>
                  <div className='flex flex-col  justify-between relative w-full lg:w-1/2'>
                    <input
                      type="email"
                      placeholder="Email address"
                      className="bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-[#0A85D1] focus:border-[#0A85D1] block py-2 px-2 dark:bg-gray-600 dark:border-gray-500
                               dark:placeholder-gray-400 dark:text-white mt-5 mb-2 font-normal text-[#645F5B] outline-none"
                      value={email}
                      onChange={handleInputChange}
                      required
                    />
                    {isValidEmail && <img className='absolute right-[5px] bottom-[16px] w-[16px] ' src={greenCheck} alt="greenCheck" />}
                  </div>
                  <div className='flex justify-between w-1/2 my-2'>
                    {(!showSent && !showVerified) && <button
                      type="submit"
                      className={isValidEmail
                        ? "border border-[#00B27A] text-[#00B27A] px-[10px] py-[9px] rounded cursor-pointer font-semibold leading-none text-base "
                        : "bg-[#EAEAEA] text-[#9B9B9B] px-[10px] py-[9px] rounded cursor-pointer font-semibold leading-none text-base "
                      }
                      disabled={!isValidEmail}
                      onClick={handleSubmit}
                    >
                      Send
                    </button>
                    }
                    {(showSent || showVerified) && <div className='relative'>
                      <button
                        type="button"
                        className="flex items-center border leading-none border-[#00B27A] text-[white] bg-[#00B27A] p-[10px] rounded cursor-pointer font-semibold text-base "
                        disabled={showVerified}
                        onClick={handleSubmit}
                      >
                        {showVerified ? 'Verified' : 'Sent'}
                        {<img className='w-[16px] ml-[10px]' src={tickCirlce} alt='tick' />}
                      </button>

                    </div>
                    }
                    <div className="w-4" />
                    {email && !showSent && !showVerified && <button
                      type="button"
                      onClick={cancelButton}
                      className="rounded leading-none border border-[#EAEAEA] bg-white px-[10px] py-[9px] text-sm  font-medium text-[#9B9B9B]"
                    >
                      Cancel
                    </button>
                    }
                  </div>
                </form>
                <VerifyModal isOpen={isVerifyModalOpen} onClose={closeVerifyModal} onResend={resendVerify} onVerify={onVerify} onBack={closeVerifyModal} />
              </div>}

              {secondSection && <div>
                <div>
                  <div className="flex justify-between ">
                    <h2 className=" mt-8 leading-[24px] text-left md:text-xl text-lg font-semibold text-[#645F5B]  dark:text-[#EAEAEA]">
                      Username
                    </h2>
                    <h2 className="  mt-8 leading-[24px] text-left md:text-xl text-lg font-semibold text-[#645F5B] dark:text-[#EAEAEA]">2/3</h2>
                  </div>
                  <div className="flex flex-col justify-between mt-3">
                    <p className="mb-3 mt-5 text-base text-[#645F5B] dark:text-white">Username and URL.</p>
                    <div className="flex flex-row w-full lg:w-1/2 h-[35px] ">
                      <span className="bg-EA py-[9px] px-[11px] h-[35px] rounded-l-[5px] text-[#645F5B] leading-none">
                        layer.page/
                      </span>
                      <div className="bg-[#D6D6D633] rounded-r-lg border border-[#EAEAEA] relative h-[35px] flex flex-row w-full">
                        <input
                          type="text"
                          className="outline-0 py-[9px] leading-none px-[10px] text-[#A09992] dark:text-[#FFFFFF] bg-transparent w-full "
                          placeholder="username"
                          onChange={(e) => {
                            setNewUsername(e.target.value);
                            setUsernameState(true);
                            handleUsernameChange(e.target.value);
                            setIsConfirmed(false);
                          }}
                          onClick={() => changeText("Save")}
                          value={newUsername}
                          defaultValue={userInfo?.username}
                        />

                        {usernameState && newUsername !== userInfo?.username ? (
                          isChecking ? null : (
                            <img
                              height={20}
                              width={20}
                              className="object-contain absolute right-[9px] top-[50%] -translate-y-1/2"
                              src={isAvailable ? (isFreeUsername ? greenCheck : purple_tick) : redCross}
                              alt={isAvailable ? (isFreeUsername ? "greenCheck" : "purple_tick") : "redCross"}
                            />
                          )
                        ) : (
                          <span className=""></span>
                        )}
                      </div>
                    </div>
                    <div className="flex flex-row justify-between w-1/2">
                      <button
                        onClick={() => {
                          changeUserUsername(newUsername);
                        }}

                        className={(isConfirmed && userInfo?.username
                          ? "flex items-center my-[20px] border border-[#00B27A] text-[white] bg-[#00B27A] p-[10px] px-[8px] rounded cursor-pointer font-semibold leading-none text-base "
                          : "flex items-center my-[20px] border border-[#00B27A] text-[#00B27A] pl-[10px] p-[10px] rounded cursor-pointer font-semibold leading-none text-base "
                        )}
                      >
                        {isConfirmed && userInfo?.username ? 'Confirmed' : 'Confirm'}
                        {isConfirmed && userInfo?.username && <img className='w-[16px] ml-[8px]' src={tickCirlce} alt='tick' />}
                      </button>

                      {usernameState && (
                        <div className="flex space-x-3 items-center">

                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="flex flex-1  border-[#EAEAEA] pt-10  mt-25 flex-col lg:border-t-[1px]"> {/*divider*/}
                  <div className="flex justify-between ">
                    <h2 className=" leading-[24px] text-left md:text-xl text-lg font-semibold text-[#645F5B]  dark:text-[#EAEAEA]">
                      Plans
                    </h2>
                    <h3 className=" mb-2 leading-[24px] text-left md:text-xl text-lg font-semibold text-[#645F5B]  dark:text-[#EAEAEA]">3/3</h3>
                  </div>
                  {/*  */}
                  <div className="flex flex-col md:flex-row w-full pt-7 ">
                    {/* Free */}
                    {!isAuthPremium &&
                      <FreeBox
                        isLoggedIn={user?.isAuth} isConfirmed={isFreeConfirmed} onConfirm={handleFreeConfirm}
                      />
                    }
                    <Box
                      isConfirmed={isPlusConfirmed} endPoint={BASE_ORIGIN}
                    />
                  </div>
                  <button
                    type="button"
                    className={isConfirmed && (isFreeConfirmed || isPlusConfirmed) ? "border border-[#645F5B] text-[#645F5B] bg-[#FFFFFF00] px-[10px] py-[9px]   rounded cursor-pointer font-semibold leading-none text-base dark:text-[#EAEAEA]"
                      : "bg-[#EAEAEA] text-[#9B9B9B] px-[10px] py-[9px] rounded cursor-pointer font-semibold leading-none text-base"
                    }
                    disabled={!isConfirmed && (!isFreeConfirmed || !isPlusConfirmed)}
                    onClick={() => {
                      handleNavigateToAccount();
                      // showBanner();
                      showToast("Layer created.")
                    }}
                  >
                    Create Layer
                  </button>

                  <div className="text-xs font-regular text-[#645F5B] dark:text-white mb-[50px] mt-[20px]">
                    By creating your Layer {'(clicking “Create Layer”)'}, you agree to our {' '}
                    <a href="https://layer.guide/legal" target="_blank" rel="noopener noreferrer" className="underline  hover:underline focus:underline" >
                      Terms and Conditions
                    </a>{' '}
                    and{' '}
                    <a href="https://layer.guide/legal" target="_blank" rel="noopener noreferrer" className="underline  hover:underline focus:underline">
                      Privacy Policy
                    </a>
                  </div>
                </div>
              </div>
              }
              <RightMenu />
            </div>
          </div>
          <Themefooter />
        </div>

      </main>
    </>
  )
}
export default AccountCreation;
