import TimeOut from "./components/TimeOut";
import Account from "./page/account";
import AccountSettings from "./page/account/Settings/index";
import AccountCreation from "./page/AccountCreation";
import Docs from "./page/Docs";
import Features from "./page/Features";

import Map from "./page/MapAbout";
import Help from "./page/Help";
import Home from "./page/Home";
import Message from "./page/Message";
import Terms from "./page/Terms";
import UserLayer from "./page/users";
import Comms from "./components/Comms"
import {
  DOCS_ROUTE,
  FEATURES_ROUTE,
  HELP_ROUTE,
  HOME_ROUTE,
  MESSAGE_ROUTE,
  SEARCH_USER,
  TERMS_ROUTE,
  USER_ACCOUNT_ROUTE,
  USER_BIO_ROUTE,
  USER_CHANGENAME_ROUTE,
  USER_SETNAME_ROUTE,
  USER_SETTINGS_ROUTE,
  USER_ACCOUNTTEST_ROUTE,
  USER_TIMEOUT_ROUTE,
  MAP_ROUTE,
  USER_COMMS_ROUTE
} from "./utils/consts";

export const authRoutes = [
  {
    path: USER_ACCOUNT_ROUTE,
    Component: <Account />,
  },
  {
    path: USER_SETTINGS_ROUTE,
    Component: <AccountSettings />,
  },
  {
    path: USER_SETNAME_ROUTE,
    Component: < AccountCreation/>,
  },
  {
    path: USER_COMMS_ROUTE,
    Component: < Comms/>,
  },
];

export const publicRoutes = [
  {
    path: HOME_ROUTE,
    Component: <Home />,
  },
 
  {
    path: SEARCH_USER + ":id",
    Component: <UserLayer />,
  },

  {
    path: FEATURES_ROUTE,
    Component: <Features />,
  },
  {
    path: HELP_ROUTE,
    Component: <Help />,
  },
  {
    path: DOCS_ROUTE,
    Component: <Docs />,
  },
  {
    path: TERMS_ROUTE,
    Component: <Terms />,
  },
  {
    path: MESSAGE_ROUTE + "/:type/:id?",
    Component: <Message />,
  },
  {
    path: USER_TIMEOUT_ROUTE,
    Component: <TimeOut />,
  },
  {
    path: MAP_ROUTE,
    Component: <Map />,
  },
  
];
