import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteMessage, readMessage, starredMessage } from "../http/messageApi";
import { afterStarred, star, trash } from "../images";
import { isEmail } from "../utils";
import { SEARCH_USER } from "../utils/consts";
import MessagePopup from "./MessagePopup";
import CheckBox from "./CheckBox";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { isMobile,isMobile767 } from "../utils";
import { useMedia } from "../utils/use-media";

const MessageItem = observer(
  ({ userId, message, onDelete, onMark, onRead, handlePreviewMessage }) => {
    const navigate = useNavigate();
    const mobile = useMedia(isMobile767);

    const [isOpen, setOpen] = useState(false);

    const date = new Date(message.createdAt);
    const now = new Date();
    let dateTimeModify = "";
    let showDateTime = true;
    const [isStarred, setIsStarred] = useState(
      message.userId === userId
        ? message?.SenderStarred
        : message?.ReceiverStarred
    );

    // Check if the message was received today
    if (date.toDateString() === now.toDateString()) {
      // If received today, display only time
      const formattedHours = date.getHours();
      const formattedMinutes =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      dateTimeModify = formattedHours + ":" + formattedMinutes;
      showDateTime = false; // Only show time for today
    } else {
      // Check if the message was received within the current week
      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      const diffDays = Math.round(Math.abs((now - date) / oneDay));
      if (diffDays >= 1 && diffDays < 7) {
        // If received within the current week, display only date
        const formattedDate =
          date.getDate() +
          " " +
          date.toLocaleString("en-US", { month: "short" }) +
          " " +
          date.getFullYear();
        dateTimeModify = formattedDate;
        showDateTime = false; // Only show date for messages older than today
      } else {
        // For older messages, display the full date and time
        const formattedDate =
          date.getDate() +
          " " +
          date.toLocaleString("en-US", { month: "short" }) +
          " " +
          date.getFullYear();
        const formattedHours = date.getHours();
        const formattedMinutes =
          date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
        dateTimeModify =
          formattedDate + ", " + formattedHours + ":" + formattedMinutes;
      }
    }

    const dateTime =
      date.getHours() +
      ":" +
      (date.getMinutes() < 10 ? "0" : "") +
      date.getMinutes() +
      ", " +
      date.getDate() +
      " " +
      date.toLocaleString("en-US", { month: "short" }) +
      " " +
      date.getFullYear();

    // console.log(now.isSame(b));

    /// Confirm before deleting the message
    const confirmDelete = async () => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="fixed mx-auto p-[10px] w-[540px] message-popup bg-[#F8F8F8] self-center dark:bg-[#000000] border-[#DAD7D4] m-auto inset-x-0 inset-y-0 border-[1px] rounded-md custom-items-center">
              <h2 className="block mb-[20px] justify-left text-lg md:text-xl font-semibold text-[#645F5B] dark:text-[#EAEAEA]">
                Permanently delete?
              </h2>
              <div className="flex justify-between">
                <button
                  className="message-btn btn-delete text-base  p-[5px] text-[#e1655c] border-[1px] border-[#eac5c3] rounded"
                  onClick={() => {
                    destroyMessage();
                    onClose();
                  }}
                >
                  Delete
                </button>
                <button
                  className="message-btn btn-cancel text-base   p-[5px] text-[#645f5b] border-[1px] border-[#eaeaea] dark:border-[#ffffff] dark:text-[#ffffff] rounded"
                  onClick={onClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          );
        },
      });
    };

    /// Delete the selected message
    const destroyMessage = async () => {
      const data = await deleteMessage(message.id);
      onDelete();

      isOpen && setOpen(false);
    };

    const markMessage = async () => {
      const data = await starredMessage(message.id);
      onMark();
      if (message.open) {
        handlePreviewMessage({
          message: message,
        });
      }
    };

    const openMessage = async () => {
      handlePreviewMessage({
        message: message,
      });
      setOpen(true);
    };

    return (
      <>
        {message.userId === userId && (
          <div className="relative">
            <div
              className={`border border-[#DAD7D4] pb-[45px] bg-[#D7E7A966]  rounded p-2.5 cursor-pointer ${
                message.open ? "bg-[#EFEFEF] dark:bg-[#45423F] dark:border-[#575350]" : ""
              }`}
              onClick={() => openMessage()}
            >
              <div className="flex justify-between">
                <h2 className="font-semibold dark:text-[#FFFFFF]">
                  To:{" "}
                  <span
                    className="cursor-pointer"
                    onClick={() => navigate(SEARCH_USER + message.to)}
                  >
                    @{message.to}
                  </span>
                </h2>
                <span className="text-[9px] dark:text-[#FFFFFF]">
                  {dateTimeModify}
                </span>
              </div>
              <div className="flex flex-col justify-between relative">
                <div className="w-auto h-7 transition duration-200 ease-in-out overflow-hidden">
                  <p className="truncate dark:text-[#FFFFFF]">{message.body}</p>
                </div>
              </div>
            </div>
            <div className="bottom-0 left-0 p-2.5 absolute z-10">
              <div className="flex space-x-2.5">
                &nbsp;
                <CheckBox message={message} userId={userId} />
              </div>
            </div>
            <div className="absolute bottom-0 right-0 flex justify-between space-x-2.5 p-2.5">
              <div className="flex justify-between space-x-2.5">
                <input
                  id={`message-item-${message.id}`}
                  className="hidden"
                  type="text"
                  value={message?.SenderStarred}
                  name="stared"
                />
                <div
                  style={
                    message?.SenderStarred ? { background: "#A19526" } : {}
                  }
                  onClick={markMessage}
                  className="cursor-pointer ml-[10px] flex h-[25px] w-[25px] rounded  hover:bg-[#eaeaeb99] justify-center items-center border border-[#F2FB88] "
                >
                  {message?.SenderStarred ? (
                    <img
                      height={15}
                      width={15}
                      src={afterStarred}
                      alt="afterStarred"
                    />
                  ) : (
                    <img height={15} width={15} src={star} alt="star" />
                  )}
                </div>
                <div
                  onClick={confirmDelete}
                  className="cursor-pointer flex h-[25px] w-[25px] rounded hover:bg-[#eaeaeb99]  justify-center items-center border border-[#F6DAD0]"
                >
                  <img height={15} width={15} src={trash} alt="trash" />
                </div>
              </div>
            </div>
          </div>
        )}

        {message.userId !== userId && (
          <div className="relative">
            <div
              className={
                message.status
                  ? `border rounded p-2.5 cursor-pointer pb-[45px] ${
                    message.open ? "bg-[#EFEFEF] dark:bg-[#45423F] border-[#E0DFDE] dark:border-[#575350]" : "border-[#00B7C6] dark:border-[#00B7C6]"
                  }`
                  : `border border-[#00B7C6] dark:border-[#00B7C6] bg-[#D7E7A966] rounded p-2.5 cursor-pointer pb-[45px] `
              }
              onClick={() => openMessage()}
            >
              <div className="flex justify-between">
                <h2 className="font-semibold dark:text-[#FFFFFF]">
                  From:{" "}
                  <span
                    className="cursor-pointer"
                    onClick={() => navigate(SEARCH_USER + message.from)}
                  >
                    {isEmail(message.from) ? "" : "@"}
                    {message.from}
                  </span>
                </h2>
                <span className="text-[9px] dark:text-[#FFFFFF]">
                  {dateTimeModify}
                </span>
              </div>
              <div className="flex flex-col justify-between">
                <div className="w-auto h-7 transition duration-200 ease-in-out">
                  <p className="truncate dark:text-[#FFFFFF]">{message.body}</p>
                </div>
              </div>
            </div>
            <div className="bottom-0 left-0 p-2.5 absolute z-10">
              <div className="flex space-x-2.5">
                &nbsp;
                <CheckBox message={message} userId={userId} />
              </div>
            </div>
            <div className="absolute bottom-0 right-0 flex justify-between space-x-2.5 p-2.5">
              <div className="flex justify-between space-x-2.5">
                <input
                  id={`message-item-${message.id}`}
                  className="hidden"
                  type="text"
                  value={message?.ReceiverStarred}
                  name="stared"
                />
                <div
                  style={
                    message?.ReceiverStarred ? { background: "#A19526" } : {}
                  }
                  onClick={markMessage}
                  className="cursor-pointer ml-[10px] flex h-[25px] w-[25px] rounded  hover:bg-[#eaeaeb99] justify-center items-center  border border-[#F2FB88]"
                >
                  {message?.ReceiverStarred ? (
                    <img
                      height={15}
                      width={15}
                      src={afterStarred}
                      alt="afterStarred"
                    />
                  ) : (
                    <img height={15} width={15} src={star} alt="star" />
                  )}
                </div>
                <div
                  onClick={confirmDelete}
                  className="cursor-pointer flex h-[25px] w-[25px] rounded  hover:bg-[#eaeaeb99]  justify-center items-center border border-[#F6DAD0]"
                >
                  <img height={15} width={15} src={trash} alt="trash" />
                </div>
              </div>
            </div>
          </div>
        )}
        {mobile ? (
          <MessagePopup
            isOpen={isOpen}
            onRequestClose={async () => {
              setOpen(false);

              if (userId !== message.userId) {
                await onRead();
                await readMessage(message.id);
              }
            }}
            message={message}
            dateTime={dateTime}
            userId={userId}
            onFav={markMessage}
            onDelete={destroyMessage}
          />
        ) : null}
      </>
    );
  }
);

export default MessageItem;
