import { observer } from "mobx-react-lite";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  getLayerInfo,
  getQA,
  addBookmark,
  getBookmark,
  getShowcase,
} from "../http/userApi";
import { useNavigate } from "react-router-dom";
import {
  deleteSelectedMessages,
  starSelectedMessages,
  addQA,
  selectedFaqs,
  deleteQueationAnswer,
  editItem,
  getMessages,
} from "../http/messageApi";
import { sort_svg } from "../images";
import { Context } from "../index";
import colors from "../utils/colors";
import MessageItem from "./MessageItem";
import CheckBox from "./CheckBox";
import { star, trash } from "../images";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const MessagesList = observer(
  ({
    onDelete,
    onMark,
    onDeleteAll,
    handlePreviewMessage,
    openFirstMsg,
    setOpenFirstmsg,
  }) => {
    const { user, messages, Themefooter } = useContext(Context);
    const [orderBy, setOrderBy] = useState(false);
    const [msgFilter, setMsgFilter] = useState("inbox");
    const [count, setCount] = useState(0);
    console.log("username:", user.user.username);

    function sortByIdThenName(a, b) {
      if (orderBy) {
        const n = a.createdAt - b.createdAt;
        return a.createdAt.localeCompare(b.createdAt);
      } else {
        const n = b.createdAt - a.createdAt;
        return b.createdAt.localeCompare(b.createdAt);
      }
    }

    const checkAll = (e) => {
      const elements = document.querySelectorAll(".hidden-btn");
      elements.forEach((element) => {
        element.classList.add("hidden");
      });
      var value = false;
      if (e.target.checked) {
        value = true;
        elements.forEach((element) => {
          element.classList.remove("hidden");
        });
      }
      document
        .querySelectorAll("input[name=selectMessage]")
        .forEach((checkbox) => {
          checkbox.checked = value;
        });
    };

    const confirmDelete = async () => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="fixed max-w-full mx-auto p-[10px] w-[540px] message-popup bg-[#F8F8F8] self-center dark:bg-[#000000] border-[#DAD7D4] m-auto inset-x-0 inset-y-0 border-[1px] rounded-md custom-items-center">
              <h2 className="block mb-[20px] justify-left text-lg md:text-xl font-semibold text-[#645F5B] dark:text-white dark:text-[#EAEAEA]">
                Permanently delete?
              </h2>
              <div className="flex justify-between">
                <button
                  className="message-btn btn-delete text-base  p-[5px] text-[#e1655c] border-[1px] border-[#eac5c3] rounded"
                  onClick={() => {
                    deleteAll();
                    onClose();
                  }}
                >
                  Delete
                </button>
                <button
                  className="message-btn btn-cancel text-base   p-[5px] text-[#645f5b] border-[1px] border-[#eaeaea] dark:border-[#ffffff] dark:text-[#ffffff] rounded"
                  onClick={onClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          );
        },
      });
    };

    //// Delete Selected
    const deleteAll = async () => {
      let counter = 0;
      var deleteAllItems = {};
      document
        .querySelectorAll("input[name=selectMessage]")
        .forEach((checkbox) => {
          if (checkbox.checked) {
            deleteAllItems[counter] = checkbox.defaultValue;
            messages.removeMessage(checkbox.defaultValue);
            counter++;
          }
        });
      const data = deleteSelectedMessages(deleteAllItems);
      onDeleteAll();
      openFirstMessage(msgFilter);
    };

    ///// Mark All Message As Stared
    const starAll = async () => {
      let stared;
      let counter = 0;
      var starAllItems = {};

      // Gather all selectMessage checkboxes
      const boxes = document.querySelectorAll(
        "input[name=selectMessage]:checked"
      );
      var element = document.getElementById(
        `message-item-${boxes[0].defaultValue}`
      );
      if (element.value == "false") {
        stared = true;
      } else {
        stared = false;
      }
      // Loop through all selectMessage checkboxes
      boxes.forEach((checkbox) => {
        starAllItems[counter] = checkbox.defaultValue;
        var element = document.getElementById(
          `message-item-${checkbox.defaultValue}`
        );
        element.value = stared;
        messages.staredMessage(checkbox.defaultValue, stared);
        counter++;
      });
      //// Perform the operation with collected data
      const data = await starSelectedMessages(starAllItems, stared);
    };

    const openFirstMessage = (value) => {
      let msg;
      switch (value) {
        case "inbox":
          msg = messages.messages
            .filter(
              (msg) =>
                msg.to === user.user.username && msg.userId !== user.user.id
            )
            .slice()
            .sort(sortByIdThenName)?.[0];
          handlePreviewMessage({ message: msg }, true);
          break;
        case "unread":
          msg = messages.messages
            .filter(
              (msg) => msg.status === false && msg.userId !== user.user.id
            )
            .slice()
            .sort(sortByIdThenName)?.[0];
          handlePreviewMessage({ message: msg }, true);
          break;
        case "starred":
          msg = messages.messages
            .filter(
              (msg) =>
                (msg.SenderStarred === true && msg.userId === user.user.id) ||
                (msg.ReceiverStarred === true && msg.userId !== user.user.id)
            )
            .slice()
            .sort(sortByIdThenName)?.[0];
          handlePreviewMessage({ message: msg }, true);
          break;
        case "sent":
          msg = messages.messages
            .filter((msg) => msg.from === user.user.username)
            .slice()
            .sort(sortByIdThenName)?.[0];
          handlePreviewMessage({ message: msg }, true);
          break;

        default:
          msg = messages.messages
            .filter(
              (msg) =>
                msg.to === user.user.username && msg.userId !== user.user.id
            )
            .slice()
            .sort(sortByIdThenName)?.[0];
          handlePreviewMessage({ message: msg }, true);
      }
    };

    const filterMessage = (value) => {
      setOpenFirstmsg(false);
      setMsgFilter(value);
      openFirstMessage(value);
    };

    const totalTask = messages.messages.slice().sort(sortByIdThenName).length;

    if (openFirstMsg) {
      console.log("openFirstMsg", msgFilter);
      openFirstMessage(msgFilter);
    }
    // useEffect(() => {
    //   getLayerInfo(user.user.username)
    //     .then((data) => {
    //         setUserId(data.id);
    //         selectedFaqs(data.id)
    //         .then((data) => {
    //           if (data) {
    //             setFaqAll(data);
    //           }
    //         });
    //     })
    //   .finally((response) => '');
    // }, []);

    return (
      <>
        <div className="flex items-center  space-x-1.5 cursor-pointer h-[25px]  justify-between">
          <div className="flex items-center  space-x-1.5">
          <div className="cursor-pointer ml-[10px] flex h-[25px] w-[25px] rounded justify-center items-center">
            <input
              onChange={checkAll}
              type="checkbox"
              id="checkAll" 
              name="checkAll"
              defaultChecked={false}
            />
          </div>

          <div className="rounded cursor-pointer ml-[10px] flex h-[25px] w-[25px] justify-center items-center trash-icon hidden hidden-btn border border-[#F6DAD0]">
            <img
              height={15}
              width={15}
              src={trash}
              onClick={confirmDelete}
              alt="delete"
            />
          </div>
          <div className="cursor-pointer rounded hover:bg-[#F2FB88] ml-[10px] flex h-[25px] w-[25px] justify-center items-center hidden hidden-btn border border-[#F2FB88]">
            <img
              height={15}
              width={15}
              src={star}
              onClick={() => {
                starAll();
                setCount(count + 1);
              }}
              alt="star"
            />
          </div>
          </div>

          <div className="bg-[#F5F5F5] py-[7px] px-[10px] rounded-[100px] flex items-center  space-x-1.5 cursor-pointer h-[30px] w-[70px] justify-center right-0">
            <span
              onClick={() => setOrderBy(!orderBy)}
              className="text-sm text-[#252423] dark:text-[#000000] font-semibold"
            >
              Date
            </span>
            <img
              onClick={() => setOrderBy(!orderBy)}
              height={15}
              width={15}
              src={sort_svg}
              className={
                orderBy
                  ? "origin-center rotate-180 transition"
                  : "origin-center rotate-0 transition"
              }
              alt="sort"
            />
          </div>
        </div>
        <div className="flex justify-between mb-2.5 items-center mt-[12px] lg:mt-[12px] border-b border-[#E0DFDE] dark:border-[#575350] py-2.5 ">
          <div className="flex justify-between space-x-1.5 items-center text-xs">
            <span
              className={`border border-[#252423] px-[10px] py-[5px]  rounded-full font-semibold cursor-pointer dark:text-[#FFFFFF] message-status-buttons  ${
                msgFilter === "inbox" ? "bg-[#252423] text-[#ffffff] dark:bg-[#FFFFFF] dark:text-[#252423]" : "text-[#252423]  dark:text-[#FFFFFF] dark:border-[#FFFFFF]"
              }`}
              onClick={() => filterMessage("inbox")}
            >
              Inbox
            </span>
            <span
              className={`active:bg-[#67743D] active:text-[#fff] border border-[#C7FFF8]  
            px-[10px] py-[5px]  rounded-full font-semibold cursor-pointer message-status-buttons hover:bg-[#67743D26] ${
              msgFilter === "unread"
                ? "bg-[#00B7C6] text-[#ffffff] hover:bg-[#00B7C6] "
                : "text-[#00B7C6]"
            }`}
              onClick={() => filterMessage("unread")}
            >
              Unread
            </span>
            <span
              className={` border border-[#F2FB88] 
             px-[10px] py-[5px]  rounded-full font-semibold cursor-pointer message-status-buttons hover:bg-[#D8A34026] ${
               msgFilter === "starred"
                 ? "bg-[#A19526] text-[#ffffff] hover:bg-[#A19526]"
                 : "text-[#A19526]"
             }`}
              onClick={() => filterMessage("starred")}
            >
              Starred
            </span>
            <span
              className={` border border-[#E0DFDE]  px-[10px] py-[5px] rounded-full font-semibold cursor-pointer message-status-buttons  ${
                msgFilter === "sent"
                  ? `bg-[#7B746E] text-[#ffffff] hover:bg-[#7B746E]`
                  : "text-[#252423] dark:text-[#ffffff]"
              }`}
              onClick={() => filterMessage("sent")}
            >
              Sent
            </span>
          </div>
        </div>

        <div
          className={
            totalTask > 10
              ? "block flex-col space-y-2.5 pb-2 custom-message-scroll"
              : "block flex-col space-y-2.5 pb-2 custom-message-scroll"
          }
        >
          {/* <div className="flex flex-col space-y-2.5 pb-16"> */}
          {/* All */}
          {msgFilter === "inbox" && (
            <>
              {messages.messages
                .filter(
                  (msg) =>
                    msg.to === user.user.username && msg.userId !== user.user.id
                )
                .slice()
                .sort(sortByIdThenName).length === 0 && (
                <span className="text-center text-xl text-[#645F5B] dark:text-[#FFFFFF] font-semibold">
                  The "Inbox" tab is empty
                </span>
              )}
              {messages.messages
                .filter(
                  (msg) =>
                    msg.to === user.user.username && msg.userId !== user.user.id
                )
                .slice()
                .sort(sortByIdThenName)
                .map((message, i) => (
                  <MessageItem
                    key={message.id}
                    onDelete={() => {
                      messages.removeMessage(message.id);
                      if (message?.open) {
                        openFirstMessage(msgFilter);
                      }
                    }}
                    onMark={() => {
                      messages.markedMessage(message.id);
                    }}
                    onRead={() => {
                      messages.readThisMessage(message.id);
                    }}
                    userId={user.user.id}
                    message={message}
                    handlePreviewMessage={handlePreviewMessage}
                  />
                ))}
            </>
          )}

          {/* Unread */}
          {msgFilter === "unread" && (
            <>
              {messages.messages
                .filter(
                  (msg) => msg.status === false && msg.userId !== user.user.id
                )
                .slice()
                .sort(sortByIdThenName).length === 0 && (
                <span className="text-center text-xl text-[#645F5B] dark:text-[#FFFFFF] font-semibold">
                  The "Unread" tab is empty
                </span>
              )}
              {messages.messages
                .filter(
                  (msg) => msg.status === false && msg.userId !== user.user.id
                )
                .slice()
                .sort(sortByIdThenName)
                .map((newMsg) => (
                  <MessageItem
                    key={newMsg.id}
                    onDelete={() => {
                      messages.removeMessage(newMsg.id);
                      if (newMsg.open) {
                        openFirstMessage(msgFilter);
                      }
                    }}
                    onMark={() => {
                      messages.markedMessage(newMsg.id);
                    }}
                    onRead={() => {
                      messages.readThisMessage(newMsg.id);
                    }}
                    userId={user.user.id}
                    message={newMsg}
                    handlePreviewMessage={handlePreviewMessage}
                  />
                ))}
            </>
          )}

          {/* Starred */}
          {msgFilter === "starred" && (
            <>
              {messages.messages
                .filter(
                  (msg) =>
                    (msg.SenderStarred === true &&
                      msg.userId === user.user.id) ||
                    (msg.ReceiverStarred === true &&
                      msg.userId !== user.user.id)
                )
                .slice()
                .sort(sortByIdThenName).length === 0 && (
                <span className="text-center text-xl text-[#645F5B] dark:text-[#FFFFFF] font-semibold">
                  The "Starred" tab is empty
                </span>
              )}
              {messages.messages
                .filter(
                  (msg) =>
                    (msg.SenderStarred === true &&
                      msg.userId === user.user.id) ||
                    (msg.ReceiverStarred === true &&
                      msg.userId !== user.user.id)
                )
                .slice()
                .sort(sortByIdThenName)
                .map((newMsg) => (
                  <MessageItem
                    key={newMsg.id}
                    onDelete={() => {
                      messages.removeMessage(newMsg.id);
                      if (newMsg.open) {
                        openFirstMessage(msgFilter);
                      }
                    }}
                    onMark={() => {
                      messages.markedMessage(newMsg.id);
                    }}
                    onRead={() => {
                      messages.readThisMessage(newMsg.id);
                    }}
                    userId={user.user.id}
                    message={newMsg}
                    handlePreviewMessage={handlePreviewMessage}
                  />
                ))}
            </>
          )}

          {/* Sent */}
          {msgFilter === "sent" && (
            <>
              {messages.messages
                .filter((msg) => msg.from === user.user.username)
                .slice()
                .sort(sortByIdThenName).length === 0 && (
                <span className="text-center text-xl text-[#645F5B] dark:text-[#FFFFFF] font-semibold">
                  The "Sent" tab is empty
                </span>
              )}
              {messages.messages
                .filter((msg) => msg.from === user.user.username)
                .slice()
                .sort(sortByIdThenName)
                .map((newMsg) => (
                  <MessageItem
                    key={newMsg.id}
                    onDelete={() => {
                      messages.removeMessage(newMsg.id);
                      if (newMsg.open) {
                        openFirstMessage(msgFilter);
                      }
                    }}
                    onMark={() => {
                      messages.markedMessage(newMsg.id);
                    }}
                    onRead={() => {
                      messages.readThisMessage(newMsg.id);
                    }}
                    userId={user.user.id}
                    message={newMsg}
                    handlePreviewMessage={handlePreviewMessage}
                  />
                ))}
            </>
          )}
          {/* </div> */}
        </div>
        <div className="border-t border-[#E0DFDE] dark:border-[#575350] w-full md:pb[0]"></div>
      </>
    );
  }
);

export default MessagesList;
