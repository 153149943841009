import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"
import ReactModal from "react-modal";
import { Context } from "../index";
import { observer } from "mobx-react-lite";
import { arrow, arrow_svg } from "../images/index";
import RightMenu from "../components/RightMenu";
import Header from "../components/Header";
import GoogleAuth from "../components/Authorization/Google";
import GithubAuth from "../components/Authorization/Github";
import {
  USER_ACCOUNT_ROUTE,
  USER_SETNAME_ROUTE,
  USER_SETTINGS_ROUTE,
  USER_TIMEOUT_ROUTE,
} from "../utils/consts";
import {
  GithubOAuth,
  GithubConnect,
  TwitterConnect,
  TwitterOAuth,
  twitterThirdFlow,
  getInfo,
  verifyOtpSignin,
  activeSession,
  getUserIpTimeAddress,
  verifyOtpSigninWithIp
} from "../http/userApi";
import TwitterAuth from "../components/Authorization/Twitter";
import AppleAuth from "../components/Authorization/Apple";
import DiscordAuth from "../components/Authorization/Discord";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import { useMedia } from "../utils/use-media";
import { isMobile } from "../utils";
import { Themefooter } from "../components/ThemeFooter"
import Email from "../components/Authorization/Email";
import SignInMenu from "../components/Authorization/signInMenu";
import { showToast } from "../utils/BannerPopup";


ReactModal.setAppElement("#root");


const Home = observer(() => {
  const { user } = useContext(Context);
  const currentUserId = (user) ? user.user.id : 0;
  const headerRef = useRef();
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme-color") === "white-content" ? false : true
  );
  const [authState, setAuthState] = useState(false);
  const navigate = useNavigate();
  let mobile = useMedia(isMobile);

  // User By Pass Function
  const bypassFn = () => {
    localStorage.setItem(
      "token",
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTI4LCJlbWFpbCI6IlJvaGl0RGhpbmdyYTA3ODZAZ2l0aHViLmNvbSIsInVzZXJuYW1lIjoidGVzdF9naXRodWIiLCJpYXQiOjE2ODM0NzkyMjgsImV4cCI6MTY4MzU2NTYyOH0.Nj3SqD5RT1wR1LVP9BJHSbJu70LY6566vG7cFkt9ivI"
    );
  };

  useEffect(() => {
    handleTwitterLoginFlow();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const codeParam = urlParams.get("code");
      if (codeParam)
        // setAuthState(true);
        if (user.isAuth) {
          GithubConnect(codeParam).then((data) => {
            if (data.status == 2) {
              showToast(
                data.message,
                { type: "error" }
              );
              return;
            }
            getInfo()
              .then((data) => {
                window.location.href = USER_SETTINGS_ROUTE;
              })
              .finally();
          });
        }
        else {
          //console.log('======================: false auth');
          getUserIpTimeAddress()
          .then((responseIp) => {
            const { ipAddress, address, currentTime } = responseIp; // destructuring of object
            return GithubOAuth(codeParam,ipAddress, address, currentTime);
          })
          .then((data) => {
            if (data.status == 2) {
              showToast(
                data.message,
                { type: "error" }
              );
              return;
            }
            getInfo()
              .then((data) => {
                if (data.id) (data.username)(data?.backup_email) ? window.location.href = USER_SETTINGS_ROUTE : window.location.href = USER_SETTINGS_ROUTE;
              })
              .finally();
            if (data.id) (data.username)(data?.backup_email) ? window.location.href = USER_ACCOUNT_ROUTE : window.location.href = USER_SETNAME_ROUTE;
          });
        }
    }, 500);
  }, [user]);


  //// Handle Twitter Auth Login Flow
  const handleTwitterLoginFlow = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let oauth_token = urlParams.get("oauth_token"),
      oauth_verifier = urlParams.get("oauth_verifier");

    if (oauth_token && oauth_verifier) {
      setAuthState(true);
      twitterThirdFlow({
        oauth_token,
        oauth_verifier,
      })
        .then((res) => {
          setAuthState(false);
          if (res?.status == 1) handleTwitterLogin(res?.response);
        })
        .catch((error) => {
          setAuthState(false);
        });
    }
  };

  //// Handle Twitter Auth Login
  const handleTwitterLogin = async (res) => {
    if (user.isAuth) {
      TwitterConnect({
        oauthToken: res?.oauth_token,
        oauthVerifier: res?.oauth_token_secret,
      }).then((data) => {
        if (data?.status === 2) {
          showToast(data?.message);
          return;
        }
        window.location.href = USER_SETTINGS_ROUTE;
      });
    } else {
      const responseIp= await getUserIpTimeAddress();
      const {ipAddress, address, currentTime } = responseIp;// destructuring of object 
      TwitterOAuth({
        oauthToken: res?.oauth_token,
        oauthVerifier: res?.oauth_token_secret,
        ipAddress, address, currentTime
      }).then((data) => {
        if (data?.status === 2) {
          showToast(data?.message);
          return;
        }
        if (data && data.id) {
          if (data.username && data?.backup_email) {
            window.location.href = USER_ACCOUNT_ROUTE;
          } else {
            window.location.href = USER_SETNAME_ROUTE;
          }
        }
      });
    }
  };

  useEffect(() => {
    window.addEventListener("theme-color", () => {
      setDarkMode(
        localStorage.getItem("theme-color") === "white-content" ? false : true
      );
    });
  }, []);


  const verifyOtp = async (otp) => {
    console.log("verifyotppppp")
    try{
      const responseIp= await getUserIpTimeAddress();
      const {ipAddress, address, currentTime } = responseIp;// destructuring of object 
      const response = await verifyOtpSigninWithIp(otp,"new_user",ipAddress, address, currentTime);
      console.log('Verification response:', response);
      if(response?.status===1){
        if(response.token)
        localStorage.setItem("token", response.token);
        response?.session_id && localStorage.setItem("session_id", response?.session_id);
        window.location.href = USER_ACCOUNT_ROUTE
      }else {
        window.location.href = USER_TIMEOUT_ROUTE;
      }
    } catch (error) {
      window.location.href = USER_TIMEOUT_ROUTE;
      console.error('Error verifying OTP:', error.response ? error.response.data : error.message);
      return 0;
    }
  }

  const location = useLocation();
  const pathHash = location.hash.split('#');
  console.log('Latitude:', pathHash, pathHash.length);
  if (pathHash?.length === 2) {
    const otp = pathHash[1];
    if (otp.length === 6) {
      console.log('OTP:', otp);
    }
    verifyOtp(otp);
  } 

  return (
    <main className="flex flex-col justify-between h-screen bg-white dark:bg-[#1A1919] overflow-hidden">
      {authState && <Loader isVisible={authState} />}
      <Header ref={headerRef} />
      <div className="custom-wrapper">
        <div className="flex justify-between bg-white dark:bg-[#1A1919]  h-full overflow-y-auto no-scrollbar">
          <div className="home_left_wrapper h-full pt-[20px] md:pt-[40px]">
            <div className="flex flex-col justify-between container md:max-w-5xl px-5 mx-auto h-full">
              <div className="lg:flex space-x-2 items-center">
                <span className="text-[#645F5B] dark:text-[#FFFFFF]">Home</span>
              </div>

              <div className="flex flex-col lg:flex-row justify-between lg:space-x-10 space-y-10 lg:space-y-0 mt-[60px] pb-[20px] md:pb-[40px]">
                <div className="border-t border-EA dark:border-[#575350] w-full">
                  <h1 className="mt-[20px] md:mt-[40px] text-[#645F5B] text-[30px] md:text-[50px] font-semibold  leading-[36px] md:leading-[61px] text-left  dark:text-white">
                    Layer
                  </h1>
                  <p className="mt-[40px] leading-[24px] md:text-2xl md:leading-[29px] text-xl font-semibold text-[#645F5B] dark:text-white dark:text-[#EAEAEA] ">
                    Your simplified web layer.
                  </p>
                </div>
                <div className="w-full">
                  <div className="border-t border-EA py-[10px] dark:border-[#575350] md:py-[20px]">
                    <p className="text-[#D46003] dark:text-[#FFFFFF]">
                      Bios, links, and more.
                    </p>
                  </div>
                  <div className="border-t border-EA py-[10px] dark:border-[#575350] md:py-[20px]">
                    <p className="text-[#5908DC] dark:text-[#FFFFFF]">
                      Free or plus.
                    </p>
                  </div>
                  <div className="border-t border-EA py-[10px] dark:border-[#575350] md:py-[20px]">
                    <p className="text-[#0B9C1A] dark:text-[#FFFFFF]">
                      Connected accounts.
                    </p>
                  </div>
                  <div className="border-t border-EA py-[10px] dark:border-[#575350] md:py-[20px]">
                    <p className="text-[#F400B0] dark:text-[#FFFFFF]">
                      Simplified messaging.
                    </p>
                  </div>
                  <div className="border-t border-EA py-[10px] dark:border-[#575350] md:py-[20px]">
                    <p className="text-[#A30B0B] dark:text-[#FFFFFF]">
                      More features coming soon.
                    </p>
                  </div>
                </div>
              </div>

              {user.isAuth && <div></div>}

              {!user.isAuth && (
                <SignInMenu headerRef={headerRef} />
              )}
            </div>
          </div>
          <RightMenu />
        </div>
      </div>
      <Themefooter />
    </main>
  );
});
export default Home;
