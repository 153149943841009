import { useContext, useEffect, useRef, useState } from "react";
import { delete_svg, borderIcon } from "../../images/index";
import {
  setImageBanner,
  DeleteImageBanner,
  getLayerInfo,
  setImageBannerBorder,
  saveComponents,
  setImageBannerUpload,
  deleteComponent
} from "../../http/userApi";
import { Context } from "../../index";
import { showToast } from "../../utils/BannerPopup";
import CropImageModal from "../CropImageModal";

const ImageComponent = ({ selectedUsername, onlyView, metadata }) => {
  console.log("metaaaaaa", metadata)

  const [image, setImage] = useState(metadata?.meta_data?.[0]?.meta_data?.image_url);
  const [imageBorder, setImageBorder] = useState(metadata?.meta_data?.[0]?.meta_data?.border ?? false);
  const [isCropPopup, setCropPopup] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const BannerInput = useRef(null);
  const fileInputRef = useRef(null);

  const userBanner = process.env.REACT_APP_API_URL;
  const { user } = useContext(Context);
  const isAuthPremium = user.user.is_premium;


  const handleImageChange = async (e) => {
    if (e.target.files[0].size > 10 * 1024 * 1024) {
      showToast("Maximum image size 10 MB.");
    } else if (
      e.target.files[0] &&
      e.target.files[0].size <= 10 * 1024 * 1024
    ) {

      setSelectedImage(e.target.files[0]);
      setCropPopup(true);
    }
    e.target.value = "";
  };

  const onEditComplete = async (file) => {
    setCropPopup(false);
    let formData = new FormData();
    formData.append("user_banner", file);

    try {
      const response = await setImageBannerUpload(formData);
      if (response?.status === 1) {
        var payload = {};
        if (metadata?.meta_data?.[0]?.id) {
          payload = {
            "user_id": user.user.id,
            "meta_id": metadata?.meta_id,
            "item_id": metadata?.meta_data?.[0]?.id,
            "data": [
              {
                "meta_data": {
                  "image_url": response?.banner_url,
                  "border": imageBorder,
                }
              }
            ]
          };
        } else {
          payload = {
            "user_id": user.user.id,
            "meta_id": metadata?.meta_id,
            "meta_name": "images",
            "item_index": metadata?.item_index,
            "data": [
              {
                "image_url": response?.banner_url,
                "border": imageBorder,
              }
            ]
          };
        }
        await saveComponents(payload);
      }

      // setImage(URL.createObjectURL(file));
      setImage(response?.banner_url)
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };


  // useEffect(() => {
  //   getLayerInfo(selectedUsername ? selectedUsername : user.user.username)
  //     .then((data) => {
  //       setImage(data?.user_banner ? userBanner + data?.user_banner : null);
  //       setImageBorder(data?.banner_border ? data?.banner_border : false);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching layer info:", error);
  //     });
  // }, [selectedUsername, user]);

  // useEffect(() => {
  //   setImage(metadata?.meta_data?.[0]?.meta_data?.image_url);
  //   setImageBorder(metadata?.meta_data?.[0]?.meta_data?.border ?? false);
  // }, [])


  const toggleImageBorder = async () => {
    const newImageBorderStatus = !imageBorder;
    setImageBorder(newImageBorderStatus);
    // const response = await setImageBannerBorder(newImageBorderStatus);
    try {

      let payload = {};
      if (metadata?.meta_data?.[0]?.id) {
        payload = {
          user_id: user.user.id,
          meta_id: metadata?.meta_id,
          item_id: metadata?.meta_data?.[0]?.id,
          data: [
            {
              meta_data: {
                image_url: image,
                border: newImageBorderStatus,
              },
            },
          ],
        };
      } else {
        payload = {
          user_id: user.user.id,
          meta_id: metadata?.meta_id,
          meta_name: "images",
          item_index: metadata?.item_index,
          data: [
            {
              image_url: image,
              border: newImageBorderStatus,
            },
          ],
        };
      }
      const response = await saveComponents(payload);
      console.log("Image and border updated successfully:", response);
    } catch (error) {
      console.error("Error updating image border:", error);
    }
  };

  const handleClick = () => {
    // document.getElementById("fileInput").click();
    fileInputRef.current.click();

  };

  const handleDeleteImage = async () => {
    // const response = await DeleteImageBanner();
    try {
      const data = {
        user_id: user.user.id,
        item_id: metadata?.meta_data?.[0]?.id,
        meta_id: metadata?.meta_id
      };

      const response = await deleteComponent(data);

      console.log("success deleted Image", response?.response);
    } catch (error) {
      console.error("Error Deleting image:", error);
    }
    setImage(null);
  };
  return (
    <>
      {(isAuthPremium || onlyView) && (
        <div className="">
          <div className="">
            <div className={`relative w-full h-[300px] rounded ${image ? "" : "bg-[#F5F5F5] dark:bg-[#302F2D]"}`}>
              {!onlyView && (
                <div
                  onClick={handleClick}
                  className="absolute w-[66px] h-[20px] top-2 right-2 bg-[#EAFFFC] dark:bg-[#EAFFFC] rounded z-10"
                >
                  <p className="text-xs text-[#00A3DD] font-medium cursor-pointer text-center p-[2px]">
                    Edit photo
                  </p>
                </div>
              )}
              <input
                // ref={BannerInput}
                // id="fileInput"
                ref={fileInputRef}
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: "none" }}
              />
              {image && (
                <img
                  src={image}
                  alt="Selected"
                  className={`absolute inset-0 w-full h-full object-cover ${imageBorder ? "border border-gray-300 rounded" : ""
                    }`}
                />
              )}
            </div>

            {image && !onlyView && (
              <div className="relative mt-4">
                <div className="relative w-full h-[45px] flex justify-between items-center bg-[#F5F5F5] dark:bg-[#302F2D] rounded-md">
                  <div className="rounded cursor-pointer ml-[10px] flex h-[25px] w-[25px] justify-center items-center trash-icon hidden-btn border border-[#CCEDF8]">
                    <img
                      className=""
                      src={borderIcon}
                      alt="borderIcon"
                      onClick={toggleImageBorder}
                    />
                  </div>
                  <div className="rounded cursor-pointer ml-[10px] flex h-[25px] w-[25px] justify-center items-center border border-[#F6DAD0] mr-[8px]">
                    <img
                      className=""
                      src={delete_svg}
                      alt="delete_svg"
                      onClick={handleDeleteImage}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      <CropImageModal
        isVisible={isCropPopup}
        selectedImage={
          selectedImage ? URL.createObjectURL(selectedImage) : ""
        }
        onClose={() => setCropPopup(false)}
        onEditComplete={onEditComplete}
        onCancel={() => {
          setCropPopup(false);
          setSelectedImage(null);
        }}
      />
    </>
  );
};

export default ImageComponent;
