import { React, useEffect, useState } from "react";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import AppleConnection from "../../../components/Connections/Apple";
import DiscordConnection from "../../../components/Connections/Discord";
import GithubConnection from "../../../components/Connections/Github";
import GoogleConnection from "../../../components/Connections/Google";
import TwitterConnection from "../../../components/Connections/Twitter";
import { tickCirlce } from '../../../images/index'

import {
  USER_ACCOUNT_ROUTE,
  USER_SETNAME_ROUTE,
  USER_SETTINGS_ROUTE,
} from "../../../utils/consts";
import {
  getInfo,
  setMailing,
  setSignedOutMailing,
  getConnections,
  deleteConnect,
  saveNotificationEmail,
  getOtpSignin,
  verifyOtpSignin,
  postBackupEmail,
  getOtpThemePlain,
  setSignInNotification,
  activeSession,
  countSession,
  signOutAll,
  allowOneSession,
} from "../../../http/userApi";
import Switch from "react-switch";
import { greenCheck } from "../../../images";
import VerifyModal from '../../../components/VerifyModal';
import Modal from "react-modal";
import { confirmAlert } from 'react-confirm-alert';
import { showToast } from "../../../utils/BannerPopup";


const SettingsConnections = ({ section, setLoading }) => {

  const [mailingStatus, setMailingStatus] = useState(false);
  const [emailState, setEmailState] = useState(false);
  const [newEmail, setNewEmail] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [signedOutMailingStatus, setSignedOutMailingStatus] = useState(false);
  const [connectionData, setConnectionData] = useState([]);
  const [dropDownData, setDropdownData] = useState([]);
  const [selctedEmail, setSelectedEmail] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);

  //backup email
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [showSent, setShowSent] = useState(false);
  const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false); 
  const [showVerified, setShowVerified] = useState(false);
  const [verifiedEmail, setVerifiedEmail] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const [checked, setChecked] = useState(false);
  const [validationMessage, setValidationMessage] = useState(null);
  const [emailNotification, setEmailNotification] = useState(false);
  const [sessions, setSessions] = useState(false);
  const [activeSessions, setActiveSessions] = useState(1);
  // const [backupEmail, setBackupEmail] = useState('');

  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };

  useEffect(() => {
    getInfo()
      .then((data) => {
        setUserInfo(data);
        setMailingStatus(data.mailing === 1 ? true : false);
        setSignedOutMailingStatus(data.mailing_signedout === 1 ? true : false);
        setSelectedEmail(data?.notification_email || "");
        if (data?.backup_email) {
          setVerifiedEmail(data?.backup_email|| '');
          setShowVerified(true);
          setEmail(data?.backup_email || " ")
        }
        if(data?.allow_one_session){
          setSessions(true);
        }
      })
      .finally(() => setLoading(false));
  }, [setLoading,verifiedEmail]);

  useEffect(() => {
    getConnectionInfo();
  }, [verifiedEmail]);

  useEffect(() => {
    const storedEmailNotification = localStorage.getItem('emailNotification'); 
    if (storedEmailNotification) {
      setEmailNotification(JSON.parse(storedEmailNotification));
    }
  }, []);
 
  useEffect(() => {
    localStorage.setItem('emailNotification', emailNotification);
    // console.log("email notify", emailNotification); 
  }, [emailNotification]);

  const getConnectionInfo = () => {
    getConnections().then((data) => {
      setConnectionData(data);

      let tempData = Array.from(data).flatMap((e) => {
        const primaryEmail = {
          label: e.email,
          value: e.email,
          className: "dropdown-options",
          ...e,
        };
        return [primaryEmail];
      })
      verifiedEmail && tempData.push(
        {
          label: verifiedEmail,
          value: verifiedEmail,
          className: "dropdown-options",         
        }
      );
      tempData=tempData.filter((info) => !(info?.is_protected === true || info?.is_protected?.trim() == 'true')).map((x)=>{
        return{
          label: x.label,
          value :x.value,
        }
      })
      
      const isUnique = (value, index, self) => {
        return self.findIndex(obj => obj.value === value.value) === index;
      };
      
      // Filter the array to remove duplicates
      tempData = tempData.filter(isUnique);
      setDropdownData(tempData);
    });
  };
  const onSelect = async (value) => {
    let status = value ? 1 : 0;

    const data = await setMailing(status);
  };

  const onSelectSignedOutMailing = async (value) => {
    let status = value ? 1 : 0;
    await setSignedOutMailing({
      id: userInfo?.id,
      status,
    });
  };

  const changeUserEmail = async () => {
    await setEmail(newEmail);
    setEmailState(false);
  };

  const removeAccount = (service) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='fixed  mx-auto p-[10px] w-[540px] message-popup bg-[#F8F8F8] self-center dark:bg-[#000000] border-[#DAD7D4] m-auto inset-x-0 inset-y-0 border-[1px] rounded-md custom-items-center'>
            <h2 className="block mb-[20px] justify-left text-lg md:text-xl font-semibold text-[#645F5B] dark:text-white dark:text-[#EAEAEA]">
              Unlink account
            </h2>
            <p className="block justify-left mb-[40px] text-base text-[#645F5B] dark:text-white">
              Confirm removal of connected account.
            </p>
            <div className='flex justify-between'>
              <button
                className="message-btn btn-delete text-base  p-[5px] text-[#e1655c] border-[1px] border-[#eac5c3] rounded"
                onClick={async () => {
                  const deleteThis = await deleteConnect(service);
                  if (deleteThis) {
                    getConnections();
                    window.location.href = USER_SETTINGS_ROUTE;
                  }
                  onClose();
                }}
              >
                Remove
              </button>
              <button
                className="message-btn btn-cancel text-base   p-[5px] text-[#645f5b] border-[1px] border-[#eaeaea] dark:border-[#ffffff] dark:text-[#ffffff] rounded"
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const onSelectEmail = (val) => {
    if (val !== selctedEmail) {
      setSelectedEmail(val);
      saveNotificationEmail(val);
    }
  };

  const openEmail = () => {
    setIsVerifyModalOpen(false);
    setIsModalOpen(true);
  }

  const sendOtp = async (email) => {
    try {
      const response = await getOtpThemePlain(email);
      console.log('OTP sent:', response);
    } catch (error) {
      console.error('Error sending OTP:', error.response ? error.response.data : error.message);
    }
  };

  const validTlds = [".com", ".org", ".net", ".in", ".co.uk", ".io", ".co", ".tv", "..."]; // Add more common TLDs

  function isValidTld(email) {
    const domain = email.split('@')[1];
    return domain.length >= 2 && validTlds.includes(domain.split('.').pop());
  }

  const validateEmail = (inputEmail) => {

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(inputEmail)) {
      setValidationMessage('Invalid email format.');
      return;
    }

    if (/^\s*|\s*$/.test(inputEmail)) {
      return { valid: false, message: "Email cannot start or end with whitespace." };
    }

    if (/[.]\./.test(inputEmail)) {
      return { valid: false, message: "Email cannot contain consecutive dots." };
    }

    if (!isValidTld(inputEmail)) {
      setValidationMessage('Email address may not be deliverable due to uncommon TLD.');
      return;
    }
    setValidationMessage('Email appears valid.');
  };

  const openVerifyModal = () => {
    setIsVerifyModalOpen(true); 
  };

  const handleInputChange = (e) => {
    const inputEmail = e.target.value;
    setEmail(inputEmail);
    setIsValidEmail(validateEmail(inputEmail));
    setShowSent(false);
    setShowVerified(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault(); 
    console.log('Submitted email:'); 
    sendOtp(email);
    setShowSent(true)
    setTimeout(openVerifyModal, 1000);
    // onSubmit();
  };

  const cancelButton = () => {
    setEmail(verifiedEmail);
    if (verifiedEmail) setShowVerified(true)
    setIsValidEmail(false);
  }

  const closeVerifyModal = () => {
    setIsVerifyModalOpen(false); // Function to close the modal
  };

  const resendVerify = () => {
    sendOtp(email);
  }

  const onVerify = async (otp) => {
    try {
      const response = await verifyOtpSignin(otp);
      console.log('Verification response:', response);
      if (response?.status === 1) {
        try {
          const res = await postBackupEmail(userInfo.id, email);
          console.log('postBackupEmail sent:', res);
          if (res?.status === 1) {
            setVerifiedEmail(email);
            setShowVerified(true);
            setTimeout(closeVerifyModal, 1000);
            return 1;
          }
          showToast(res?.message);
          return 0
        } catch (err) {
          showToast(err?.message);
          console.error('Error verifying OTP:', err.response ? err.response.data : err.message);
          return 0
        }
      } else {
        showToast(response?.message);
        return 0
      }
    } catch (error) {
      console.error('Error verifying OTP:', error.response ? error.response.data : error.message);
      return 0;
    }

  }


  const AuthenticatedHandleSwitchToggle = () => {
    const newMailingStatus = !mailingStatus;
    setMailingStatus(newMailingStatus);
    onSelect(newMailingStatus);
  };

  const AnybodyHandleSwitchToggle = () => {
    const newSignedOutMailingStatus = !signedOutMailingStatus;
    setSignedOutMailingStatus(newSignedOutMailingStatus);
    onSelectSignedOutMailing(newSignedOutMailingStatus);
  };

  const SignInToggle = async(checked) => {
    console.log("checked", checked);
    try {
      if (checked) {
        await setSignInNotification(true);
        setEmailNotification(true); 
        
      } else {
        await setSignInNotification(false);
        setEmailNotification(false); 
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

 ////////////// function to handle exit all active sessions except current one //////////////

  const handleExitOtherSessions = async () => {
    console.log("Signing out all other sessions...");

    try {
      const response = await signOutAll();
      if (response?.status) {
        console.log("All other sessions signed out successfully.");
        setActiveSessions(1); 
      } else {
        console.error("Error signing out 1 other sessions:", response?.error);
      }
    } catch (error) {
      console.error("Error signing out  2 other sessions:", error);
    }
  };

  const handleSessions = async () => {
    console.log("only one active session is true")
    try {
      const localSession = !sessions;
      setSessions(localSession);      
      await allowOneSession(localSession);
      handleExitOtherSessions(); 
      
    } catch (error) {
      console.error("Error toggling sessions switch:", error);
    }
  };

  ////////////////////////  useffect to handle countsession /////////////////////////
useEffect(()=>{
  const fetchActiveSession=async()=>{
    try {
      const response = await countSession();
      setActiveSessions(response.sessions);
  } catch (error) {
      console.error("Error fetching active sessions count:", error);
  }
  }
  fetchActiveSession();
},[])
  


  return (
    <div
      ref={section.ref}
      className="border-t border-EA w-full space-y-5">
        
      {/*divider*/}

      {/* {================================================ start: Connections ========================================= } */}
      
      <h1 className=" mt-10 leading-[24px] text-left space-y-10 md:text-2xl text-xl font-bold text-[#645F5B] dark:text-[#EAEAEA] ">
        Connections
      </h1>

      <div className="flex flex-1  flex-col "> 

        <h2 className=" mt-[30px] text-left md:text-xl text-lg font-semibold text-[#645F5B] dark:text-white dark:text-[#EAEAEA] ">
          Layer and backup email address
        </h2>
        <p className="mt-10  leading-[19px] text-left text-base text-[#645F5B] dark:text-white">
          Change and verify a new email address</p>
        <form>
          <div className='flex flex-col lg:flex-row justify-between '>
            <input
              type="email"
              placeholder="Email address"
              className="w-full lg:w-[450px] bg-gray-50 border border-gray-300 text-sm rounded-[5px] focus:ring-[#0A85D1] focus:border-[#0A85D1] block  py-2 px-4 dark:bg-gray-600 dark:border-gray-500
                    dark:placeholder-gray-400 dark:text-white mt-5 mb-2 text-base  font-normal text-[#645F5B]"
              value={email}
              onChange={handleInputChange}
              required
            />
            {isValidEmail && <img className='absolute right-[5px] bottom-[16px] w-[16px]' src={greenCheck} alt="greencheck"/>}
          </div>
          <div className='flex justify-between w-1/2 my-1'>
            {(!showSent && !showVerified) && <button
              type="submit"
              className={isValidEmail
                ? "border border-[#5FC650] text-[#5FC650] px-[10px] py-[9px] rounded cursor-pointer font-semibold leading-none text-base "
                : "bg-[#EAEAEA] text-[#9B9B9B] px-[10px] py-[9px] rounded cursor-pointer font-semibold leading-none text-base "
              }
              disabled={!isValidEmail}
              onClick={handleSubmit}
            >
              Send
            </button>
            }

            {(showSent || showVerified) && <div className='relative'>
              <button
                type="button"
                className="flex items-center border  border-[#5FC650] text-[white] bg-[#5FC650] p-[10px] rounded cursor-pointer font-semibold leading-none text-base "
                disabled={showVerified}
                onClick={handleSubmit}
              >
                {showVerified ? 'Verified' : 'Sent'}
                {<img className='w-[16px] ml-[8px]' src={tickCirlce} alt='tick' />}
              </button>
            </div>
            }

            <div className="w-4" />
            {email && !showSent && !showVerified && <button
              type="button"
              onClick={cancelButton}
              className="rounded leading-none border border-[#EAEAEA] bg-white px-[10px] py-[9px] text-sm  font-medium text-[#9B9B9B]"
            >
              Cancel
            </button>
            }
          </div>
        </form>
        {isVerifyModalOpen && <VerifyModal isOpen={isVerifyModalOpen} onClose={closeVerifyModal}
          onResend={resendVerify} onVerify={onVerify} onBack={openEmail} />
        }
      </div>
      {/* {================================================ Email Notification ========================================= } */}
      
      <div className="flex flex-col">
        <h2 className="mt-7 leading-[24.42px] text-left md:text-xl text-lg font-semibold text-[#645F5B] dark:text-white dark:text-[#EAEAEA]">
          Email notifications
        </h2>
        <p className=" mt-8 leading-[19px] text-left text-base text-[#645F5B] dark:text-white">
          Email address to receive message email updates</p>
        <Dropdown
          className="bg-[#D6D6D633] rounded-[5px] border border-[#EAEAEA] relative h-[35px] flex flex-row  w-full lg:w-[450px] mt-5"
          menuClassName="rounded px-[10px] py-[9px] text-sm font-semibold mt-[5px] w-full lg:w-[450px]"
          controlClassName=" w-full lg:w-[450px] flex justify-between px-[10px] py-[9px] leading-none bg-[#EAEAEA] hover:bg-[#E2E2E2] active:bg-[#CCC] border-none rounded-md"
          placeholderClassName="font-normal text-[#252423]"
          arrowClassName="dropdownArrow down"
          arrowClosed={<span className="dropdownArrow down" />}
          arrowOpen={<span className="dropdownArrow down" />}
          options={dropDownData}
          value={selctedEmail}
          onChange={(e) => onSelectEmail(e.value)}
        />
        <p className=" mt-10  leading-[19px] text-left text-base text-[#645F5B] dark:text-white  my-5">
          Message types
        </p>

        <div className="flex flex-col border border-[#EAEAEA] rounded">
          <div className="flex flex-row justify-between  items-center p-[10px]">
            <p className="text-[#645F5B] dark:text-[#FFFFFF] text-base ">
              Authenticated users signed in
            </p>

            <Switch
              checked={mailingStatus}
              size={36}
              width={36}
              height={24}
              value={mailingStatus}
              onChange={AuthenticatedHandleSwitchToggle}
            />
          </div>

          <div className="flex flex-row justify-between  items-center p-[10px] ">
            <p className="text-base text-[#645F5B] dark:text-white">
              Anybody signed out
            </p>
            <div className="customSwitch">

              <Switch
                checked={signedOutMailingStatus}
                size={36}
                width={36}
                height={24}
                value={signedOutMailingStatus}
                onChange={AnybodyHandleSwitchToggle}
              />
            </div>
          </div>
        </div>
        <div>
          <p className=" mt-10  leading-[19px] text-left text-base text-[#645F5B] dark:text-[#EAEAEA] my-5">
            Signing in
          </p>
          <div className="flex flex-col border border-[#EAEAEA] rounded">
            <div className="flex flex-row justify-between  items-center p-[10px]">
              <p className="text-[#645F5B] dark:text-[#FFFFFF] text-base ">
                When my Layer is signed into
              </p>

              <Switch
                checked={emailNotification}
                size={36}
                width={36}
                height={24}
                onChange={SignInToggle}
                
              />
            </div>
          </div>
        </div>
        {/* {================================================ Sessions ========================================= } */}

        <div>
        <h2 className="mt-10 leading-[24.42px] text-left md:text-xl text-lg font-semibold text-[#645F5B] dark:text-[#EAEAEA]">
        Sessions
        </h2>
        <p className="mt-10  leading-[19px] text-left text-base text-[#645F5B] dark:text-white  my-5">
          Manage active sessions
        </p>
        <div className="flex flex-col border border-[#EAEAEA] rounded">
          <div className="flex flex-row justify-between  items-center p-[10px] mt-[5px]">
            <h2 className="text-[#645F5B] dark:text-[#FFFFFF] text-xl ">
            {activeSessions}
            </h2>
            
            <button 
            className="message-btn btn-delete text-base font-regular  p-[5px] text-[#e1655c] border-[1px] border-[#eac5c3] rounded"
            onClick={handleExitOtherSessions}
            >
              
            Exit other sessions
           </button>
          </div>
          <p className="text-sm  font-regular text-[#645F5B] dark:text-white  p-[10px] mb-[10px]">
           (Active sessions including this one)
           </p>
        </div>

        <div className="flex flex-col border border-[#EAEAEA] rounded mt-[20px]">
          <div className="flex flex-row justify-between  items-center p-[10px]">
            <p className="text-[#645F5B] dark:text-[#FFFFFF] text-base ">
            Only allow one active session
            </p>

            <Switch
              checked={sessions}
              size={36}
              width={36}
              height={24}
              onChange={handleSessions}
            />
          </div>

          </div>
        </div>
    </div>

      {/* {================================================  Connected accounts ========================================= } */}

      <div className="flex flex-col mt-10">
        <h2 className="mt-8 leading-[24px] text-left md:text-xl text-lg font-semibold text-[#645F5B] dark:text-white dark:text-[#EAEAEA]">
          Connected accounts
        </h2>
        {/* <p className=" mt-10 text-base font-normal leading-[19px] text-left text-[#645F5B]  dark:text-[#FFFFFF] ">
          At least one connection is required for your Layer to be active.
        </p> */}
        <div className="flex flex-col mt-8 space-y-5">
          <GoogleConnection data={connectionData} onRemove={() => removeAccount("google")} />
          <GithubConnection data={connectionData} onRemove={() => removeAccount("github")} />
          <TwitterConnection data={connectionData} onRemove={() => removeAccount("twitter")} />
          <AppleConnection data={connectionData} onRemove={() => removeAccount("apple")} />
          <DiscordConnection data={connectionData} onRemove={() => removeAccount("discord")} />

        </div>
      </div>
    </div>
  );
};

export default SettingsConnections;