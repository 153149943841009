import { $authHost, $host } from "./index";

export const createMessage = async (to, body) => {
  const { data } = await $authHost.post("api/message/createMessage", {
    to,
    body,
  });
  return data;
};

export const getMessages = async () => {
  const { data } = await $authHost.get("api/message/getMessages");
  return data;
};

export const deleteMessage = async (id) => {
  const { data } = await $authHost.post("api/message/deleteMessage", { id });
  return data;
};

export const starredMessage = async (id) => {
  const { data } = await $authHost.post("api/message/starredMessage", { id });
  return data;
};

/// API Delete Selected Messages
export const deleteSelectedMessages = async (ids) => {
  const { data } = await $authHost.post("api/message/deleteSelectedMessages", { ids });
  return data;
};

/// API Mark Selected Messages As Statred 
export const starSelectedMessages = async (ids, stared) => {
  const { data } = await $authHost.post("api/message/starSelectedMessages", { ids, stared });
  return data;
};

export const readMessage = async (id) => {
  const { data } = await $authHost.post("api/message/readMessage", { id });
  return data;
};

export const createUnauthMessage = async (from, to, body) => {
  const { data } = await $authHost.post("api/message/createUnauthMessage", {
    from,
    to,
    body,
  });
  return data;
};

export const verifyUnauthMessage = async (verify_id) => {
  const { data } = await $authHost.post("api/message/verifyMessage", {
    verify_id,
  });
  return data;
};

/// Add New Q&A item
export const addQA = async (title, desc, user_id) => {
  const { data } = await $authHost.post("api/message/addQA",{
    title, 
    desc,
    user_id
  });
  return data;
};

/// Fetch Q&A items
export const selectedFaqs = async (user_id) => {
  const { data } = await $host.get("api/message/getQA?user_id="+user_id);
  return data.data;
};

/// Delete Q&A items
export const deleteQueationAnswer = async (itemId) => {
  const { data } = await $authHost.post("api/message/deleteQA", { itemId });
  return data;
};

export const editItem = async ( question, answer, user_id, id) => {
  const { data } = await $authHost.post('/api/message/updateQA', { question, answer, user_id, id }) 
  return data;
};

export const messagePermissions = async (messaging_permissions) => {
  const { data } = await $authHost.post("api/user/messaging-permissions",{messaging_permissions});
  return data;
}

// {
//   "userId": 215,
//   "messaging_permissions--": "every-body",
//   "messaging_permissions=": "layer-only",
//   "messaging_permissions": "bookmarks-only",
//   "messaging_permissions===": "no-body"
// }