import React, { useContext, useEffect, useState } from "react";

import { observer } from "mobx-react-lite";
import { getInfo } from "../http/userApi";
import RightMenu from "../components/RightMenu";
import Header from "../components/Header";
import { getMessages, starredMessage, deleteMessage } from "../http/messageApi";
import MessagesList from "../components/MessagesList";
import PreviewMessage from "./PreviewMessage";
import { Context } from "../index";
import { isMobile767 ,isMobile } from "../utils";
import { useMedia } from "../utils/use-media";

function sortByIdThenName(a, b) {
  return b.createdAt.localeCompare(b.createdAt);
}

export const Comms = observer(() => {
  const [refreshMessages, setRefreshMessages] = useState(false);
  const { user, messages, Themefooter } = useContext(Context);
  const [openFirstMsg, setOpenFirstmsg] = useState(false);
  const [loading, setLoading] = useState(true);
  let mobile = useMedia(isMobile767);

  const [data, setData] = useState({
    message: messages.messages
      .filter(
        (msg) => msg.to === user.user.username && msg.userId !== user.user.id
      )
      .slice()?.[0],
  });

  // Function to handle data from child
  const handlePreviewMessage = (message, autoFirst = false) => {
    setOpenFirstmsg(false);
    setData(message);
    if (!autoFirst) {
      onRead(message?.message);
    }
    messages.openMessage(message?.message.id);
  };

  const handleDeleteAll = () => {
    setRefreshMessages(true);  // Set the state to trigger the refresh of messages
    
  };
  const onDelete = async (message) => {
    const data = await deleteMessage(message.id);
    messages.removeMessage(message.id);
    setOpenFirstmsg(true);
  };
  const onMark = async (message) => {
    const starredData = await starredMessage(message.id);
    messages.markedMessage(message.id);
    handlePreviewMessage({ message: message });
  };
  const onRead = (message) => {
    messages.readThisMessage(message.id);
  };

  useEffect(() => {
    getInfo()
      .then((data) => {
        /// Load Messages
        getMessages().then((data) => {
          messages.setMessages(data);
          setData({
            message: data
              .filter(
                (msg) =>
                  msg.to === user.user.username && msg.userId !== user.user.id
              )
              .slice()
              .sort(sortByIdThenName)?.[0],
          });
        });
      })
      .catch((error) => console.log("error at account", error))
      .finally(
        setTimeout(function () {
          setLoading(false);
        }, 1000)
      );
    if (refreshMessages) {
      getMessages().then((data) => messages.setMessages(data));
      setRefreshMessages(false);
    }
  }, [messages, refreshMessages]);

  return (
    <main className="flex flex-col justify-start dark:bg-[#1A1919]">
      <Header />
      <div className="flex justify-between border-b border-EA custom-wrapper ">
        <div className="w-full customContainer__left screen_height">
          <div
            id="scrollTotop"
            className="flex flex-col w-[1060px] max-w-[100%] px-[20px] mx-auto md:pt-[40px] pt-[20px] md:pb-[40px] pb-[20px] space-x-2"
          >
            <div className="flex flex-col md:flex-row justify-between md:space-x-[40px]">
              {/* <div className="flex-1 md:pb-0 lg:max-w-[500px] lg:mx-auto"> */}
              <div className="flex-1 md:pb-0  lg:max-w-[500px] lg:mx-auto md:w-[324px] md:mx-auto">
                <MessagesList
                  onDeleteAll={handleDeleteAll}
                  handlePreviewMessage={handlePreviewMessage}
                  openFirstMsg={openFirstMsg}
                  setOpenFirstmsg={setOpenFirstmsg}
                />
              </div>
              {!mobile && (  
                <PreviewMessage
                  message={data?.message}
                  userId={user?.user?.id}
                  onFav={onMark}
                  onDelete={onDelete}
                  onRead={onRead}
                  openMessage={messages.openMessage}
                />
              )}
            </div>
          </div>
          <Themefooter></Themefooter>
        </div>
        <RightMenu />
      </div>
    </main>
  );
});

export default Comms;
