import React, { useContext, useRef, useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { paymentArrowBlue, paymentArrowRed, tickCirlce } from "../../../images";
import { USER_ACCOUNT_ROUTE, BASE_CONTROL } from "../../../utils/consts";
import { Box, FreeBox } from "../../../components/PlanDescription/BoxData"
import { setPremiumUser, createCheckoutSession, createBillingSession } from "../../../http/userApi";
import { useMedia } from "../../../utils/use-media";
import { useNavigate } from "react-router-dom";
import { isMobile } from "../../../utils";
import { Context } from "../../../index";


const PlanLayout = observer(({ section }) => {
  const navigate = useNavigate();
  const { user, Themefooter } = useContext(Context);

  const [isAuthPremium, setIsAuthPremium] = useState(user.user.is_premium);
  const headerRef = useRef();
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme-color") === "white-content" ? false : true
  );
  let mobile = useMedia(isMobile);

  useEffect(() => { setIsAuthPremium(user.user.is_premium) }, [user.user.is_premium]);

  // const onConfirm = () => {
  //   if (user?.isAuth) {
  //     navigate(USER_ACCOUNT_ROUTE);
  //   }
  //   else {
  //     headerRef?.current?.callBack();
  //   }
  // };

  // const onPaid = async () => {
  //   const res = await setPremiumUser(true);
  //   if (res?.status) {
  //     user.setUser({ ...user.user, is_premium: true });
  //     setIsAuthPremium(true);
  //     // setIsPlus(true);
  //     // setIsFree(false);
  //   }
  // }

  // const onFree = async () => {
  //   const res = await setPremiumUser(false);
  //   if (res?.status) {
  //     user.setUser({ ...user.user, is_premium: false });
  //     setIsAuthPremium(false);
  //     // setIsFree(true);
  //     // setIsPlus(false);
  //   }
  // }

  const handlePortalAndDowngrade = async () => {
    try {
      const response = await createBillingSession()
      window.location.href = response.url;
    } catch (error) {
      console.error('Error redirecting to customer portal:', error);
    }
  };

  return (
    <div ref={section.ref}>
      <div className="border-t border-EA w-full ">
        <h1 className="mt-10 leading-[24px] text-left md:text-2xl text-xl font-bold text-[#645F5B] dark:text-[#EAEAEA] ">
          Plans
        </h1>
        <h2 className="mt-[50px] text-left md:text-xl text-lg font-semibold text-[#645F5B] dark:text-white dark:text-[#EAEAEA] ">
          Free or Plus
        </h2>
        <p className={`text-base  font-regular leading-[19px] text-left dark:text-gray-400 text-[#252423] mt-[20px]`}>
          Current Plan
        </p>
      </div>
      {/*  */}

      {!isAuthPremium && <div className="flex justify-between w-full mt-2">
        <button
          className={`mt-[20px]  text-sm font-semibold border-[1px] rounded py-[5px] px-[8px] border-[#CCEDF8] mb-[10px] ${!isAuthPremium ? 'flex text-[#ffffff] border-[#00A3DD] bg-[#00A3DD]' : 'flex text-[#0A85D1] border-[#CCEDF8]'
            }`}
        >
          {!isAuthPremium ? 'Free' : 'Get Free'}
          {!isAuthPremium && <img className='w-[16px] ml-[8px]' src={tickCirlce} alt='tick' />}
        </button>
      </div>

      }
      <div className="flex justify-between flex-col md:flex-row w-full mt-4">
        <Box
          // onConfirm={onPaid}
          isConfirmed={isAuthPremium}
          isPlus={isAuthPremium}
          endPoint={BASE_CONTROL}
        />
      </div>

      {isAuthPremium && <div className="mt-2">
        <h2 className="text-base font-regular text-[#252423] dark:text-white">Manage Plus</h2>
        <div className="mt-4">
          <div className="flex flex-col justify-between items-center py-4 px-6 bg-[#F5F5F5] dark:bg-[#302F2D] rounded-lg mb-4">
           <div className="flex flex-col w-full">
             <div className="flex justify-between w-full">
              <p className="text-sm font-regular text-[#645F5B] dark:text-white">Manage your payments</p>
                <div onClick={handlePortalAndDowngrade}
                  className={"text-[#00B7C6] text-sm font-regular bg-[#EAFFFC] px-[4px] ml-auto flex items-center cursor-pointer rounded-[2px]"}>
                  Payments portal <span className="ml-2"><img height={8} width={8} src={paymentArrowBlue} alt="paymentArrowBlue" /></span>
                </div>
              </div>

              {/* <div className="flex flex-row justify-between mt-[20px]">
                <p className="text-sm font-regular text-[#645F5B] dark:text-white">Downgrade to Free</p>
                <div onClick={handlePortalAndDowngrade}
                  className={"text-[#D14414]  flex items-center text-sm font-regular bg-[#FAECE7] px-[4px] ml-auto cursor-pointer rounded-[2px]"}>
                  Payments portal <span className="ml-2"><img height={8} width={8} src={paymentArrowRed} alt="paymentArrowRed" /></span>
                </div>
              </div> */}

            </div>
          </div>
        </div>
      </div>
      }
    </div>

  );
});

export default PlanLayout;
