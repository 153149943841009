import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  defaultImage,
  blockIcon,
  ThreeDots,
  report,
  beforeBookmark,
  chatIcon,
  bookmarkTick,
} from "../../images/index";
import RightMenu from "../../components/RightMenu";
import Header from "../../components/Header";
import {
  getInfo,
  getLayerInfo,
  addBookmark,
  getBookmark,
  getShowcase,
  blockUser,
  verifyOtpSignin,
  getOtpThemePlain,
} from "../../http/userApi";
import { useNavigate, useParams } from "react-router-dom";
import { Context } from "../../index";
import {
  USER_TIMEOUT_ROUTE,
  REPORT_LAYER,
} from "../../utils/consts";
import {
  createMessage,
  createUnauthMessage,
  selectedFaqs,
  verifyUnauthMessage,
} from "../../http/messageApi";
import LinksList from "../../components/LinksList";
import Loader from "../../components/Loader";
import { Helmet } from "react-helmet";
import { confirmAlert } from "react-confirm-alert";
import VerifyModal from "../../components/VerifyModal";
import {
  LAYER_ONLY,
  BOOKMARK_ONLY,
  EVERYBODY,
  COMPONENT_ID,
  isMobile,
} from "../../utils/index";
import QuestionAndAnswer from "../../components/Base/QuestionAndAnswer";
import Modal from "react-modal";
import ShowCaseBanner from "../../components/ShowCaseBanner";
import BioContainer from "../../components/Base/BioContainer";
import { showToast } from "../../utils/BannerPopup";
import ImageComponent from "../../components/Base/ImageComponent";
import TextComponent from "../../components/Base/TextComponent";
import { useMedia } from "../../utils/use-media";

export const UserLayer = () => {
  const { id } = useParams();
  const headerRef = useRef();
  const { user, links, Themefooter, selectedUser } = useContext(Context);
  const isAuthPremium = user.user.is_premium;
  const [currentUserId, setCurrentUserId] = useState(user.user.id);
  const [sections, setSections] = useState([]);

  const [userInfo, setUserInfo] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [fromEmail, setFromEmail] = useState(null);
  const [messageBody, setMessageBody] = useState(null);
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme-color") === "white-content" ? false : true
  );
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [faqAll, setFaqAll] = useState([]);
  const [userId, setUserId] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const [showCases, setShowCases] = useState([]);
  // const defaultAvatarSrc = process.env.REACT_APP_API_URL + defaultImage;
  const [isPrivate, setIsPrivate] = useState(false);

  //// open threeDots menu dropdown states
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showBookmarkIcon, setShowBookMark] = useState(false);
  const dropdownRef = useRef(null);

  //for blocked user states
  const [isMeBlocked, setIsMeBlocked] = useState(false);
  const [isOtherBlocked, setIsOtherBlocked] = useState(false);
  const [isMeBlockedArr, setIsMeBlockedArr] = useState([]);
  const [isOtherBlockedArr, setIsOtherBlockedArr] = useState([]);

  /// message box states
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [email, setEmail] = useState("");
  const [validationMessage, setValidationMessage] = useState(null);
  const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [messagePermissions, setMessagePermissions] = useState("");
  const [messageId, setMessaageId] = useState(0);

  //// get bookmark id from arr these states are for bookmark id match
  const [bookmarked, setBookmarked] = useState([]);
  const [bookmarkedIsTrue, setBookmarkedIsTrue] = useState(false);

  // States to manage modal

  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [usernameInfo, setUsernameInfo] = useState("");
  const mobile = useMedia(isMobile);

  const loadAuthUser = async () => {
    const data = await getInfo();
    if (data?.contacts) {
      setIsMeBlockedArr(data?.contacts);
      isBlockedByMe(data?.contacts);
    }
    // setLoading(true);
    setCurrentUserId(data.id);
  };

  ////////   get bookmark id from array

  const bookmarkIdArr = (arr) => {
    arr?.forEach((element) => {
      if (currentUserId == element?.bookMarkId) {
        setBookmarkedIsTrue(true);
      }
    });
  };

  useEffect(() => {
    bookmarkIdArr(bookmarked);
  }, [bookmarked, bookmarkedIsTrue]);

  useEffect(() => {
    setCurrentUserId(user.user.id);
  }, [user.user.id]);

  //// Add Bookmark Users
  const bookmark = async () => {
    const data = await addBookmark(currentUserId, userId);
    if (!data.data) {
      // setTitleBookmark("Unmark");
      setShowBookMark(true);
    } else {
      // setTitleBookmark("Bookmark");
      setShowBookMark(false);
    }
  };

  //block user

  const isBlockedByOther = (arr) => {
    arr?.forEach((element) => {
      if (currentUserId == element?.contact_id) {
        setIsMeBlocked(true);
      }
    });
  };

  const isBlockedByMe = (arr) => {
    arr?.forEach((element) => {
      if (userId == element?.contact_id) {
        setIsOtherBlocked(true);
      }
    });
  };

  useEffect(() => {
    isBlockedByMe(isMeBlockedArr);
  }, [isMeBlockedArr]);

  useEffect(() => {
    isBlockedByOther(isOtherBlockedArr);
  }, [isOtherBlockedArr]);

  const sendMessage = async () => {
    await createMessage(userInfo.username, messageBody);
    setMessageBody("");
    showToast("Message sent");
    setOpenMessageModal(!openMessageModal);
  };

  const openVerifyModal = () => {
    setIsVerifyModalOpen(true);
  };

  const closeVerifyModal = () => {
    setIsVerifyModalOpen(false);
  };

  const resendVerify = () => {
    sendOtp(email);
  };

  const openEmail = () => {
    setIsVerifyModalOpen(false);
    setIsModalOpen(true);
  };

  const onVerify = async (otp) => {
    try {
      const response = await verifyOtpSignin(otp);
      if (response?.status === 1) {
        const res = await verifyUnauthMessage(messageId);
        if (res?.status != 1) {
          navigate(USER_TIMEOUT_ROUTE);
        } else {
          showToast("Message sent");
        }
        closeVerifyModal();
        // navigate(MESSAGE_ROUTE + "/1/" + messageId);
        // navigate(MESSAGE_ROUTE + "/1");
      }
      return response?.status;
    } catch (error) {
      console.error(
        "Error verifying OTP:",
        error.response ? error.response.data : error.message
      );
      return 0;
    }
  };

  const sendUnauthMessage = async () => {

    if (!validateEmail(fromEmail)) {
      // console.log('Please enter a valid email address.');
      return;
    }
  
    if (!validateMessageBody(messageBody)) {
      // console.log('Message body cannot be empty.');
      return;
    }

    const data = await createUnauthMessage(
      fromEmail,
      userInfo.username,
      messageBody
    );
    setMessaageId(data?.id);
    setOpenMessageModal(false);
    openVerifyModal();
  };

  // const searchUser = (e) => {
  //   e.preventDefault();
  //   navigate(SEARCH_USER + searchValue.toLowerCase());
  //   navigate(0);
  // };

  useEffect(() => {
    const storedValue = localStorage.getItem("isPrivate");

    if (storedValue) {
      setIsPrivate(JSON.parse(storedValue));
    }
  }, []);

  ///////  Handle click outside /////////////////////

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      setDropdownOpen(false);
    };
  }, []);

  /////////////////  open astrick dropdown menu ///////////////////

  const openDropDownMenu = () => {
    setDropdownOpen(true);
  };

  const closeDropDownMenu = () => {};

  const handleBlockClick = async () => {
    const res = await blockUser(userId);
    if (res) {
      setIsOtherBlocked(true);
    }
  };

  const UnblockUser = async () => {
    const res = await blockUser(userId);
    if (res) {
      setIsOtherBlocked(false);
    }
  };

  const confirmUnblock = async () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className=" fixed  flex items-center justify-center">
            <div className="fixed  max-w-full mx-auto p-[10px] w-[540px] message-popup  self-center bg-[#FFFFFF] dark:bg-[#252423] border-[#DAD7D4] m-auto inset-x-0 inset-y-0 border-[1px] rounded-[10px] custom-items-center">
              <h2 className=" block mb-[20px] justify-left text-lg md:text-xl font-semibold text-[#645F5B]  dark:text-[#EAEAEA]">
                Confirm{" "}
              </h2>
              <p className="block justify-left mb-[40px] text-base text-[#645F5B] dark:text-white">
                The user will be able to access your Layer.
              </p>
              <div className="flex justify-between">
                <button
                  className="message-btn btn-delete text-base  p-[5px] text-[#e1655c] border-[1px] border-[#eac5c3] rounded"
                  onClick={() => {
                    UnblockUser();
                    onClose();
                  }}
                >
                  Unblock
                </button>
                <button
                  className="message-btn btn-cancel text-base   p-[5px] text-[#645f5b] border-[1px] border-[#eaeaea] dark:border-[#ffffff] dark:text-[#ffffff] rounded"
                  onClick={onClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        );
      },
    });
  };

  useEffect(() => {
    setLoading(true);
    loadAuthUser();
    getLayerInfo(id)
      .then((data) => {
        if (data) {
          selectedUser.setUser(data);
          setUserId(data.id);
          setIsPrivate(data?.ignore_layer);
          setUserInfo(data);
          // getOrderedComponents(data?.components || []);

          const componentsData = data?.components || [];
          const formattedSections = componentsData.map((component) => ({
            id: component.meta_id,
            meta_name: component.meta_name,
            data: component.data,
          }));
          getorderedComponents(formattedSections);
          
          links.setLinks(data.links);
          setAvatar(data.avatar ? data.avatar : null);
          setIsOtherBlockedArr(data?.contacts);
          setUsernameInfo(data?.username);

          /// get message_permission dropdownValue
          setMessagePermissions(data?.messaging_permissions);
          setBookmarked(data?.bookmarks);

          /// Get Faqs
          selectedFaqs(data.id).then((data) =>  {
            if (data) {
              setFaqAll(data);
            }
          });
          /// Get Bookmarks
          if(user.isAuth ){
            getBookmark(user?.user?.id, data.id).then((data) => {
              if (data.status == true) {
                setShowBookMark(true);
              }
            });
          }
            
          /// Get Showcase Items
          getShowcase(data.id).then((data) => {
            setShowCases(data.data);
          });
          // getOrderedComponents(data?.components || []);
          setNotFound(false);
        } 
        else  {
          setNotFound(true);
        }
      })
      .catch((error) => {
        setNotFound(true); 
      })
      .finally((response) =>
        setTimeout(function () {
          setLoading(false);
        }, 1000)
      );
  }, []);

  if (loading) {
    return <Loader isVisible={loading} />;
  }

  /// validate email

  const validTlds = [
    ".com",
    ".org",
    ".net",
    ".in",
    ".co.uk",
    ".io",
    ".co",
    ".tv",
    "...",
  ]; // Add more common TLDs

  function isValidTld(email) {
    const domain = email.split("@")[1];
    return domain.length >= 2 && validTlds.includes(domain.split(".").pop());
  }

  const validateEmail = (inputEmail) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(inputEmail)) {
      setValidationMessage("Invalid email format.");
      return;
    }

    if (/^\s*|\s*$/.test(inputEmail)) {
      return {
        valid: false,
        message: "Email cannot start or end with whitespace.",
      };
    }

    if (/[.]\./.test(inputEmail)) {
      return {
        valid: false,
        message: "Email cannot contain consecutive dots.",
      };
    }

    if (!isValidTld(inputEmail)) {
      setValidationMessage(
        "Email address may not be deliverable due to uncommon TLD."
      );
      return;
    }
    setValidationMessage("Email appears valid.");
  };

  const validateMessageBody = (message) => {
    return message.trim().length > 0;
  };

  /// otp sent & verify

  const sendOtp = async (email) => {
    try {
      // const response = await getOtpSignin(email);
      const response = await getOtpThemePlain(email);
      console.log("OTP sent:", response);
    } catch (error) {
      console.error(
        "Error sending OTP:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const closeMessageModal = () => {
    setMessageBody("");
    setFromEmail("");
    setOpenMessageModal(!openMessageModal);
  };

  // Function to extract and order components based on orderedIds
  // const getOrderedComponents = (orderedIds) => {
    // const allSections = [
    //   {
    //     id: COMPONENT_ID.LINK_COMPONENT,
    //     content: <LinksList showProtectedList={false} onlyView={true} />,
    //   },
    //   {
    //     id: COMPONENT_ID.BIO_COMPONENT,
    //     content: <BioContainer />,
    //   },
    //   {
    //     id: COMPONENT_ID.QA_COMPONENT,
    //     content: <QuestionAndAnswer selectedUsername={id} onlyView={true} />,
    //   },
    //   {
    //     id: COMPONENT_ID.SHOWCASE_COMPONENT,
    //     content: <ShowCaseBanner />,
    //   },
    //   { id: COMPONENT_ID.TEXT_COMPONENT ,content: <TextComponent selectedUsername={id} onlyView={true} /> },
    //   {
    //     id: COMPONENT_ID.IMAGE_COMPONENT,
    //     content: <ImageComponent selectedUsername={id} onlyView={true} />,
    //   },
    //   {
    //     id: COMPONENT_ID.PROTECTED_COMPONENT,
    //     content: <LinksList onlyView={true} showProtectedList={true} />,
    //   },
    // ];
// };

    const allSections = [
      {
        meta_name: COMPONENT_ID.LINK_COMPONENT,
        content: (props) => <LinksList showProtectedList={false} onlyView={true} links={props} />,
      },
      {
        meta_name: COMPONENT_ID.BIO_COMPONENT,
        content: (props) => <BioContainer  metadata={props} />,
      },
      {
        meta_name: COMPONENT_ID.QA_COMPONENT,
        content: (props) => <QuestionAndAnswer selectedUsername={id} onlyView={true} metadata={props} />,
      },
      {
        meta_name: COMPONENT_ID.SHOWCASE_COMPONENT,
        content: (props) => <ShowCaseBanner metadata={props} />,
      },
  
      {
        meta_name: COMPONENT_ID.TEXT_COMPONENT,
        content: (props) => <TextComponent selectedUsername={id} onlyView={true}  metadata={props} />,
      },
  
      {
        meta_name: COMPONENT_ID.IMAGE_COMPONENT,
        content: (props) => <ImageComponent selectedUsername={id} onlyView={true} metadata={props} />,
      },
  
      {
        meta_name: COMPONENT_ID.PROTECTED_COMPONENT,
        content: (props) => <LinksList onlyView={true} showProtectedList={true} links={props} />,
      },
    ];

    // const orderedComponents = orderedIds.map(
    //   (
    //     id // Create an orderedComponents array based on orderedIds
    //   ) => allSections.find((section) => section.id === id)
    // );
    // setSections(orderedComponents);
    // // return orderedComponents;

    const getorderedComponents = (orderedMetaData) => {
      const orderedComponents = orderedMetaData?.map((metaData) => {
        const sectionData = allSections.find(
          (section) => section.meta_name === metaData?.meta_name
        );
        return {
          ...sectionData,
          ...metaData,
        };
      });
      setSections(orderedComponents);
      // return orderedComponents;
    };

  return (
    <div>
      <Modal // Modal for message send
        isOpen={openMessageModal}
        onRequestClose={closeMessageModal}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            height: "auto",
            padding: "0",
            background: "transparent",
          },
        }}
        contentLabel="Message Modal"
      >
        <div className="w-[540px]  max-w-full bg-[#F8F8F8] text-[#645F5B] dark:bg-[#252423] dark:text-[#fff] p-[20px]  radius-[5px] rounded-[10px]">
          <p className="text-sm  font-regular text-[#645F5B] dark:text-white">
            To: {usernameInfo}
          </p>
          <p className=" border-[1px] border-[#E0DFDE] dark:border-[#575350] mt-[12px] "></p>
          <textarea
            className=" w-full outline-0 border border-[#E8E7E366] bg-[#D6D6D633] rounded text-[#645F5B] dark:text-[#FFFFFF] p-2.5 resize-none h-[200px] margin-t-20 focus:border-[#589ED5]"
            onChange={(e) => setMessageBody(e.target.value)}
            value={messageBody ? messageBody : ""}
            placeholder="Message.."
          />

          {!user.isAuth && (
            <div className="mt-[20px]">
              <p className="text-xs font-regular text-[#645F5B] dark:text-[#fffff]">
                Your email
              </p>
              <input
                type="email"
                className="w-full outline-0 border border-[#E8E7E366] bg-[#D6D6D633] rounded text-[#645F5B] dark:text-[#FFFFFF] p-2.5 focus:border-[#589ED5] mt-[8px]"
                placeholder="Email"
                onChange={(e) => {
                  const inputValue = e.target.value;
                  setEmail(inputValue);
                  setFromEmail(inputValue);
                  setIsValidEmail(validateEmail(inputValue));
                }}
                value={fromEmail ? fromEmail : ""}
              />
            </div>
          )}

          <div className="flex justify-between min-w-100 items-center mt-[20px]">
            {user.isAuth && (
              <button
                className={
                  messageBody
                    ? " w-[63px] h-[35px] border border-[#CCEDF8] text-[#00A3DD] px-[10px] py-[9px] rounded cursor-pointer font-semibold leading-none text-base "
                    : "w-[63px] h-[35px] bg-[#EAEAEA] text-[#9B9B9B] px-[10px] py-[9px] rounded cursor-pointer font-semibold leading-none text-base "
                }
                disabled={!messageBody}
                onClick={sendMessage}
              >
                Send
              </button>
            )}

            {!user.isAuth && (
              <button
                className={
                  isValidEmail && messageBody
                    ? " w-[63px] h-[35px] border border-[#CCEDF8] text-[#00A3DD] px-[10px] py-[9px] rounded cursor-pointer font-semibold leading-none text-base "
                    : "w-[63px] h-[35px] bg-[#EAEAEA] text-[#9B9B9B] px-[10px] py-[9px] rounded cursor-pointer font-semibold leading-none text-base "
                }
                disabled={!isValidEmail && !messageBody}
                onClick={sendUnauthMessage}
              >
                Send
              </button>
            )}

            <button
              className="w-[77px] h-[35px] btn-cancel p-[10px] border-[1px] border-[#EAEAEA] dark:border-[#EAEAEA] dark:text-[#ffffff] rounded-[5px]  text-[#645F5B] text-16px font-medium flex items-center leading-[18px]"
              onClick={closeMessageModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>

      {isPrivate && (
        <Helmet>
          <meta name="robots" content="noindex,nofollow" />
        </Helmet>
      )}

      <main className="flex flex-col justify-start dark:bg-[#1A1919]">
        <Header ref={headerRef} />

        <div className="flex justify-between lg:border-b border-EA  dark:bg-[#1A1919] custom-wrapper">
          {/* <div className="w-full remove-sidebar-scroll"> */}
          <div className="w-full customContainer__left">
            <div
              id="scrollTotop"
              className="flex flex-col container max-w-5xl px-5 mx-auto"
            >
              {userInfo && (
                <>
                  <div className="hidden lg:flex pb-[60px] pt-[20px] md:pt-[40px] items-center ">
                    <div className="hidden lg:flex  items-center  space-x-2 ">
                      <span className="text-[#645F5B] dark:text-[#FFFFFF] ">
                        Layer
                      </span>
                      <span className="block w-1.5 h-1.5 bg-EA rounded-full"></span>
                      <span className="text-[#645F5B] dark:text-[#FFFFFF] ">
                        @{userInfo?.username}
                      </span>
                    </div>
                    <div className="ml-auto">
                      <img
                        src={ThreeDots}
                        className={` object-cover rounded-[5px] styled-image ml-auto ${
                          dropdownOpen ? "blue-filter" : ""
                        }`}
                        onMouseEnter={openDropDownMenu}
                        alt="menu"
                      />
                      {user.isAuth &&
                        currentUserId != userId &&
                        dropdownOpen && (
                          <div
                            className="relative"
                            onMouseDown={closeDropDownMenu}
                          >
                            <div className="absolute mr-[10px] top-[12px] right-0  w-[220px] h-[79px] bg-[#E0DFDE] rounded-md ">
                              <div ref={dropdownRef}>
                                <ul className="py-2 p-[10px]">
                                  <li className=" w-[200px] h-[32px] p-[6px] px-4 cursor-pointer hover:bg-[#FFFFFF] active:bg-[#FFFFFF]  rounded-[3px] text-[#252423]">
                                    <a
                                      href={REPORT_LAYER}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="flex items-center justify-between "
                                    >
                                      Report layer
                                    
                                    <img
                                      src={report}
                                      className="w-4 h-4 "
                                      alt="report"
                                    />
                                    </a>
                                  </li>
                                  <li
                                    className="w-[200px] h-[32px] flex items-center justify-between px-4 py-2 cursor-pointer hover:bg-[#FFFFFF] active:bg-[#FFFFFF] rounded-[3px] text-[#252423]"
                                    onClick={handleBlockClick}
                                  >
                                    Block
                                    <img
                                      src={blockIcon}
                                      className="w-4 h-4"
                                      alt="block"
                                    />
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>

                  {(isMeBlocked || isOtherBlocked) && (
                    <div className="flex flex-col lg:flex-row justify-between lg:space-x-10">
                      <div className="lg:border-t border-EA w-full lg:w-full">
                        <div className="mt-10 ">
                          <div className="flex flex-row justify-between">
                            <p className="text-[#645F5B] dark:text-[#FFFFFF]  text-xl font-semibold ">
                              @{userInfo.username}
                            </p>
                            {isOtherBlocked && (
                              <button
                                type="button"
                                className={
                                  " border border-[#E1655C] text-[#E1655C] px-[10px] py-[9px] rounded cursor-pointer font-semibold leading-none text-base "
                                }
                                onClick={confirmUnblock}
                              >
                                Unblock
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {!(isMeBlocked || isOtherBlocked) && (
                    <div className="flex flex-col justify-between  lg:space-x-10">
                      {/* <div className="lg:border-t border-EA w-full lg:w-1/2"> */}
                      <div className="custom_grid_container ">
              <div className=" mt-4 custom_grid">
                <div className="lg:border-t border-[#E0DFDE] dark:border-[#575350] overflow-x-hidden ">
                        <div className="flex justify-between md:pt-[40px] pt-[20px]">
                          <div>
                            <p className="text-[#645F5B] dark:text-[#FFFFFF]  text-xl font-semibold">
                              @{userInfo.username}
                            </p>
                          </div>

                          <div className="flex flex-row justify-between">
                            {((messagePermissions === BOOKMARK_ONLY &&
                              user.isAuth &&
                              bookmarkedIsTrue) ||
                              (messagePermissions === LAYER_ONLY &&
                                user.isAuth) ||
                              messagePermissions === EVERYBODY ||
                              messagePermissions === null) && (
                              <div className="mr-[10px]">
                                <div
                                  className="w-[30px] h-[30px] border border-[#C7FFF8] hover:border-[#0A85D1] cursor-pointer rounded-[5px] flex justify-center items-center"
                                  onClick={() => {
                                    setOpenMessageModal(true);
                                  }}
                                >
                                  <img
                                    src={chatIcon}
                                    width={12}
                                    height={15}
                                    alt="chatIcon"
                                  />
                                </div>
                              </div>
                            )}
                            {user.isAuth &&
                              currentUserId != userId &&
                              isAuthPremium && (
                                <div className="mr-[10px]">
                                  <div
                                    className={`w-[30px] h-[30px] border border-[#C7FFF8] hover:border-[#0A85D1] cursor-pointer rounded-[5px] flex justify-center items-center ${showBookmarkIcon? "bg-[#00B7C6]":""}`}
                                    onClick={() => bookmark()}
                                  >
                                    {!showBookmarkIcon ? (
                                      <img
                                        src={beforeBookmark}
                                        width={12}
                                        height={15}
                                        alt="beforeBookmark"
                                      />
                                    ) : (
                                      <img
                                        src={bookmarkTick}
                                        width={12}
                                        height={15}
                                        alt="bookmarkTick"
                                      />
                                    )}
                                  </div>
                                </div>
                              )}

                            <div className=" w-[60px] h-[60px] border border-[#EAEAEA] hover:border-[#0A85D1] cursor-pointer rounded-[5px] flex justify-center items-center">
                              <img
                                src={
                                  avatar
                                    ? process.env.REACT_APP_API_URL + avatar
                                    : defaultImage
                                }
                                className={
                                  avatar
                                    ? " w-[100%] h-[100%] object-cover rounded-[5px]"
                                    : "w-auto h-auto"
                                }
                                alt="avatar"
                              />
                            </div>
                          </div>
                        </div>
                        </div>
                        {/* SHOW SECTION START */}
                        {sections?.length > 0 &&
                          sections.map((section) => (
                            <>
                              <div key={section?.id} id={section?.id}>
                                {/* {section?.content} */}
                                {section?.content({ meta_data: section?.data, meta_id: section?.id, item_index: section?.item_index })}
                              </div>
                            </>
                          ))}
                   
                    
                      </div>
                      </div>

                   
                      {isVerifyModalOpen && (
                        <VerifyModal
                          isOpen={isVerifyModalOpen}
                          onClose={closeVerifyModal}
                          onResend={resendVerify}
                          onVerify={onVerify}
                          onBack={openEmail}
                        />
                      )}
                    </div>
                  )}

                  {/* //////// when user block other user this section will be visible/////////// */}

                  {(isMeBlocked || isOtherBlocked) && (
                    <div className="mt-[40px]">
                      <h2 className="text-xl lg:text-2xl font-bold text-[#645F5B] dark:text-[#EAEAEA]">
                        This user’s Layer is hidden.
                      </h2>
                    </div>
                  )}
                </>
              )}

              {notFound && (
                <h1 className="mt-5 text-xl font-semibold text-[#645F5B] dark:text-[#EAEAEA] ">
                  User not found.
                </h1>
              )}
            </div>
            <Themefooter></Themefooter>
          </div>
          <RightMenu />
        </div>
      </main>
    </div>
  );
};
export default UserLayer;
