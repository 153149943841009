
import { useState } from "react";
import {useNavigate} from 'react-router-dom'
import {emailLogo} from "../../images"
import EmailModal from '../EmailModal'; // Import the EmailModal component
import { verifyOtpSignin,getOtpSignin,getUserIpTimeAddress,verifyOtpSigninWithIp} from "../../http/userApi";
import { isMobile } from "../../utils";
import { USER_ACCOUNT_ROUTE, USER_SETNAME_ROUTE } from "../../utils/consts";
import { useMedia } from "../../utils/use-media";
import VerifyModal from '../VerifyModal';

function Email(){
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility
  const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false); // State to manage modal visibility
  const [email, setEmail] = useState('');
  const navigate=useNavigate();


  
  const sendOtp = async (email) => {
    try {
      const response = await getOtpSignin(email);
      console.log('OTP sent:', response);
    } catch (error) {
      console.error('Error sending OTP:', error.response ? error.response.data : error.message);
    }
  };


//   const verifyOtp=async(otp)=>{
//   try{
//   const response = await verifyOtpSignin(otp);
//   console.log('Verification response:', response);
// } catch (error) {
//   console.error('Error verifying OTP:', error.response ? error.response.data : error.message);
// }
//   }

  const openModal = () => {
    setIsModalOpen(true); // Function to open the modal
  };

  const closeModal = () => {
    setIsModalOpen(false); // Function to close the modal
  };
  const openEmail=()=>{
    setIsVerifyModalOpen(false);
    setIsModalOpen(true);
  }
  const openVerifyModal = (email) => {
    sendOtp(email);
    setEmail(email);
    setIsModalOpen(false); 
    setIsVerifyModalOpen(true); // Function to open the modal
  };

  const closeVerifyModal = () => {
    setIsVerifyModalOpen(false); // Function to close the modal
  };

  const resendVerify = () => {
    sendOtp(email);
  }

  const onVerify = async (otp) =>{
    console.log("onVerifyyyyy")

    try{
      const responseIp= await getUserIpTimeAddress();
      const {ipAddress, address, currentTime } = responseIp;// destructuring of object 
      const response = await verifyOtpSigninWithIp(otp,"new_user",ipAddress, address, currentTime);
      if(response?.status===1){
        if(response.token){
          localStorage.setItem("token", response.token);
        }
        response?.session_id && localStorage.setItem("session_id", response?.session_id);
        window.location.href = USER_ACCOUNT_ROUTE
      }
      return response?.status
    } catch (error) {
      console.error('Error verifying OTP:', error.response ? error.response.data : error.message);
      return 0;
    }

  }
    return (
        <>
            <button
            style={{
                height: useMedia(isMobile) ? 40 : 60,
                width: useMedia(isMobile) ? 40 : 60,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
            }}
            onClick={openModal}
            className="border mx-auto rounded-[4px] hover:bg-[#eaeaea4d]  bg-white
            dark:hover:bg-[#eaeaea4d] focus:bg-[#eaeaea] dark:focus:bg-[#eaeaea] active:bg-[#eaeaea] dark:active:bg-[#eaeaea] duration-300 ease-in-out cursor-pointer customSplashPage__logBtn"
            >
                <img
                    height={useMedia(isMobile) ? 20 : 30}
                    width={useMedia(isMobile) ? 20 : 30}
                    src={emailLogo}
                    alt="email logo"
                    
                    />
            </button>
            <EmailModal isOpen={isModalOpen} onClose={closeModal} onSubmit={openVerifyModal} />
           { isVerifyModalOpen &&
            <VerifyModal isOpen={isVerifyModalOpen} onClose={closeVerifyModal} onResend={resendVerify} 
              onVerify={onVerify} showNumText={true} onBack={openEmail}/>}
        </>
      );
}

export default Email;