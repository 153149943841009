import React from "react";
import { greenCheck } from "../../images";
import { LAYER_PAGE_CONNECTION } from "../../utils/consts";

const ConnectedBox = ({ title, isRemove, onRemove, children, info }) => {
  return (
    <div className="border-[1px] border-color-[#e3e3e3] rounded py-1.5 px-2 h-[93px] flex flex-col justify-between mb-2">
      <div className="flex flex-row justify-between">
        <p className="text-[#645F5B] dark:text-[#ffffff] font-bold text-sm">
          {title}
        </p>

        <div className="flex cursor-pointer">
          {children}

          {isRemove && (
            <div
              onClick={onRemove}
              className="flex justify-center items-center h-[35px] w-[75px] border border-[#EAEAEA] rounded cursor-pointer"
            >
              <span className="font-semibold text-[#645F5B] dark:text-[#fff] text-sm">
                Remove
              </span>
            </div>
          )}
        </div>
      </div>

      {info &&
      (info?.is_protected === true || info?.is_protected?.trim() == "true") ? (
        <em className="text-[#645F5B] dark:text-[#ffffff] text-[14px] truncate block ">
          Email hidden with provider (
          <span className="text-[#0A85D1]">
            <a
              href={LAYER_PAGE_CONNECTION}
              target="_blank"
              rel="noopener noreferrer"
              className="underline  hover:underline focus:underline"
            >
              learn more
            </a>
          </span>
          )
        </em>
      ) : (
        info?.email && (
          <div className="flex flex-row justify-between">
            <span className="text-[#645F5B] dark:text-[#ffffff] text-sm truncate block">
              {info?.email}
            </span>
            <img height={20} width={20} src={greenCheck} alt="greenCheck" />
          </div>
        )
      )}
    </div>
  );
};

export default ConnectedBox;
