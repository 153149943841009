import { useState,useRef } from "react";
import ReactModal from "react-modal";
import { ThemeContext, themes } from "../store/ThemeContext";
import AppleAuth from "./Authorization/Apple";
import DiscordAuth from "./Authorization/Discord";
import GithubAuth from "./Authorization/Github";
import GoogleAuth from "./Authorization/Google";
import TwitterAuth from "./Authorization/Twitter";
import { dark, logo, layerlogo, sun, close, searchIcon } from "../images/index";
import { useLocation, useNavigate } from "react-router-dom";
import { SEARCH_USER } from "../utils/consts";
import { isMobile } from "../utils";
import { useMedia } from "../utils/use-media";

import {emailLogo} from "../images"
import EmailModal from './EmailModal'; // Import the EmailModal component
import { verifyOtpSignin,getOtpSignin,getUserIpTimeAddress,verifyOtpSigninWithIp} from "../http/userApi";

import { USER_ACCOUNT_ROUTE, USER_SETNAME_ROUTE } from "../utils/consts";

import VerifyModal from './VerifyModal';

const AccountsMenu = ({ isOpen, onRequestClose, onMenu }) => {
  const mobile = useMedia(isMobile);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility
  const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false); // State to manage modal visibility
  const [email, setEmail] = useState('');

  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme-color") === "white-content" ? false : true
  );
  const [searchValue, setSearchValue] = useState("");

  const searchUser = (e) => {
    e.preventDefault();
    navigate(SEARCH_USER + searchValue.toLowerCase());
    navigate(0);
  };
  


  const sendOtp = async (email) => {
    try {
      const response = await getOtpSignin(email);
    } catch (error) {
      console.error('Error sending OTP:', error.response ? error.response.data : error.message);
    }
  };


//   const verifyOtp=async(otp)=>{
//   try{
//   const response = await verifyOtpSignin(otp);
//   console.log('Verification response:', response);
// } catch (error) {
//   console.error('Error verifying OTP:', error.response ? error.response.data : error.message);
// }
//   }

  const openModal = () => {
    onRequestClose();
    setIsModalOpen(true); // Function to open the modal
  };

  const closeModal = () => {
    setIsModalOpen(false); // Function to close the modal
  };
  const openEmail=()=>{
    setIsVerifyModalOpen(false);
    setIsModalOpen(true);
  }
  const openVerifyModal = (email) => {
    sendOtp(email);
    setEmail(email);
    setIsModalOpen(false); 
    setIsVerifyModalOpen(true); // Function to open the modal
  };

  const closeVerifyModal = () => {
    setIsVerifyModalOpen(false); // Function to close the modal
  };

  const resendVerify = () => {
    sendOtp(email);
  }

  const onVerify = async (otp) =>{
    console.log("onVerifyyyyy")

    try{
      const responseIp= await getUserIpTimeAddress();
      const {ipAddress, address, currentTime } = responseIp;// destructuring of object 
      const response = await verifyOtpSigninWithIp(otp,"new_user",ipAddress, address, currentTime);
      if(response?.status===1){
        if(response.token){
          localStorage.setItem("token", response.token);
        }
        response?.session_id && localStorage.setItem("session_id", response?.session_id);
        window.location.href = USER_ACCOUNT_ROUTE
      }
      return response?.status
    } catch (error) {
      console.error('Error verifying OTP:', error.response ? error.response.data : error.message);
      return 0;
    }

  }
  return (
    <>
    <ReactModal
      // style={{ overlay: { background: "rgba(26, 25, 25, 0.6)" } }}
      isOpen={isOpen}
      shouldFocusAfterRender={false}
      shouldCloseOnEsc
      onRequestClose={onRequestClose}
      closeTimeoutMS={300}
      overlayClassName="fixed top-0 bottom-0 left-0 right-0 flex h-full items-center justify-center menu_loginOverlay"
      className="outline-none inline-flex menu_loginModal"
      shouldCloseOnOverlayClick={true}
   >
          {/* <div className="flex flex-col lg:flex-row justify-between accountMenu_wrapper  overflow-y-auto py-[20px]"> */}
          {mobile ? (
            <div className="inline-flex flex-col lg:flex-1 mt-[50px] gap-y-[12px] md:gap-y-[20px] accountMenu_items flex-wrap items-center bg-[#F5F5F5] dark:bg-[#252423] p-[12px] border-[1px] border-solid border-[#fff] dark:border-[#252423] my-2 mx-auto rounded-[8px]">
              <div className="w-full lg:h-auto h-fit">
                {" "}
                {/* <Email /> */}
                <button
            style={{
                height:  40 ,
                width:  40 ,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
            }}
            onClick={openModal}
            className="border mx-auto rounded-[4px] hover:bg-[#eaeaea4d]  bg-white
            dark:hover:bg-[#eaeaea4d] focus:bg-[#eaeaea] dark:focus:bg-[#eaeaea] active:bg-[#eaeaea] dark:active:bg-[#eaeaea] duration-300 ease-in-out cursor-pointer customSplashPage__logBtn"
            >
                <img
                    height={ 20}
                    width={ 20 }
                    src={emailLogo}
                    alt="email logo"
                    
                    />
            </button>
              </div>

              <div className="w-full lg:h-auto h-fit">
                {" "}
                <DiscordAuth />
              </div>

              <div className="w-full lg:h-auto h-fit">
                {" "}
                <AppleAuth />
              </div>

              <div className="w-full lg:h-auto h-fit">
                {" "}
                <TwitterAuth />
              </div>

              <div className="w-full lg:h-auto h-fit">
                {" "}
                <GithubAuth />
              </div>

              <div className="w-full lg:h-auto h-fit">
                <GoogleAuth />
              </div>
            </div>
          ) : (
            <div className="inline-flex flex-row items-center h-auto m-auto gap-x-[20px] bg-[#FFFFFF] dark:bg-[#252423] border-[1px] border-solid border-[#fff] dark:border-[#000] p-[20px] rounded-[8px]">
              {/* <Email /> */}
              <button
            style={{
                height: 60,
                width: 60,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
            }}
            onClick={openModal}
            className="border mx-auto rounded-[4px] hover:bg-[#eaeaea4d]  bg-white
            dark:hover:bg-[#eaeaea4d] focus:bg-[#eaeaea] dark:focus:bg-[#eaeaea] active:bg-[#eaeaea] dark:active:bg-[#eaeaea] duration-300 ease-in-out cursor-pointer customSplashPage__logBtn"
            >
                <img
                    height={ 30}
                    width={ 30}
                    src={emailLogo}
                    alt="email logo"
                    
                    />
            </button>
              <DiscordAuth />
              <AppleAuth />
              <TwitterAuth />
              <GithubAuth />
              <GoogleAuth />
            </div>
          )}

          <div className="hidden justify-between px-5 ">
            {!pathname.includes(SEARCH_USER) && (
              <form className="relative block w-8/12" onSubmit={searchUser}>
                <input
                  className="placeholder:text-[#67743D] bg-transparent block w-full border border-[#67743D] dark:text-[#FFFFFF] dark:placeholder-[#FFFFFF] rounded-md py-2 pr-9 pl-3 focus:outline-none text-xs"
                  placeholder="@finduser"
                  type="text"
                  name="search"
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <button
                  type="submit"
                  className="absolute inset-y-0 right-0 flex items-center pr-2"
                >
                  <img
                    height={10}
                    width={10}
                    src={searchIcon}
                    alt="search icon"
                  />
                </button>
              </form>
            )}
          </div>
        {/* </div> */}
      {/* </div> */}
    </ReactModal>
     <EmailModal isOpen={isModalOpen} onClose={closeModal} onSubmit={openVerifyModal} />
     { isVerifyModalOpen &&
      <VerifyModal isOpen={isVerifyModalOpen} onClose={closeVerifyModal} onResend={resendVerify} 
        onVerify={onVerify} showNumText={true} onBack={openEmail}/>}
  </>
  );
};

export default AccountsMenu;
