import { observer } from "mobx-react-lite";
import { useState, useContext } from "react";
import { Context } from "../index";
import LinkItem from "./LinkItem";
import ProtectedLink from "./ProtectedLink";
import LinkItemProtected from "./LinkItemProtected";


  const LinksList = observer(({onDelete , showProtectedList, onlyView ,links, modifyLink }) => {

  const { user } = useContext(Context);

  // const modifyLink = async (id, newTitle, newLink, desc) => {
   
  //   // const data = await editItem(tempQuestion, tempAnswer, authUserId, id);
  //   const payload = {

  //     "user_id": user.user.id,
  //     "item_id": id,
  //     "meta_id": links?.meta_id,
  //     "data": [
  //       {
  //         "meta_data": {
  //          "title": newTitle,
  //             "link": newLink,
  //             "userId": user.user.id,
  //             "description": desc,
  //             "protected": false,
  //             "password": ""
           
  //         }
  //       }
  //     ]
  //   };

  //   const data = await saveComponents(payload);
  //   if (data.status) {
  //     setFaqAll(data?.componentItems || []);
  //   } else {
  //     showToast(data.message);
  //   }
  // };

  // const { user, links } = useContext(Context);
  
  return (
    <>
    {/* { protectedLink && !user.user.id &&( */}
    {/* { protectedLink && !user.user.id &&(
       <ProtectedLink
       protectedLink = {protectedLink}
       />
    )} */}
    <div className="flex flex-col justify-between space-y-2.5 mt-[12px]">
      { showProtectedList && links.meta_data.map((link, i) => (
        <>
          { link?.meta_data?.password != null && onlyView &&(
            <ProtectedLink
            protectedLink = {link}
            />
          )}
          { link?.meta_data?.password != null  && user.user.id &&  ( user.user.id == link?.meta_data?.userId) && !onlyView && (
          <LinkItemProtected
                    key={link.id}
                    onDelete={(id) => {
                      // links.removeLink(link.id);
                      onDelete(id);
                    }}
                    // onEdit={(id, newTitle, newLink, desc,password) => {
                    //   // links.setLinks(data);
                    //   links.editLink(id, newTitle, newLink, desc,password);
                    // }}
                    onEdit={(id, newTitle, newLink, desc,password) => modifyLink(id, newTitle, newLink, desc,password)}
                    userId={user.user.id}
                    link={link}
                    index={i}
                    onSwap={(e) => {
                      e.preventDefault();
                      links.swapLink(i, i - 1);
                    }}
                  />
                )}
       
          {/* {!protectedLink && link.password != null ? setProtectedLink(link) : ""} */}
        </>
        
      ))}
      { !showProtectedList &&  links.meta_data.map((link, i) => (
        <>
          {/* { link.password != null  && !user.user.id &&(
            <ProtectedLink
            protectedLink = {link}
            />
          )} */}
        
        <LinkItem
          key={link.id}
          onDelete={(id) => {
            // links.removeLink(link.id);
            onDelete(id);
          }}
          onEdit={(id, newTitle, newLink, desc) => modifyLink(id, newTitle, newLink, desc)}
          userId={user.user.id}
          link={link}
          index={i}
          onSwap={(e) => {
            e.preventDefault();
            links.swapLink(i, i - 1);
          }}
          onlyView={onlyView}
        />
          {/* {!protectedLink && link.password != null ? setProtectedLink(link) : ""} */}
        </>
        
      ))}

    </div>

    </>
  );
});

export default LinksList;
