import React, { useState, useEffect, useRef } from 'react';
import { RiCloseLine } from 'react-icons/ri';
import OtpInput from 'react-otp-input';
import { tickCirlce } from '../images/index'
import {verifyOtpSignin} from "../http/userApi"


const CodeVerificationModal = ({ isOpen, onClose, onBack, onResend, onVerify, showNumText = false }) => {
  const [code, setCode] = useState('');
  const [isValidOtp, setIsValidOtp] = useState(false);
  const modalRef = useRef(null);
  const [otp, setOtp] = useState('');
  const [seconds, setSeconds] = useState(30);
  const [showResend, setShowResend] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showVerified, setShowVerified] = useState(false);


  const handleResendClick = () => {
    // Handle the "Resend" button click (e.g., trigger email resend)
    // Reset the timer to 30 seconds
    onResend();
    setSeconds(30);
    setShowResend(false);
  };

  const handleOtpchange = (newOtp) => {
    setOtp(newOtp)
    
    showVerified && setShowVerified(false);
    showError && setShowError(false);
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const response = await onVerify(otp);
      console.log('Verification response:', response);
      if (response === 1) {
        setShowVerified(true);
      } else {
        setShowError(true);
      }
    } catch (error) {
      console.error('Error verifying OTP:', error.response ? error.response.data : error.message);
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      onClose();
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  useEffect(() => {
    let interval;

    if (seconds > 0) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else {
      setShowResend(true);
    }

    return () => clearInterval(interval);
  }, [seconds]);

  return (
    <>
      {isOpen && (
        <div className="fixed z-[99] overflow-y-auto inset-0 customLogin_modalOverlay mt-0">
          <div className="flex items-center justify-center min-h-screen">
            <div className="fixed inset-0 bg-[#F8F8F8] bg-opacity-80 dark:bg-[#606060CC] transition-opacity customLogin_modalOverlay" aria-hidden="true"></div>
            <div ref={modalRef} className="relative bg-white rounded-lg w-[580px] max-w-full mx-auto p-4 modal-content dark:bg-[#252423] dark:border-[#EAEAEA] dark:border-1">
              <form >
                <div className="text-left customDarkOTP--holder">
                  <div className="flex justify-between mb-[20px] ">
                    <h2 className=" text-lg md:text-xl font-semibold text-[#645F5B] dark:text-[#EAEAEA]">Verify Your Email</h2>
                    {showNumText && <h2 className="text-lg md:text-xl font-semibold text-[#645F5B]  dark:text-[#EAEAEA]">2/2</h2>}
                  </div>
                  <p className="block mb-[40px] mt-[12px]  text-base text-[#645F5B] dark:text-white ">
                   {showNumText ? 'Click the Layer link or enter the verification code from the email that was sent to you.':'Enter the verification code from the email that was sent to you.'}</p>
                  <OtpInput
                    className="dark:text-white"
                    value={otp}
                    onChange={handleOtpchange}
                    numInputs={6}
                    renderSeparator={<span className="" style={{ width: "10px" }}>  </span>}
                    isInputNum={true}
                    inputType='tel'
                    shouldAutoFocus={true}
                    inputStyle={{
                      border: showError ? "1px solid red" : (showVerified ? "1px solid green" : "1px solid #EAEAEA"),
                      borderRadius: "8px",
                      width: "40px",
                      height: "40px",
                      fontSize: "16px",
                      color: "#645F5B ",
                      fontWeight: "400",
                      caretColor: "#645F5B",
                      backgroundColor: "#D6D6D633"

                    }}
                    focusStyle={{
                      border: "1px solid blue",
                      outline: "none"
                    }}
                    renderInput={(props) => <input {...props} />}
                  />
                  <div className='mb-[10px] mt-[10px]'>
                    {showResend ? (<span className='dark:text-white text-base mb-[20px] text-blue-500 hover:text-blue-700 font-bold underline cursor-pointer'
                     onClick={handleResendClick} >Resend</span>) : (<div className='cursor-pointer text-base dark:text-white' > 
                    Verification is valid for 1h. Resend in {seconds}s</div>)}
                  </div>
                  <div className='flex justify-between mt-[40px]'>
                    <div className='relative'>

                      <button
                        type="submit"
                        onClick={handleSubmit}
                        className={otp?.length === 6
                          ? (showVerified
                            ? "border border-[#5FC650] text-[white] bg-[#5FC650] pl-[10px] pr-[30px] py-[9px] rounded cursor-pointer font-semibold text-base "
                            : "border border-[#5FC650] text-[#5FC650] px-[10px] py-[9px] rounded cursor-pointer font-semibold leading-none text-base ")
                          : "bg-[#EAEAEA] text-[#9B9B9B] px-[10px] py-[9px] rounded cursor-pointer font-semibold leading-none text-base "
                        }
                        disabled={otp?.length !== 6} // Disable button if code is not valid
                      >
                        {showVerified ? "Verified" : "Verify"}
                      {showVerified && <img className='absolute right-[5px] bottom-[12px] w-[16px]' src={tickCirlce} alt='tick' />}
                      </button>
                    </div>
                    <div className="w-4" />
                    <button
                      type="button"
                      onClick={onBack}
                      className="rounded-lg border border-gray-200 bg-white px-[10px] py-[9px] leading-none text-sm  font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-4 focus:ring-blue-300
                       dark:border-gray-500 dark:bg-[#252423] dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white outline-none"
                    >
                      Back
                    </button>
                  </div>
                </div>
              </form>
              </div>

          </div>
        </div>
      )}
    </>
  );
};

export default CodeVerificationModal;