import { github_logo } from "../../images";
import { isMobile } from "../../utils";
import { useMedia } from "../../utils/use-media";

const clientId = "7a5b9eeac74f6ad14299";

function GithubAuth() {
  const loginGithub = () => {
    window.location.assign(
      "https://github.com/login/oauth/authorize?client_id=" +
        clientId +
        "&scope=user"
    );
  };

  return (
    <button
      style={{
        height: useMedia(isMobile) ? 40 : 60,
        width: useMedia(isMobile) ? 40 : 60,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
      onClick={loginGithub}
      className="border mx-auto rounded-[4px] bg-white
      hover:bg-[#eaeaea4d] dark:hover:bg-[#eaeaea4d] focus:bg-[#eaeaea] dark:focus:bg-[#eaeaea] active:bg-[#eaeaea] dark:active:bg-[#eaeaea] duration-300 ease-in-out cursor-pointer customSplashPage__logBtn"
    >
      <img
        height={useMedia(isMobile) ? 20 : 30}
        width={useMedia(isMobile) ? 20 : 30}
        src={github_logo}
        alt="github logo"
      />
    </button>
  );
}

export default GithubAuth;
