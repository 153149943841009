import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  addShowcase,
  getShowcase,
  updateShowcaseItem,
  deleteShowCase,
  saveComponents,
  deleteComponent
} from "../../http/userApi";
import {
  circlePlusSolid,
  sliderArrowRight,
  sliderArrowLeft,
} from "../../images/index";

import useEmblaCarousel from "embla-carousel-react";
import { Context } from "../../index";
import { toast } from "react-toastify";
import Modal from "react-modal";
import { showToast } from "../../utils/BannerPopup";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "transparent",
    height: "auto",
    border: "0",
  },
};

const Showcase = (metadata) => {

  const { user } = useContext(Context);
  const isAuthPremium = user.user.is_premium;
  //// Mamange Showcase Items
  const [showcaseTitle, setShowcaseTitle] = useState(null);
  const [saveTitle, setSaveTitle] = useState(null);
  const [showcaseDescription, setShowcaseDescription] = useState(null);
  const [saveDescription, setSaveDescription] = useState(null);
  const [boxBg, setBoxBg] = useState("#790726");
  const [titleLen, setTitleLen] = useState("0");
  const [descriptionLen, setDescriptionLen] = useState("0");
  const [showCases, setShowCases] = useState(metadata?.metadata?.meta_data || []);
  const [showCaseId, setShowCaseId] = useState(0);

  const [modalIsOpen, setIsOpen] = useState(false);

  const [bgColor, setBgColor] = useState();

  const [emblaRef, emblaApi] = useEmblaCarousel();

  //// Change Showcase box bg color
  const changeBg = (color) => {
    setBoxBg(color);
  };

  //// Load Showcase Items Data
  // const addItem = async (e) => {
  //   const data = await addShowcase(showcaseTitle, showcaseDescription, boxBg);
  //   if (data.status == true) {
  //     setShowCases(data.data);
  //     setIsOpen(false);
  //     setShowcaseTitle(null);
  //     setShowcaseDescription(null);
  //     setBoxBg("#790726");
  //     setTitleLen("0");
  //     setDescriptionLen("0");
  //   } else {
  //     showToast(data.message);
  //     return false;
  //   }
  // };

  const addItem = async () => {
    var payload = {};
    // if (metadata?.metadata?.meta_data?.[0]?.id) {
    //   payload = {
    //     "user_id": user.user.id,
    //     "item_id": metadata?.metadata?.meta_data?.[0]?.id,
    //     "meta_id": metadata?.metadata?.meta_id,
    //     "data": [
    //       {
    //         "meta_data": {
    //           "title": showcaseTitle,
    //           "description": showcaseDescription,
    //           "bg_color": boxBg,
    //           "status": false,
    //         }
    //       }
    //     ]
    //   };
    // } else {
      payload = {
        "user_id": user.user.id,
        "meta_id": metadata?.metadata?.meta_id,
        "meta_name": "showcase",
        "item_index": metadata?.metadata?.item_index,
        "data": [
          {
              "title": showcaseTitle,
              "description": showcaseDescription,
              "bg_color": boxBg,
              "status": false,
          }
        ]
      };
    // }
    const data = await saveComponents(payload);

    if (data.status) {
      setShowCases(data?.componentItems || []);
      closeModal();
    } else {
      showToast(data.message);
    }
  };

  //// Edit Showcase Items
  const editItem = (e) => {
    setShowCaseId(e.id);
    setShowcaseTitle(e?.meta_data?.title);
    setSaveTitle(e?.meta_data?.title);
    setShowcaseDescription(e?.meta_data?.description);
    setSaveDescription(e?.meta_data?.description);
    if (e?.meta_data?.bg_color != null) {
      setBoxBg(e?.meta_data?.bg_color);
    }
    setBgColor(e?.meta_data?.bg_color);
    setIsOpen(true);
  };
  //// Edit Showcase Items
  // const updateItem = async (e) => {
  //   const data = await updateShowcaseItem(
  //     showcaseTitle,
  //     showcaseDescription,
  //     boxBg,
  //     showCaseId
  //   );
  //   if (data.status == true) {
  //     setShowCases(data.data);
  //     setIsOpen(false);
  //     setShowcaseTitle(null);
  //     setShowcaseDescription(null);
  //     setBoxBg("#790726");
  //     setShowCaseId(0);
  //   } else {
  //     showToast(data.message);
  //     return false;
  //   }
  // };

  const updateItem = async () => {
    const payload = {
      // user_id: user.user.id,
      // meta_id: metadata?.meta_id,
      // meta_name: "showcase",
      // item_index: metadata?.item_index,
      // data: [
      //   {
      //     id: showCaseId,
      //     title: showcaseTitle,
      //     description: showcaseDescription,
      //     bg_color: boxBg,
      //     status: false,
      //   },
      // ],

      "user_id": user.user.id,
      "item_id": showCaseId,
      "meta_id": metadata?.metadata?.meta_id,
      "data": [
        {
          "meta_data": {
            "title": showcaseTitle,
            "description": showcaseDescription,
            "bg_color": boxBg,
            "status": false,
          }
        }
      ]
    };

    const data = await saveComponents(payload);

    if (data.status) {
      setShowCases(data?.componentItems || []);
      closeModal();

      // setShowCases(data.data);
          setIsOpen(false);
          setShowcaseTitle(null);
          setShowcaseDescription(null);
          setBoxBg("#790726");
          setShowCaseId(0);
    } else {
      showToast(data.message);
    }
  };
  //// Delete Showcase Items
  // const deleteItem = async (e) => {
  //   const data = await deleteShowCase(showCaseId);

  //   // setShowCases(data.data);
  //   setShowCases(data?.componentItems || []);
  //   setIsOpen(false);
  //   setShowcaseTitle("");
  //   setShowcaseDescription("");
  //   setBoxBg("#790726");
  //   setShowCaseId(0);
  // };

  const deleteItem = async (e) => {
    // const data = await deleteShowCase(showCaseId);
    try {
      const data = {
        user_id: user.user.id,   
        item_id: showCaseId,  
        meta_id: metadata?.metadata?.meta_id
      };
  
      const response = await deleteComponent(data);

      console.log("success:", response?.response);
    } catch (error) {
      console.error("Error ", error);
    }
    // setShowCases(data?.componentItems || []);

    setShowCases(prevShowCases => 
      prevShowCases.filter(item => item.id !== showCaseId)
    );

    setIsOpen(false);
    setShowcaseTitle("");
    setShowcaseDescription("");
    setBoxBg("#790726");
    setShowCaseId(0);
  };
  //// Modal Title
  let subtitle;

  function openModal() {
    setIsOpen(true);
    setSaveTitle(null);
    setSaveDescription(null);

    // setShowcaseTitle("");
    // setShowcaseDescription("");
    // setBoxBg("#790726");
    // setShowCaseId(0);
  }
  function afterOpenModal() {
    subtitle.style.color = "#f00";
  }
  function closeModal() {
    setIsOpen(false);
    setShowcaseTitle("");
    setShowcaseDescription("");
    setBoxBg("#790726");
    setTitleLen("0");
    setDescriptionLen("0");
  }
  const cancelChanges = (e) => {
    console.log("colorbackground", bgColor);
    setShowcaseTitle(saveTitle);
    setShowcaseDescription(saveDescription);
    setBoxBg(bgColor);
    setTitleLen("0");
    setDescriptionLen("0");
  };
  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);
  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);
  //// Showcase section ends

  // useEffect(() => {
  //   /// Load Showcase Items
  //   getShowcase(user.user.id).then((data) => {
  //     setShowCases(data.data);
  //   });
  // }, []);

  // useEffect(() => {
  //   // Load Showcase Items
  //   const fetchShowcaseItems = async () => {
  //     const data = await saveComponents({
  //       user_id: user.user.id,
  //       meta_id: metadata?.meta_id,
  //       meta_name: "showcase",
  //       item_index: metadata?.item_index,
  //       data: metadata?.data
  //     });
  //     setShowCases(data.data);
  //   };
  //   fetchShowcaseItems();
  // }, []);


  return (
    <>
      {isAuthPremium && (
        <div className="">
          <div className="flex w-full">
            <div
              class="add_item"
              className={`
                          ${showCases?.length < 10
                  ? "w-[140px] min-w-[140px] h-[100px] bg-[#EAEAEA] hover:bg-[#DBDBDB] focus:bg-[#DBDBDB] active:bg-[#DBDBDB] p-[10px] rounded-[5px] relative mr-[10px] p-[5px]"
                  : "hidden"
                }
                          border-[#EAEAEA]`}
              onClick={() => openModal()}
            >
              {" "}
              <button className="absolute right-[10px] top-[10px]">
                <img
                  src={circlePlusSolid}
                  className="w-[15px] h-[15px] rounded-full"
                  alt="circlePlusSolid"
                />
              </button>
            </div>

            <div
              className={`
                        ${showCases?.length < 10
                  ? "half-slider w-full"
                  : "half-slider"
                } border-[#EAEAEA]`}
            >
              <div className="embla relative" ref={emblaRef}>
                <div className="embla__container">
                  {showCases?.map((item, index) => (
                    <div
                      onClick={() => {
                        editItem(item);
                      }}
                      className={`w-[140px] h-[100px] rounded-[5px] p-[5px] relative bg-[${item?.meta_data?.bg_color != null ? item?.meta_data?.bg_color : "#790726"
                        }] hover:bg-[#DBDBDB] active:bg-[#CCCCCC] focus:bg-[#CCCCCC] cursor-pointer embla__slide`}
                    >
                      <h4 className="text-[12px] h-[30px] text-[#ffffff] break-all">
                        {item?.meta_data?.title != "false" ? item?.meta_data?.title : ""}
                      </h4>
                      <h4 className="[h-50px] absolute text-[#ffffff] text-[14px] bottom-[5px] left-[5px] break-all">
                        {item?.meta_data?.description != "false" ? item?.meta_data?.description : ""}
                      </h4>
                    </div>
                  ))}
                </div>
                <button
                  className="embla__prev  absolute -translate-y-1/2 top-1/2 left-[0] z-10"
                  onClick={scrollPrev}
                >
                  {" "}
                  <img
                    src={sliderArrowLeft}
                    className="h-[14px]"
                    alt="sliderArrowLeft"
                  />{" "}
                </button>
                <button
                  className="embla__next  absolute right-[0] -translate-y-1/2 top-1/2 z-10"
                  onClick={scrollNext}
                >
                  {" "}
                  <img
                    src={sliderArrowRight}
                    className="h-[14px]"
                    alt="sliderArrowRight"
                  />
                </button>
              </div>
            </div>
          </div>
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <div
              className="
                        w-[500px] max-w-full bg-[#F8F8F8] text-[#645F5B] dark:bg-[#000000] dark:text-[#fff] p-[10px]
                          border-[#DAD7D4] border-[1px] radius-[5px] rounded "
            >
              <div className="text-base text-[14px] mb-[24px] leading-none font-semibold text-[#645F5B] dark:text-[#fff] ">
                Blocks
              </div>
              <div className="">
                <div
                  class="add_item"
                  className={`w-[140px] h-[100px] bg-[${boxBg}] rounded-[5px] m-auto relative p-[5px]`}
                >
                  <h4 className="text-[12px] h-[30px] text-[#ffffff] left-[10px] break-words">
                    {showcaseTitle}
                  </h4>
                  <h4 className="[h-50px] absolute text-[#ffffff] text-[14px] bottom-[5px] left-[5px] break-words break-all">
                    {showcaseDescription}
                  </h4>
                </div>
                <div className="flex relative justify-center gap-[5px] mt-[12px]">
                  <button
                    value="790726"
                    onClick={() => changeBg("#790726")}
                    className="cursor-pointer bg-[#790726] w-[20px] h-[20px] rounded-full border-[1px] border-[#589ED5]"
                  ></button>
                  <button
                    value="AC4609"
                    onClick={() => changeBg("#AC4609")}
                    className="cursor-pointer bg-[#AC4609] w-[20px] h-[20px] rounded-full border-[1px] border-[#589ED5]"
                  ></button>
                  <button
                    value="A07536"
                    onClick={() => changeBg("#A07536")}
                    className="cursor-pointer bg-[#A07536] w-[20px] h-[20px] rounded-full border-[1px] border-[#589ED5]"
                  ></button>
                  <button
                    value="00533E"
                    onClick={() => changeBg("#00533E")}
                    className="cursor-pointer bg-[#00533E] w-[20px] h-[20px] rounded-full border-[1px] border-[#589ED5]"
                  ></button>
                  <button
                    value="007385"
                    onClick={() => changeBg("#007385")}
                    className="cursor-pointer bg-[#007385] w-[20px] h-[20px] rounded-full border-[1px] border-[#589ED5]"
                  ></button>
                  <button
                    value="004283"
                    onClick={() => changeBg("#004283")}
                    className="cursor-pointer bg-[#004283] w-[20px] h-[20px] rounded-full border-[1px] border-[#589ED5]"
                  ></button>
                  <button
                    value="3F3870"
                    onClick={() => changeBg("#3F3870")}
                    className="cursor-pointer bg-[#3F3870] w-[20px] h-[20px] rounded-full border-[1px] border-[#589ED5]"
                  ></button>
                </div>

                <div className="w-full mt-[43px]">
                  <div className="flex flex-row justify-right lg:w-[100%]">
                    <span className="dark:text-[#BCBCBC] mr:0 lg:mr-[0%] text-sm text-[#645F5B] right">
                      {titleLen} of 20
                    </span>
                  </div>
                  <input
                    type="text"
                    onChange={(e) => {
                      setShowcaseTitle(e.target.value);
                      setTitleLen(e.target.value.length);
                    }}
                    value={showcaseTitle ? showcaseTitle : ""}
                    maxLength={20}
                    className="bg-[#EAEAEA] dark:bg-[#45423F]  px-2 text-[#645F5B] text-base outline-0 text-[14px] dark:text-[#fff] h-[35px] rounded w-full p-2 cursor-pointer placeholder-[#645F5B]"
                    placeholder="Age"
                  />

                  <div className="flex flex-row justify-right lg:w-[100%] mt-[38px]">
                    <span className="dark:text-[#BCBCBC] mr:0 lg:mr-[0%] text-sm text-[#645F5B] right">
                      {descriptionLen} of 30
                    </span>
                  </div>
                  <input
                    type="text"
                    onChange={(e) => {
                      setShowcaseDescription(e.target.value);
                      setDescriptionLen(e.target.value.length);
                    }}
                    value={showcaseDescription ? showcaseDescription : ""}
                    maxLength={30}
                    className="bg-[#EAEAEA] dark:bg-[#45423F]  px-2 text-[#645F5B] text-base outline-0 text-[14px] dark:text-[#fff] h-[35px] rounded w-full p-2 cursor-pointer placeholder-[#645F5B]
                                "
                    placeholder="60"
                  />
                </div>
                <div className="w-full mt-[10px]">
                  <div class="add_item" className="w-[200px]  m-auto relative">
                    <button
                      type="text"
                      onClick={() =>
                        showCaseId > 0 ? updateItem() : addItem()
                      }
                      className=" px-2 text-[#53A258] dark:text-[#53A258] font-semibold outline-0 text-[14px]  h-[35px] rounded p-2 cursor-pointer mt-[10px] w-[200px] border-[#53A258] border-[1px]"
                    >
                      {" "}
                      Save Changes
                    </button>
                    <button
                      type="text"
                      onClick={() => cancelChanges()}
                      className=" px-2 text-[#645F5B] dark:text-[#ffffff] font-semibold outline-0 text-[14px]  h-[35px] rounded p-2 cursor-pointer mt-[10px] w-[200px] border-[#EAEAEA] border-[1px]"
                    >
                      {" "}
                      Cancel Changes
                    </button>
                    <button
                      type="text"
                      onClick={() =>
                        showCaseId > 0 ? deleteItem() : closeModal()
                      }
                      className=" px-2 text-[#E1655C] font-semibold outline-0 text-[14px]  h-[35px] rounded p-2 cursor-pointer mt-[10px] w-[200px] border-[#EAC5C3] dark:border-[#EAC5C3] border-[1px]"
                    >
                      {" "}
                      Remove Changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      )}
    </>
  );
};

export default Showcase;
