import { appleLogo } from "../../images";
import { isMobile } from "../../utils";
import { useMedia } from "../../utils/use-media";
import AppleSignin from "react-apple-signin-auth";
import { AppleOAuth,getInfo,getUserIpTimeAddress } from "../../http/userApi";
import { USER_ACCOUNT_ROUTE, USER_SETNAME_ROUTE } from "../../utils/consts";
import { toast } from "react-toastify";
import { showToast } from "../../utils/BannerPopup";

function AppleAuth() {
  let mobile = useMedia(isMobile);

  const onSuccess = async(response) => {
    const responseIp= await getUserIpTimeAddress();
      const {ipAddress, address, currentTime } = responseIp;// destructuring of object 
    if (response?.authorization) {
      AppleOAuth({
        code: response?.authorization?.id_token,
        ipAddress, address, currentTime
      }).then((data) => {
          console.log('d1',data)
        if ( data?.status && data.status !=1 ){
          showToast(
            data.message,
            { type: "error" }
          );
          return;  
        }         
        getInfo().then((userData)=>{
        if (data?.id) {
          if (data?.username && userData?.backup_email) {
            window.location.href = USER_ACCOUNT_ROUTE;
          } else {
            window.location.href = USER_SETNAME_ROUTE;
          }
        }}).catch((error) => {
          console.error('Error fetching getinfo:', error);
        });
        }).catch((error) => {
          console.error('Error fetching user info:', error);
        });
        }
  };

  return (
    <AppleSignin
      /** Auth options passed to AppleID.auth.init() */
      authOptions={{
        /** Client ID - eg: 'com.example.com' */
        clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
        /** Requested scopes, seperated by spaces - eg: 'email name' */
        scope: "email name",
        /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
        redirectURI: process.env.REACT_APP_REDIRECT_URI,
        /** State string that is returned with the apple response */
        state: "state",
        /** Nonce */
        nonce: "nonce",
        /** Uses popup auth instead of redirection */
        usePopup: true,
      }} // REQUIRED
      onSuccess={onSuccess} // default = undefined
      /** Called upon signin error */
      onError={(error) => console.log("error>>", error)} // default = undefined
      render={(props) => (
        <button
          style={{
            height: mobile ? 40 : 60,
            width: mobile ? 40 : 60,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
          {...props}
          className="border mx-auto rounded-[4px] bg-white
           hover:bg-[#eaeaea4d] dark:hover:bg-[#eaeaea4d] duration-300 ease-in-out cursor-pointer focus:bg-[#eaeaea] dark:focus:bg-[#eaeaea] active:bg-[#eaeaea] dark:active:bg-[#eaeaea] customSplashPage__logBtn"
        >
          <img
            height={mobile ? 20 : 30}
            width={mobile ? 20 : 30}
            src={appleLogo}
            alt="apple logo"
          />
        </button>
      )}
    />
  );
}

export default AppleAuth;
