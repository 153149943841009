import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./components/AppRouter";
import { observer } from "mobx-react-lite";
import { Context } from "./index";
import { activeSession, check, getInfo, getThemeSetting, signOutAll } from "./http/userApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./components/Loader";
import { ThemeContext, themes } from "./store/ThemeContext";
import { HOME_ROUTE } from "./utils/consts"
import { showToast } from "./utils/BannerPopup";

const App = observer(() => {
  const { user } = useContext(Context);
  const { changeTheme } = useContext(ThemeContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    check()
      .then((data) => {
        // user.setUser(data);
        user.setIsAuth(true);
        const getsession_id = localStorage.getItem("session_id");
        getsession_id && activeSession(getsession_id);
      })
      .finally(() => setLoading(false));

    getInfo()
      .then((data) => {
        user.setUser(data);
        user.setAvatar(data?.avatar || null);
        user.setIsAuth(true);
        const getsession_id = localStorage.getItem("session_id");
        if (data?.allow_one_session === true && getsession_id) {
          console.log("allow one");
          signOutAll();
        }
        getThemeInfo();
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (user.isAuth) {
      const intrval = setInterval(async () => {
        const getsession_id = localStorage.getItem("session_id");
        if (getsession_id) {
          const res = await activeSession(getsession_id);
          if (res.status === 0) {

            showToast(res.message)
            user.setUser({});
            localStorage.removeItem("token");
            localStorage.removeItem("isPrivate");
            localStorage.removeItem("emailNotification");
            localStorage.removeItem("session_id");
            localStorage.removeItem("MessagingPermission")
            clearInterval(intrval)
            window.location.href = HOME_ROUTE;
          }
        }
      }, 10000);

      return () => clearInterval(intrval);
    }
  }, [user.isAuth, activeSession]);


  const getThemeInfo = async () => {
    try {
      const response = await getThemeSetting(user?.user?.id);
      if (response?.theme) {
        changeTheme(response?.theme !== "light" ? themes.light : themes.dark);
      }
    } catch (error) { }
  };

  // Get the current URL
  var currentURL = window.location.href;
  var urlParts = currentURL.split('/');
  var filteredURLParts = urlParts.filter(function (part) {
    return part !== '';
  });

  // console.log(filteredURLParts);
  // if (loading && filteredURLParts.length <=2) {
  //    return <Loader isVisible={loading} />;
  // }

  if (loading) {
    return <Loader isVisible={loading} />;
  }

  if (!localStorage.getItem("theme-color")) {
    localStorage.setItem("theme-color", "light");
  }

  return (
    <BrowserRouter>
      <AppRouter />
      <ToastContainer
          hideProgressBar
          pauseOnHover
      />
    </BrowserRouter>
  );
});

export default App;
