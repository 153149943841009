import React, { useContext, useEffect, useRef, useState } from "react";
import RightMenu from "../../../components/RightMenu";
import Header from "../../../components/Header";
import SettingsProfile from "./Profile";
import SettingsConnections from "./Connections";
import { GithubConnect } from "../../../http/userApi";
import SystemSettings from "./System";
import { useMedia } from "../../../utils/use-media";
import { isMobile } from "../../../utils";
import { RotatingLines } from "react-loader-spinner";
import Loader from "../../../components/Loader";
import { Context } from "../../../index";
import PlanLayout from "./PlanLayout";

const AccountSettings = () => {
  const { Themefooter } = useContext(Context);
  const [activeLayer, setActiveLayer] = useState(0);
  const [connectState, setConnectState] = useState(false);
  const [loading, setLoading] = useState(true);
  let mobile = useMedia(isMobile);

  const scrollRef = useRef();

  const sections = [
    {
      name: "Profile",
      ref: React.createRef() /* Ref per section */,
    },
    {
      name: "Plan",
      ref: React.createRef(),
    },
    {
      name: "Connections",
      ref: React.createRef(),
    },
    {
      name: "System",

      ref: React.createRef(),
    },
  ];

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const codeParam = urlParams.get("code");
    if (codeParam) setConnectState(true);
    GithubConnect(codeParam)
      .then((data) => {
        alert(data.message);
      })
      .finally(() => setConnectState(false));
  }, []);

  const handleNavigate = (section, i) => {
    /* 
    Access the "current element" of this sections ref. 
    Treat this as the element of the div for this section.
    */

    section.ref.current.scrollIntoView({ behavior: "smooth", block: "start" });

    setActiveLayer(i);
  };

  const handleScroll = (e) => {
    const isInViewport = (offset = 0, child) => {
      if (!child) return false;
      const top = child.getBoundingClientRect().top;
      return top + offset >= 0 && top - offset <= window.innerHeight - 200;
    };

    sections.forEach((element, index) => {
      let currentActiveChild = scrollRef?.current?.children[index];
      if (isInViewport(0, currentActiveChild)) {
        setActiveLayer(index);
      }
    });
  };

  
  return (
    <main className="flex flex-col justify-between dark:bg-[#1A1919]">
      {loading ? <Loader isVisible={loading} /> : null}

      <Header />

      <div className="flex justify-between border-b border-EA pt-0 custom-wrapper">
        <div          
          onScroll={handleScroll}
          className="w-full customContainer__left"
        >
          <div 
          id="scrollTotop" 
          className="flex flex-col container max-w-5xl px-5 mx-auto"
          >
            <div className="flex pt-[40px] md:pt-[40px] pb-[60px] space-x-2 items-center">
              <span className="text-[#645F5B] dark:text-[#FFFFFF]">Layer</span>
              <span className="block w-1.5 h-1.5 bg-EA rounded-full"></span>
              <span className="text-[#645F5B] dark:text-[#FFFFFF]">
                Settings
              </span>
            </div>
            <div className="flex flex-col lg:flex-row justify-between grow customSettingPageContainer">
              <div className="flex lg:flex-col lg:space-y-[10px] space-x-[10px] lg:space-x-[0] w-full lg:w-[200px] md:pt-[0px] pt-[10px] pb-[10px] border-b lg:border-b-0 sticky lg:top-[76px] bg-[#ffffff] dark:bg-[#1A1919] -top-[20px] z-10">
                
                {sections.map((e, i) => {
                  return (
                    <div
                      key={i}
                      className={
                        activeLayer === i
                          ? "bg-[#D3D3D366] rounded cursor-pointer p-[10px] leading-none"
                          : "rounded cursor-pointer p-[10px] border border-[#EAEAEA] leading-none"
                      }
                      onClick={() => handleNavigate(e, i)}
                    >
                      <span className="font-semibold text-[#645F5B] dark:text-[#FFFFFF]">
                        {e.name}
                      </span>
                    </div>
                  );
                })}
              </div>
              <div                
                ref={scrollRef}
                style={
                  !mobile
                    ? {
                      }
                    : {}
                }
                className="flex flex-col mb-10 overflow-y-scroll no-scrollbar w-[100%] connectionRightWrapper h-[100%]"
              >
                {sections.map((e, i) => {
                  if (i === 0) {
                    return (
                      <SettingsProfile
                        key={i}
                        section={e}
                        setLoading={setLoading}
                      />
                    );
                  }
                  if (i === 1) {
                    return (
                      <div className="py-10">
                      <PlanLayout 
                        key={i}
                        section={e}
                        setLoading={setLoading}
                      />
                      </div>
                    );
                  }

                  if (i === 2)
                    return (
                      <SettingsConnections
                        key={i}
                        section={e}
                        setLoading={setLoading}
                      />
                    );

                  if (i === 3) return <SystemSettings key={i} section={e} />;
                })}
              </div>
            </div>
          </div>
          <Themefooter></Themefooter>
        </div>
        <RightMenu />
      </div>
     
    </main>
  );
};
export default AccountSettings;
