import { useEffect } from "react";
import { toast } from "react-toastify";
import { DiscordConnect, getDiscordUserInfo } from "../../http/userApi";
import { getUrlParams } from "../../utils";
import ConnectedBox from "./ConnectedBox";
import { showToast } from "../../utils/BannerPopup";

const DiscordConnection = ({ data, successCallBack, onRemove }) => {
  let isDiscordConnected = data?.findIndex((e) => e.service === "discord");

  useEffect(() => {
    handleDiscordLogin();
  }, []);

  const handleDiscordLogin = () => {
    const url = window.location.href;
    const params = getUrlParams(url);
    if (params?.access_token) {
      // GET ACCOUNT INFO FROM DISCORD
      getDiscordUserInfo(params?.access_token).then((response) => {
        if (response?.email) {
          // AUTH FLOW
          DiscordConnect({
            id: response?.id,
            email: response?.email,
          }).then((data) => {
            console.log("discord connect data>>>", data);
            if (data.status === 0) {
              showToast(data?.message);
              return;
            }
            successCallBack();
          });
        }
      });
    }
  };

  const onLogin = () => {
    window.open(
      "https://discord.com/api/oauth2/authorize?client_id=1072759047029260338&redirect_uri=https%3A%2F%2Flayer.page%2Faccount%2Fsettings&response_type=token&scope=identify%20email"
    );
  };

  return (
    <ConnectedBox
      title={"Discord"}
      isRemove={isDiscordConnected > -1 && data?.length}
      onRemove={() => onRemove("discord")}
      info={data[isDiscordConnected]}
    >
      {isDiscordConnected === -1 && (
        <button
          className="bg-[#EAEAEA] h-[35px] w-[50px] rounded font-semibold text-sm text-[#589ED5] cursor-pointer hover:bg-[#E2E2E2] active:bg-[#CCC]"
          onClick={onLogin}
        >
          Add
        </button>
      )}
    </ConnectedBox>
   
  );
};

export default DiscordConnection;
