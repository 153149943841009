import { makeAutoObservable } from "mobx";

export default class SelectedUserStore {
  constructor() {
    this._selectedUser = {};
    makeAutoObservable(this);
  }

  setUser(user) {
    this._selectedUser = user;
  }

  get selectedUser() {
    return this._selectedUser;
  }


}
