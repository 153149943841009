import React, { useEffect, useState } from "react";
import RightMenu from "../components/RightMenu";
import Header from "../components/Header";
import { useParams } from "react-router-dom";
import { verifyUnauthMessage } from "../http/messageApi";
import { RotatingLines } from "react-loader-spinner";
import { useMedia } from "../utils/use-media";
import { isMobile } from "../utils";
import Loader from "../components/Loader";
import { useNavigate } from "react-router-dom";
import {USER_TIMEOUT_ROUTE} from "../utils/consts"
import { showToast } from "../utils/BannerPopup";

const Message = () => {
  const params = useParams();
  let mobile = useMedia(isMobile);
  const { type } = params;

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();  

  useEffect(() => {
    if (params?.id) {
      createMessage();
    }
  }, [params]);

  const createMessage = async () => {
    setLoading(true);
    try {
     const res= await verifyUnauthMessage(params?.id);
     if(res?.status != 1){
      navigate(USER_TIMEOUT_ROUTE);
     }
     else{
      showToast("Message sent!")
     }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      navigate(USER_TIMEOUT_ROUTE);
    }
  };

  return (
    <main className="flex flex-col justify-between h-screen bg-white dark:bg-[#1A1919]">
      {loading && (
        <Loader isVisible={loading}/>
      )}

      <Header />

      <div className="flex justify-between h-full bg-white dark:bg-[#1A1919]">
        <div className="w-full">
          <div className="flex flex-col justify-between container max-w-5xl px-5 mx-auto ">
            <div className="hidden lg:flex my-12 space-x-2 items-center">
              <span className="text-[#645F5B] dark:text-[#FFFFFF]">
                {type === "1" ? "Sent" : "Verify"}
              </span>
            </div>

            <div className="flex flex-col">
              <span className="mt-[50px] lg:mt-0 h-[1px] w-full bg-[#EAEAEA]"></span>

              {type === "1" ? (
                <h2 className="mt-[20px] font-semibold text-[#53A258] text-xl">
                  Message sent!
                </h2> 
              ) : (
                <p className="mt-[20px] font-semibold text-[#589ED5] text-xl">
                  Check your email to verify it and send the message.
                </p>
              )}
            </div>
          </div>
        </div>

        <RightMenu />
      </div>
    </main>
  );
};

export default Message;
