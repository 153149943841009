import { makeAutoObservable } from "mobx";

export default class LinkStore {
  constructor() {
    this._links = [];
    makeAutoObservable(this);
  }

  setLinks(links) {
    this._links = links;
  }

  get links() {
    return this._links;
  }

  removeLink(id) {
    for (let link of this.links) {
      if (link.id === id) {
        const indexOf = this.links.indexOf(link);
        this.links.splice(indexOf, 1);
      }
    }
  }

  swapLink(i1, i2) {
    // Step 1
    let temp = this.links[i1];

    // Step 2
    this.links[i1] = this.links[i2];

    // Step 3
    this.links[i2] = temp;
  }

  editLink(id, newTitle, newLink, desc,password=null) {
    for (let link of this.links) {
      if (link.id === id) {
        link.title = newTitle;
        link.link = newLink;
        link.description = desc;
        link.password = password;
      }
    }
  }
}
