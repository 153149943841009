import { React, useState, useEffect, useContext } from "react";
import { ThemeContext, themes } from "../store/ThemeContext";
import { dark, sun } from "../images";

const scrollTop = (event) => {
  const body = document.getElementById("scrollTotop");

  body.scrollIntoView(
    {
      top: 0,
      behavior: "smooth",
    },
    1000
  );
};

export function Themefooter() {
  const { theme, changeTheme } = useContext(ThemeContext);
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme-color") === "white-content" ? false : true
  );
  const guide = "https://layer.guide/";
  const legal = "https://layer.guide/legal";
  const about = "https://layer.guide/about";

  return (
    <div className="flex justify-between p-[12px] h-[40px] footer bg-white dark:bg-[#1A1919] customMainFooter">
      <div className="flex justify-between w-full">
        <div className="flex w-full justify-start">
          <a
            className="mr-5 custom-hover text-xs text-[#645F5B] dark:text-white"
            href={legal}
            target="_blank"
            rel="noopener noreferrer"
          >
            Legal
          </a>
          <a
            className="mr-5 custom-hover text-xs text-[#645F5B] dark:text-white"
            href={guide}
            target="_blank"
            rel="noopener noreferrer"
          >
            Guide
          </a>
          <a
            className="mr-5 custom-hover text-xs text-[#645F5B] dark:text-white"
            href={about}
            target="_blank"
            rel="noopener noreferrer"
          >
            About
          </a>
          <div className="flex ml-auto">
            <div
              onClick={scrollTop}
              className="mr-[8px] lg:hidden text-xs text-[#645F5B] dark:text-white cursor-pointer"
            >
              Top
            </div>

            <div
              onClick={scrollTop}
              className="pr-[10px] hidden lg:block text-xs text-[#645F5B] dark:text-white  cursor-pointer"
            >
              Top
            </div>

            <button
              className="self-end"
              color="link"
              onClick={() => {
                const newTheme = darkMode ? themes.light : themes.dark;
                setDarkMode(!darkMode);
                changeTheme(newTheme);
                window.dispatchEvent(new Event("theme-color"));
              }}
            >
              <img
                height={12}
                width={12}
                src={darkMode ? dark : sun}
                className="ml-auto"
                alt={darkMode ? "dark" : "sun"}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
