import React, { useContext, useEffect, useRef, useState } from "react";
import ReactModal from "react-modal";

import { observer } from "mobx-react-lite";

import GoogleAuth from "../Authorization/Google";
import GithubAuth from "../Authorization/Github";

import TwitterAuth from "../Authorization/Twitter";
import AppleAuth from "../Authorization/Apple";
import DiscordAuth from "../Authorization/Discord";
import Email from "../Authorization/Email";


ReactModal.setAppElement("#root");


const SignInMenu = observer((headerRef) => {
   console.log(headerRef);


  return (
              <div className="mt-auto">
                <div className="flex justify-start relative lg:justify-between items-end lg:items-start border-t border-EA py-[20px]">
                  <div className="hidden lg:flex flex-col justify-between">
                    <p className=" text-lg font-medium text-[#645F5B] dark:text-white dark:text-[#EAEAEA">
                      Sign up/in:
                    </p>
                  </div>
                  <div className="lg:flex justify-between space-x-4 ">
                    <Email />
                    <DiscordAuth />
                    <AppleAuth />
                    <TwitterAuth />
                    <GithubAuth />
                    <GoogleAuth />
                  </div>
                  <div className="block lg:hidden w-full ">
                    <div
                      className="w-full cursor-pointer flex justify-center text-sm mx-auto h-[35px]  items-center text-[#645F5B] dark:text-[#FFFFFF] border border-[#645F5B]  font-semibold"
                      onClick={() => headerRef?.headerRef?.current?.callBack()}
                    >
                      Sign up/in:
                    </div>
                  </div>
                </div>
              </div>        
  );
});
export default SignInMenu;